import React, { useContext, useEffect, useState } from "react";
import { strings } from "../../Common/String";
import { AppContext } from "../../Context/AppContext";
import { LeadService } from "../../Services/LeadService";
import LayoutWrapper from "../../Common/LayoutWrapper";

const ThankYou = (props) => {
  const { setLoading } = useContext(AppContext);
  const [apiMessage, setApiMessage] = useState("");
  const [name, setName] = useState(null);

  useEffect(() => {
    setLoading(true);
    setApiMessage("");
    LeadService.rekycGetLeadStatus()
      .then((data) => {
        if (data.success) {
          setName(data.name);
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <LayoutWrapper>
      <div className="summarydiv">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-10 marg">
              <div className="forms ">
                <div className="row pb-2">
                  <div className="col-md-12 pl-3 pt-5 pb-5 pr-3 pl-md-5 pr-md-5">
                    <div className="row">
                      <div className="col-md-2 text-center" />
                      <div className="col-md-8 bg-w text-center">
                        <p>
                          <img
                            src={process.env.PUBLIC_URL + "/img/check1.png"}
                            alt=""
                          />
                        </p>
                        <h6 className="pb-3 thank">
                          {strings.thankyou} {name}!
                        </h6>
                        <p className="pt-1 mb-0 thankp">
                          {strings.rekyc_thankyou_para}.
                        </p>
                        <div className=" pt-3 mb-0 thankp">
                          <h6 className="thankpg">
                            {strings.signin_check_status}
                          </h6>
                        </div>
                        <h6 className="pt-3 head-ov"> </h6>
                        <div className="col-md-12 pt-3">
                          <div className="row">
                            <div className="col-md-2 pt-4" />
                            <div className="col-md-8 pt-3 text-center">
                              <div className="inp ">
                                <a
                                  href="https://acumengroup.in/"
                                  style={{ textDecoration: "none" }}
                                  target="blank"
                                >
                                  {strings.gotowebsit}
                                </a>
                                <br></br>
                                <br></br>
                              </div>
                            </div>
                            <div className="col-md-2 pt-4" />
                            <div className="col-md-2 pt-4" />
                            <div className="col-md-3 pt-3 d-none">
                              <div className="text-danger text-center">
                                <label id="txterror" className="txterror">
                                  {apiMessage}
                                </label>{" "}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2 text-center" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};
export default ThankYou;
