import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import registerServiceWorker from "./registerServiceWorker";
import { AppContextProvider } from "./Context/AppContext";
import "./Common/css/theme.css";
import "./Common/css/style.css";
import "./Common/css/font-awesome.min.css";
import ScrollToTop from "./ScrollToTop";
import packageJson from '../package.json';

ReactDOM.render(
  <Router basename={process.env.PUBLIC_URL}>
    <ScrollToTop>
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </ScrollToTop>
  </Router>,
  document.getElementById("root")
);
console.log("App Version :", packageJson.version);
registerServiceWorker();
