import React from "react";
/**
 * Edit icon
 * @param {*} props
 * @returns
 */
function EditPen(props) {
  const { click, classes, imgsrc, imgclass } = props;
  return (
    <div className={classes}>
      <img src={imgsrc} alt="" onClick={click} className={imgclass} />
    </div>
  );
}

export default EditPen;
