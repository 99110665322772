import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../Context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { strings } from "../Common/String";
import Button from "../Component/Button";
// import { LeadService } from "../Services/LeadService";
import Footer from "../footer";
import Header2 from "../HeaderStatus";
import { MasterService } from "../Services/MasterService";
import { LeadService } from "../Services/LeadService";
import { PATH } from "../Common/Path";
import { getCurrentStep } from "../Common/utils";

function TermsConditions(props) {
  const { steps, setSteps, setLoading, currentStep, setCurrentStep, showConfirmDialog, closeConfirmDialog, hasChanges, setHasChanges } =
    useContext(AppContext);
  const [options, setOptions] = useState([]);
  const [enableNext, setEnableNext] = useState(false);
  //const [disabled, setDisabled] = useState(false);
  // const [termsCount, setTermsCount] = useState({});
  const [apiMessage, setApiMessage] = useState("");
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [cheked, setCheked] = useState(false);

  useEffect(() => {
    setHasChanges(false)
    setLoading(true);
    Promise.all([MasterService.termsConditions(), LeadService.getTermsConditions("tnc")])
      .then(datas => {
        if (datas[0]) {
          let data = datas[0];
          if (data.success) {
            setOptions(data.terms);
            // setTermsCount(data.terms.length);
          } else {
          }
        }
        if (datas[1]) {
          let data = datas[1];
          setCurrentStep(getCurrentStep(data.current_step));
          if (data.success && data.tnc) {
            setCheked(true);
            setEnableNext(true);
            formData.iConfirm = true;
          } else {
            setEnableNext(false);
            //setDisabled(disabled);
            setCheked(false);
            formData.iConfirm = false;
          }
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    steps.forEach((step) => {
      if (step.id === currentStep) {
        step.status = 1;
      } else if (step.id < currentStep) {
        step.status = 2;
      }
    });
    setSteps(steps);
  }, [currentStep]);

  /**
   * checkbox change
   * @param {*} event
   */
  const handleCheckBoxChange = (event) => {
    setFormData((formData) => ({
      ...formData,
      iConfirm: event.target.checked,
    }));
    if (event.target.checked) {
      setEnableNext(true);
      //  setDisabled(!disabled);
      setCheked(true);
    } else {
      setEnableNext(false);
      // setDisabled(disabled);
      setCheked(false);
    }
    setApiMessage("")
    setHasChanges(true);
  };


  const previousClick = () => {
    if (hasChanges) {
      showConfirmDialog(strings.confirm_msg, undefined, () => {
        closeConfirmDialog();
        history.push(PATH.PERSONALOTHER_PAGE);
      })
    } else {
      history.push(PATH.PERSONALOTHER_PAGE);
    }
  };
  const nextClick = () => {
    if (formData.iConfirm) {
      let body = {
        type: "tnc",
        details: {
          tnc: true,
        },
      };
      setApiMessage("")
      LeadService.postTermsConditions(body)
        .then((data) => {
          if (data.success) {
            history.push(PATH.BANKACCOUNT_PAGE);
          } else {
            setApiMessage(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className="inner-section">
      <div className="details">
        <div className="container-fluid">
          <div className="row">
            <Header2 />
            <div className="col-md-9 pl-3 pt-55 pb-5 pr-3 pl-md-5 pr-md-5">
              <div className="row">
                <div className="col-md-12 pb-3">
                  <h5 className="pb-2">{strings.checkoutdetails}</h5>
                </div>
                <div className="col-md-12">
                  <ol type="1" className="list-ad">
                    {options.map((item, index) => (
                      <li key={index} style={{ paddingLeft: "9px" }}>
                        <p>{item.condition}</p>
                      </li>
                    ))}
                  </ol>
                  <div className="select-plan terms">
                    <label className="main" style={{ background: "#f4f6f8" }}>
                      <p>I agree to the above default settings</p>
                      <input
                        type="checkbox"
                        checked={cheked}
                        onChange={handleCheckBoxChange}
                      />
                      {/* defaultChecked="checked" */}
                      <span className="geekmark" />
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row mh-42">
                    <div className="col-md-12 pt-2">
                        <div className="text-danger text-center .api-errormessage">
                          <label id="txterror" name="txterror">
                            {apiMessage}
                          </label>{" "}
                        </div>
                    </div>
                  </div>
                  <div className="row mh-64">
                    <div className="col-6 pt-4">
                      <Button
                        classes="inp-back"
                        label={strings.previous}
                        click={previousClick}
                      />
                    </div>
                    {enableNext && (
                      <div className="col-6 pt-3 text-right">
                        <Button
                          classes="inp"
                          classes1="buttonmystyle"
                          label={strings.nextbtn}
                          // btndisable={disabled}
                          click={nextClick}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default TermsConditions;
