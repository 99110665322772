import React from "react";

const RadioButtonGroup = (props) => {
  const {
    name,
    options,
    label,
    onOptionChange,
    error,
    errorMessage,
    errorclass,
    selected,
    disabled,
    tabIndex,
    reference,
    mainclassName,
    htmlForText,
    divClassName,
    spanClassName
  } = props;
  return (
    <>
      <div className={mainclassName || "col-md-6 pb-3"}>
        <label
          className="color-add"
          htmlFor={htmlForText ? htmlForText : label}
        >
          {label}{" "}
        </label>
        <div className={divClassName || "inuput-box radio-box"}>
          {options.map((option, i) => {
            const value = typeof option === "object" ? (option.value || option.label) : option
            const optionLabel = typeof option === "object" ? option.label : option
            const helperText = typeof option === "object" ? option.helperText : null
            return (
              <span key={i} className={spanClassName}>
                <input
                  tabIndex={tabIndex}
                  type="radio"
                  id={
                    htmlForText
                      ? `${htmlForText}-${value}`
                      : `${name}-${value}`
                  }
                  name={name}
                  value={value}
                  onChange={onOptionChange}
                  checked={selected === value}
                  disabled={disabled}
                  ref={reference}
                />
                <label
                  htmlFor={
                    htmlForText
                      ? `${htmlForText}-${value}`
                      : `${name}-${value}`
                  }
                  className="paddingleft"
                >
                  {optionLabel}
                </label>
                {helperText && <p>{helperText}</p>}
              </span>
            );
          })}
        </div>
        <div>
          <div className={errorclass}>{error && errorMessage}</div>
        </div>
      </div>
    </>
  );
};

export default RadioButtonGroup;
