import React, { useEffect, useState } from "react";
import { strings } from "../Common/String";

function FileUpload(props) {
  const {
    label,
    maindivClass,
    subDivClass,
    link,
    allowedFormats,
    fileName,
    selectedOption: so,
    fileURL,
    headView,
    selectid,
    isError,
    errorMessage,
    options,
    onSelectChange,
    linklabel,
    accept,
    maxsize,
    onChange,
    childDivClass,
    spanClass,
    iClass,
    imgClass,
    imgSrc,
    divClass,
    required,
    uploadClass,
    uploadTitleClass,
    selectClass,
  } = props;
  const [newFile, setNewFile] = useState(null);
  const [imagePrev, setImagePrev] = useState(null);
  const [error, setError] = useState(false);
  const [acceptedTypes, setAcceptedTypes] = useState("image/*,application/pdf");
  const [enableDropDown, setEnableDropDown] = useState(false);
  const [enableHead, setEnableHead] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [initialValue, setInitialValue] = useState(null);
  const [message, setMessage] = useState("jpg, png and pdf");
  const [uploadError, setUploadError] = useState(false);

  useEffect(() => {
    setNewFile(fileName);
  }, [fileName]);

  useEffect(() => {
    if (fileURL) {
      if (/\.(|jpe?g|png)$/i.test(fileURL)) {
        setImagePrev(fileURL);
      } else if (/\.(|pdf)$/i.test(fileURL)) {
        setImagePrev(process.env.PUBLIC_URL + "/img/upload-after.jpg");
      } else {
        setImagePrev(null);
      }
    }
  }, [fileURL]);

  useEffect(() => {
    setUploadError(isError);
  }, [isError]);

  useEffect(() => {
    setInitialValue(so);
    if (so) {
      setSelectedOption(options.find((o) => o.value === so));
    }
  }, [options, so]);

  useEffect(() => {
    if (accept === "both") {
      setMessage("jpg, png and pdf");
      setAcceptedTypes("image/*,application/pdf");
    } else if (accept === "image") {
      setMessage("jpg or png");
      setAcceptedTypes("image/*");
    } else if (accept === "document") {
      setMessage("pdf");
      setAcceptedTypes("application/pdf");
    }
  }, [accept]);

  useEffect(() => {
    if (headView === "normal") {
      setEnableHead(true);
    }
    if (headView === "select") {
      setEnableDropDown(true);
    }
  }, []);

  const dropdownOptionChange = (event) => {
    setSelectedOption(options.find((o) => o.value === event.target.value));
    onSelectChange(event.target.value);
  };

  const change = (event) => {
    setUploadError(false);
    if (event.target.files.length > 0) {
      const fileUploaded = event.target.files[0];
      const fsize = fileUploaded.size / 1024;
      if (
        fsize <= maxsize * 1024 &&
        (((acceptedTypes === "image/*,application/pdf" ||
          acceptedTypes === "image/*") &&
          /\.(|jpe?g|png)$/i.test(fileUploaded.name)) ||
          ((acceptedTypes === "image/*,application/pdf" ||
            acceptedTypes === "application/pdf") &&
            /\.(|pdf)$/i.test(fileUploaded.name)))
      ) {
        setNewFile(fileUploaded.name);
        if (/\.(|jpe?g|png)$/i.test(fileUploaded.name)) {
          setImagePrev(URL.createObjectURL(fileUploaded));
        } else {
          setImagePrev(process.env.PUBLIC_URL + "/img/upload-after.jpg");
        }
        setError(false);
        onChange(fileUploaded);
      } else {
        setImagePrev(null);
        setNewFile(null);
        setError(true);
        onChange(null);
      }
    }
  };
  const remove = (e) => {
    e.stopPropagation();
    setNewFile(null);
    onChange(null);
    setImagePrev(null);
  };
  return (
    <div className={maindivClass}>
      <div className={subDivClass} id="files4">
        <div className={childDivClass}>
          <span className={uploadTitleClass || "upload-title"}>
            {label}
            {required && <sup className="required"> *</sup>}
          </span>
          {enableHead && (
            <>
              <span className={spanClass}>
                <a href="# ">
                  <i className={iClass} aria-hidden="true" />
                  <img className={imgClass} src={imgSrc} alt=" " />
                </a>
              </span>
              {/* <a className="download-link" href={link}>{linklabel}</a> */}
            </>
          )}

          {enableDropDown && (
            <React.Fragment>
              <select
                id={selectid}
                name={selectid}
                onChange={dropdownOptionChange}
                className={selectClass}
                defaultValue={initialValue}
              >
                {options.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              {headView === "select" ? (
                selectedOption &&
                selectedOption.preview_url && (
                  <span className={spanClass}>
                    <a href="# ">
                      <i className={iClass} aria-hidden="true" />
                      <img
                        className={imgClass}
                        src={selectedOption.preview_url}
                        alt=" "
                      />
                    </a>
                  </span>
                )
              ) : (
                <span className={spanClass}>
                  <a href="# ">
                    <i className={iClass} aria-hidden="true" />
                    <img className={imgClass} src={imgSrc} alt=" " />
                  </a>
                </span>
              )}
            </React.Fragment>
          )}
          <span>
            {" "}
            <a
              className="download-link"
              href={link}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              {linklabel}
            </a>
          </span>
        </div>

        <div
          className={uploadError ? `mandatory-border ${divClass}` : divClass}
        >
          {imagePrev ? (
            <img src={imagePrev} height="184" width="257" alt=" " />
          ) : (
            <>
              <img
                src={process.env.PUBLIC_URL + "/img/upload.png"}
                width="90%"
                alt=" "
              />
              <div className={uploadClass || "upload-types"}>
                <b>
                  Only {allowedFormats} upto {maxsize}MB
                </b>
              </div>
            </>
          )}
          {newFile ? (
            <div className="show-me">
              {newFile}
              <div className="close-btn">
                <a href="# " onClick={remove}>
                  <i className="fa fa-window-close" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          ) : (
            <input
              type="file"
              accept={acceptedTypes}
              onChange={change}
              name="files1"
              multiple
            />
          )}
          {uploadError && (
            <div className="upload-message">
              {errorMessage || strings.please_upload}
            </div>
          )}
        </div>
        <br />
        {error && (
          <span className="text-danger errorfont">
            {strings.sizefile} {maxsize}MB. {strings.onlyallowed} {message}{" "}
            {strings.format}
          </span>
        )}
      </div>
    </div>
  );
}
export default FileUpload;
