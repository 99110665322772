import React, { useContext } from "react";
import { useHistory } from "react-router";
import { strings } from "../Common/String";
import { AppContext } from "../Context/AppContext";

function StatusRow(props) {
  const { tickClasses, labelClasses, label, link } = props;
  const { showConfirmDialog, closeConfirmDialog, hasChanges } = useContext(AppContext)
  const history = useHistory()
  const onClick = () => {
    if (link) {
      if (hasChanges) {
        showConfirmDialog(strings.confirm_msg, undefined, () => {
          closeConfirmDialog();
          history.push(link);
        })
      } else {
        history.push(link)
      }
    }
  }
  return (
    <div className={tickClasses}>
      <a
        className={labelClasses || ''}
        style={{ textDecoration: "none" }}
        href="# "
        onClick={onClick}>
        {label}
      </a>
    </div>
  );
}

export default StatusRow;
