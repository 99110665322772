import { flatten } from "lodash";
import React from "react";
import { Controller } from "react-hook-form";
import { useForm } from ".";
import PropTypes from "prop-types";

const Dropdown = (props) => {
    const {
        label = "",
        classes,
        name,
        disabled: disabledProp,
        mainClass,
        selectClass,
        labelClass,
        errorClass = "text-danger errorfont mh-18",
        reference,
        tabIndex,
        options,
        readOnly: isReadOnly,
        hidden = false,
        required = false,
        index = -1,
        parentNode = "",
    } = props;

    const {
        control,
        errors,
        formIsDisabled,
        formIsReadOnly,
        formIsLoading,
        autoFocusElement,
        getValues,
    } = useForm();

    const disabled = disabledProp || formIsDisabled;
    const readOnly = isReadOnly || formIsReadOnly || formIsLoading;

    const hasError = (errors) => {
        if (index !== -1) {
            return (
                errors &&
                errors[parentNode] &&
                errors[parentNode][index] &&
                errors[parentNode][index][name] &&
                errors[parentNode][index][name].message
            );
        }
        if (name && name.includes(".")) {
            const parts = flatten(
                name
                    .split(".")
                    .map((item) => item.split(/\[/).map((item) => item.replace("]", "")))
            );
            let error = errors;
            parts.forEach((part) => {
                error = error?.[part] || {};
            });
            return error ? error.message : undefined;
        }
        return errors && errors[name] ? errors[name].message : undefined;
    };

    const error = hasError(errors);

    return (
        <div className={mainClass}>
            <div className={classes}>
                <label htmlFor={label} className={labelClass}>
                    {label}
                </label>
                <Controller
                    name={index !== -1 ? `${parentNode}[${index}].${name}` : name}
                    control={control}
                    defaultValue={
                        getValues(
                            index !== -1 ? `${parentNode}[${index}].${name}` : name
                        )
                    }
                    render={({ field }) => (
                        <select
                            {...field}
                            hidden={hidden}
                            required={required}
                            className={selectClass}
                            disabled={disabled}
                            readOnly={readOnly}
                            ref={reference}
                            error={errorClass}
                            tabIndex={tabIndex}
                            autoFocus={autoFocusElement === name}
                        >
                            {options.map((item, index) => (
                                <option key={index} value={item.value}>
                                    {item.label}
                                </option>
                            ))}
                        </select>
                    )}
                />
            </div>
            <div className={errorClass}>{error}</div>
        </div>
    );
};

Dropdown.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    classes: PropTypes.string,
    selectClass: PropTypes.string,
    hidden: PropTypes.bool,
    step: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    parentNode: PropTypes.string,
    index: PropTypes.number,
    mainClass: PropTypes.string,
    labelClass: PropTypes.string,
    errorClass: PropTypes.string,
    tabIndex: PropTypes.string,
    options: PropTypes.array,
}
export { Dropdown };
