import { flatten, isEmpty, isNil } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { useForm } from ".";
import PropTypes from "prop-types";

const Input = (props) => {
  const {
    label = "",
    placeholder = "",
    classes,
    type,
    name,
    maxLength = -1,
    disabled: disabledProp,
    onChange: onTextChange,
    mainClass,
    inputClass,
    postfix,
    labelClass,
    errorClass = "text-danger errorfont mh-18",
    reference,
    tabIndex,
    pattern,
    prefix,
    readOnly: isReadOnly,
    hidden = false,
    required = false,
    autoComplete = "off",
    min = undefined,
    max = undefined,
    step = "1",
    index = -1,
    parentNode = "",
    list = undefined,
  } = props;

  const {
    control,
    errors,
    formIsDisabled,
    formIsReadOnly,
    formIsLoading,
    autoFocusElement,
    getValues,
  } = useForm();

  const [shouldTransform, setShouldTransform] = useState(false);

  const disabled = disabledProp || formIsDisabled;
  const readOnly = isReadOnly || formIsReadOnly || formIsLoading;

  const hasError = (errors) => {
    if (index !== -1) {
      return (
        errors &&
        errors[parentNode] &&
        errors[parentNode][index] &&
        errors[parentNode][index][name] &&
        errors[parentNode][index][name].message
      );
    }
    if (name && name.includes(".")) {
      const parts = flatten(
        name
          .split(".")
          .map((item) => item.split(/\[/).map((item) => item.replace("]", "")))
      );
      let error = errors;
      parts.forEach((part) => {
        error = error?.[part] || {};
      });
      return error ? error.message : undefined;
    }
    return errors && errors[name] ? errors[name].message : undefined;
  };

  const error = hasError(errors);

  const value = useMemo(
    () => getValues(index !== -1 ? `${parentNode}[${index}].${name}` : name),
    [name, parentNode, index]
  );

  useEffect(() => {
    if (!isNil(value)) {
      setShouldTransform(true);
    }
  }, [value]);

  return (
    <div className={mainClass}>
      <div className={classes}>
        <Controller
          name={index !== -1 ? `${parentNode}[${index}].${name}` : name}
          control={control}
          defaultValue={
            getValues(
              index !== -1 ? `${parentNode}[${index}].${name}` : name
            ) || ""
          }
          render={({ field }) => (
            <>
              {prefix}
              <input
                {...field}
                onChange={(event) => {
                  if (
                    !maxLength ||
                    event.target.value.length <= parseInt(maxLength)
                  ) {
                    field.onChange(event);
                    if (onTextChange) onTextChange(event.target.value);
                  }
                }}
                id={index !== -1 ? `${parentNode}[${index}].${name}` : name}
                hidden={hidden}
                required={required}
                className={inputClass}
                disabled={disabled}
                readOnly={readOnly}
                label={label}
                autoComplete={autoComplete}
                type={type}
                placeholder={placeholder}
                min={min}
                max={max}
                maxLength={maxLength}
                step={step}
                onFocus={(event) => {
                  setShouldTransform(true);
                }}
                onBlur={(event) => {
                  setShouldTransform(!isEmpty(event.target.value));
                }}
                autoFocus={autoFocusElement === name}
                ref={reference}
                tabIndex={tabIndex}
                pattern={pattern}
                list={list}
              />
              <label
                htmlFor={label}
                className={
                  shouldTransform ? `${labelClass} lbltransform` : labelClass
                }
              >
                {label}
              </label>
            </>
          )}
          rules={{ valueAsDate: type === "date" }}
        />
      </div>
      <div className={errorClass}>{error}</div>
      {postfix}
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.oneOf([
    "text",
    "password",
    "submit",
    "reset",
    "radio",
    "checkbox",
    "button",
    "hidden",
    "file",
    "image",
    "color",
    "date",
    "email",
    "number",
    "url",
    "tel",
    "time",
  ]),
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  hidden: PropTypes.bool,
  step: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
  isReadOnly: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  inputLabelProps: PropTypes.object,
  parentNode: PropTypes.string,
  multiline: PropTypes.bool,
  maxRows: PropTypes.number,
  tabIndex: PropTypes.string,
  list: PropTypes.string,
};

export { Input };
