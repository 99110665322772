import { flatten } from "lodash";
import React from "react";
import { Controller } from "react-hook-form";
import { useForm } from ".";
import PropTypes from "prop-types";

const Checkbox = (props) => {
  const {
    label = "",
    classes,
    name,
    disabled: disabledProp,
    mainClass,
    inputClass,
    postfix,
    labelClass,
    errorClass = "text-danger errorfont mh-18",
    reference,
    tabIndex,
    readOnly: isReadOnly,
    hidden = false,
    required = false,
    index = -1,
    parentNode = "",
    onChange = undefined,
    id = null,
  } = props;

  const {
    control,
    errors,
    formIsDisabled,
    formIsReadOnly,
    formIsLoading,
    autoFocusElement,
    getValues,
  } = useForm();

  const disabled = disabledProp || formIsDisabled;
  const readOnly = isReadOnly || formIsReadOnly || formIsLoading;

  const hasError = (errors) => {
    if (index !== -1) {
      return (
        errors &&
        errors[parentNode] &&
        errors[parentNode][index] &&
        errors[parentNode][index][name] &&
        errors[parentNode][index][name].message
      );
    }
    if (name && name.includes(".")) {
      const parts = flatten(
        name
          .split(".")
          .map((item) => item.split(/\[/).map((item) => item.replace("]", "")))
      );
      let error = errors;
      parts.forEach((part) => {
        error = error?.[part] || {};
      });
      return error ? error.message : undefined;
    }
    return errors && errors[name] ? errors[name].message : undefined;
  };

  const error = hasError(errors);

  return (
    <div className={mainClass}>
      <div className={classes}>
        <div style={{ display: "inline-flex", alignItems: "flex-start" }}>
          <Controller
            name={index !== -1 ? `${parentNode}[${index}].${name}` : name}
            control={control}
            defaultValue={getValues(
              index !== -1 ? `${parentNode}[${index}].${name}` : name
            )}
            render={({ field }) => (
              <input
                {...field}
                onChange={(event) => {
                  field.onChange(event);
                  if (onChange) onChange(event.target.checked);
                }}
                hidden={hidden}
                required={required}
                className={inputClass}
                disabled={disabled}
                readOnly={readOnly}
                type="checkbox"
                id={id || name}
                ref={reference}
                error={errorClass}
                tabIndex={tabIndex}
                defaultChecked={getValues(
                  index !== -1 ? `${parentNode}[${index}].${name}` : name
                )}
                autoFocus={autoFocusElement === name}
              />
            )}
          />
          <label htmlFor={label} className={labelClass}>
            {label}
          </label>
        </div>
      </div>
      <div className={errorClass}>{error}</div>
      {postfix}
    </div>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  hidden: PropTypes.bool,
  step: PropTypes.string,
  min: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  onChange: PropTypes.func,
  parentNode: PropTypes.string,
  index: PropTypes.number,
  mainClass: PropTypes.string,
  inputClass: PropTypes.string,
  classes: PropTypes.string,
  errorClass: PropTypes.string,
  tabIndex: PropTypes.string,
  id: PropTypes.string,
};

export { Checkbox };
