import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../Context/AppContext";
import { strings } from "../Common/String";
import Button from "../Component/Button";
import { LeadService } from "../Services/LeadService";
import Footer from "../footer";
import Header2 from "../HeaderStatus";
import { PATH } from "../Common/Path";
import useScript from "../useScript";
import { useHistory } from "react-router";
import { getCurrentStep } from "../Common/utils";

const PaymentPage = (props) => {
  useScript("https://checkout.razorpay.com/v1/checkout.js");
  const {
    steps,
    setSteps,
    setLoading,
    currentStep,
    setCurrentStep,
    setHasChanges,
  } = useContext(AppContext);
  const [formData, setFormData] = useState({});
  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(null);
  const [grandTotal, setGrandTotal] = useState(0);
  const [payments, setPayment] = useState([]);
  const history = useHistory();
  const [paid, setPaid] = useState(false);
  const [summary, setSummary] = useState({});
  const [couponAPIMessage, setCouponAPIMessage] = useState("");
  const [paymentAPIMessage, setPaymentAPIMessage] = useState("");

  useEffect(() => {
    setHasChanges(false);
    getMyPaymentDetails();
  }, []);

  useEffect(() => {
    steps.forEach((step) => {
      if (step.id === currentStep) {
        step.status = 1;
      } else if (step.id < currentStep) {
        step.status = 2;
      }
    });
    setSteps(steps);
  }, [currentStep]);
  /**
   * call previous state details
   */
  const getMyPaymentDetails = () => {
    setLoading(true);
    LeadService.getPaymentAmount()
      .then((data) => {
        setCurrentStep(getCurrentStep(data.current_step));
        if (data.success) {
          setPayment(data.summary);
          setSummary(data);
          setPaid(data.paid);
          setTotal(data.amount || 0);
          setDiscount(data.discount);
          setGrandTotal(data.total || 0);
          setFormData((formData) => ({
            ...formData,
            payment: data.summary,
            promoCode: data.coupon,
          }));
        } else {
          setFormData((formData) => ({ ...formData, payment: [] }));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  /**
   * submit payment
   */
  const onPromoSubmit = () => {
    if (!paid && formData.promoCode && formData.promoCode.length > 0) {
      setLoading(true);
      setCouponAPIMessage("");
      setPaymentAPIMessage("");
      LeadService.validatePromoCode(formData.promoCode)
        .then((data) => {
          if (data.success) {
            setDiscount(data.coupon_applied ? data.discount || 0 : null);
            setGrandTotal(data.total);
          } else {
            setDiscount(null);
            setGrandTotal(total);
            setCouponAPIMessage(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    }
  };
  /**
   * previous click
   */
  const PreviousClick = () => {
    history.push(PATH.SEGMENTS_PAGE);
  };
  const onPromoChange = (event) => {
    event.persist();
    setPaymentAPIMessage("");
    setCouponAPIMessage("");
    setFormData((oldData) => ({ ...oldData, promoCode: event.target.value }));
  };
  /**
   * payment click
   */
  const onPaymentClick = () => {
    if (!paid) {
      setLoading(true);
      setPaymentAPIMessage("");
      setCouponAPIMessage("");
      LeadService.requestPayment(formData.promoCode)
        .then((data) => {
          if (data.success) {
            if (data.paid) {
              //setPaid(data.paid)
              getMyPaymentDetails();
            }
            var options = {
              ...data.options,
              handler: function (response) {
                capturePayment(response);
              },
            };
            var rzp1 = new window.Razorpay(options);
            rzp1.on("payment.failed", function (response) {
              capturePayment(response);
            });
            rzp1.open();
          } else {
            if (!data.is_coupon_error) {
              setPaymentAPIMessage(data.error);
            } else {
              setCouponAPIMessage(data.error);
            }
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    }
  };

  const capturePayment = (response) => {
    console.log(response);
    setLoading(true);
    setPaymentAPIMessage("");
    setCouponAPIMessage("");
    LeadService.submitPayment({ details: response })
      .then((data) => {
        if (data.success) {
          getMyPaymentDetails();
        } else {
          //Show Error UI
          setPaymentAPIMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const nextClick = () => {
    history.push(PATH.INPERSON_PAGE);
  };

  return (
    <div className="inner-section">
      <div className="details">
        <div className="container-fluid">
          <div className="row">
            <Header2 />
            <div className="col-md-9 pl-3 pt-55 pb-5 pr-3 pl-md-5 pr-md-5">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="pb-4">
                    {strings.payment_head}{" "}
                    <span style={{ fontSize: 15 }}>{strings.head_sub}</span>
                  </h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="select-plan">
                    <h6>Summary</h6>
                    <div className="line-1 pt-3">
                      {payments.map((payment, index) => (
                        <div className="line-1" key={index}>
                          <div className="name-add">{payment.column_label}</div>
                          <div className="amount-rit">₹ {payment.amount}</div>
                        </div>
                      ))}
                      <div className="line-1">
                        <div className="name-add">
                          <h6>{strings.total}</h6>
                        </div>
                        <div className="amount-rit">
                          <b>₹ {total}</b>
                        </div>
                      </div>
                    </div>
                    <div className="inuput-box mt-2 d-inline-block">
                      <input
                        className="c-code paninput"
                        type="text"
                        disabled={paid}
                        id="promocode"
                        value={formData.promoCode || ""}
                        onChange={onPromoChange}
                        placeholder="Enter Promo code"
                      />
                      <a
                        className="apply-btn"
                        onClick={onPromoSubmit}
                        href="# "
                        style={{
                          textDecoration: "none",
                          backgroundColor: paid ? "#989797" : undefined,
                        }}
                      >
                        Apply Now
                      </a>

                      <div
                        id="txterror"
                        name="txterror"
                        className="text-danger coupenerrormessage mh-25"
                      >
                        {couponAPIMessage}
                      </div>
                    </div>

                    {discount && (
                      <div className="line-1">
                        <div className="name-add">{strings.discount_title}</div>
                        <div className="amount-rit">
                          {discount && "- "}₹ {discount}
                        </div>
                      </div>
                    )}
                    <div className="line-1 pt-3">
                      <div className="name-add">
                        <h6>{strings.grand_total}</h6>
                      </div>
                      <div className="amount-rit">
                        <b>₹ {grandTotal}</b>
                      </div>
                    </div>
                    {!paid && (
                      <div className="inp text-right pt-3">
                        <button
                          type="button"
                          onClick={onPaymentClick}
                          style={{
                            textDecoration: "none",
                            backgroundColor: paid ? "#989797" : undefined,
                          }}
                        >
                          Pay now
                        </button>
                      </div>
                    )}

                    <div className="text-danger coupenerrormessage">
                      <label id="txterror" name="txterror">
                        {paymentAPIMessage}
                      </label>{" "}
                    </div>
                  </div>
                </div>
              </div>
              {paid && (
                <img
                  className="paid"
                  src={summary && summary.seal_url ? summary.seal_url : ""}
                  alt=""
                />
              )}
              <div className="col-md-12">
                <div className="row">
                  <div className="col-6 pt-4">
                    <Button
                      classes="inp-back"
                      label={strings.previous}
                      click={PreviousClick}
                    />
                  </div>
                  {paid && (
                    <div className="col-6 pt-3 text-right">
                      <Button
                        classes="inp"
                        classes1="buttonmystyle"
                        label={strings.nextbtn}
                        click={nextClick}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default PaymentPage;
