import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../Context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { strings } from "../Common/String";
import Button from "../Component/Button";
import { LeadService } from "../Services/LeadService";
import { format_without_time, format_date_only } from "../Common/Constants";
import Footer from "../footer";
import Header2 from "../HeaderStatus";
import TextBox from "../Component/TextField";
import moment from "moment";
import DatePicker from "react-datepicker";
import { getCurrentStep, getPath } from "../Common/utils";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";

const Pan = (props) => {
  const { setLoading, setCurrentStep, currentStep, setHasChanges } =
    useContext(AppContext);
  const [disabledFields, setDisabledFields] = useState({
    pan: false,
    dob: false,
    name: false,
  });
  const [showFields, setShowFields] = useState({
    pan: true,
    dob: true,
    name: false,
    verifyButton: false,
    confirmButton: false,
    wrongName: false,
    note: false,
    refetch_left: false,
  });

  const [verifyVisible, setVerifyVisible] = useState(true);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [visibleWrongName, setVisiblewrongname] = useState(false);
  const [panError, setPanError] = useState("");
  const [panFetched, setPanFetched] = useState(false);
  const [dateError, setDateError] = useState(true);
  const { steps, setSteps } = useContext(AppContext);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const [apiMessage, setApiMessage] = useState("");
  const [formData, setFormData] = useState({ dob: null });
  const [disabled, setDisabled] = useState(false);
  const [disabledName, setDisabledName] = useState(false);
  const [visibleConfirmMessage, setVisibleConfirmMessage] = useState(false);
  const panRef = useRef();
  const dobRef = useRef();
  const nameRef = useRef();
  const years = range(1900, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    setHasChanges(false);
    panRef.current.focus();
    loadState();
  }, []);

  useEffect(() => {
    steps.forEach((step) => {
      if (step.id === currentStep) {
        step.status = 1;
      } else if (step.id < currentStep) {
        step.status = 2;
      }
    });
    setSteps(steps);
  }, []);

  const loadState = (isWrongDetails = false) => {
    setLoading(true);
    setApiMessage("");
    LeadService.getLeadPanDetails()
      .then((data) => {
        console.log(data);
        setCurrentStep(getCurrentStep(data.current_step));
        if (data.success) {
          setFormData({
            pan: isWrongDetails ? data.pan || formData.pan : data.pan,
            dob: isWrongDetails
              ? data.dob
                ? moment(data.dob)
                : formData.dob
              : data.dob
              ? moment(data.dob)
              : null,
            name: isWrongDetails
              ? data.pan_name || formData.name
              : data.pan_name,
          });

          let sf = { ...showFields };
          let df = { ...disabledFields };
          // Conditions
          if (data.pan_fetched) {
            if (data.nsdl_fetched === 1) {
              df.pan = true;
              df.dob = true;
              df.name = true;
              sf.pan = true;
              sf.dob = true;
              sf.name = false;
              sf.confirmButton = true;
              sf.note = true;
              sf.wrongName = false;
              sf.verifyButton = false;
            } else if (data.name_fetched) {
              df.pan = true;
              df.dob = true;
              df.name = true;
              sf.pan = true;
              sf.dob = true;
              sf.name = true;
              sf.confirmButton = true;
              sf.note = true;
              sf.wrongName = false;
              sf.verifyButton = false;
            } else {
              df.pan = true;
              df.dob = true;
              df.name = false;
              sf.pan = true;
              sf.dob = true;
              sf.name = true;
              sf.verifyButton = true;
              sf.confirmButton = false;
              sf.note = false;
              sf.wrongName = false;
            }
          } else {
            if (data.fetch_name) {
              df.pan = false;
              df.dob = false;
              df.name = true;
              sf.pan = true;
              sf.dob = true;
              sf.name = false;
              sf.verifyButton = true;
              sf.confirmButton = false;
              sf.note = false;
              sf.wrongName = false;
            } else {
              df.pan = false;
              df.dob = false;
              df.name = false;
              sf.pan = true;
              sf.dob = true;
              sf.name = true;
              sf.verifyButton = true;
              sf.confirmButton = false;
              sf.note = false;
              sf.wrongName = false;
            }
          }

          setShowFields(sf);
          setDisabledFields(df);

          // setVisiblewrongname(false);
          // if (!data.pan_fetched) {
          //   setPanFetched(false);
          //   setDisabled(false);
          //   setDisabledName(false);
          //   setConfirmVisible(false);
          //   setVerifyVisible(true);
          //   setVisibleConfirmMessage(false);
          // } else {
          //   setPanFetched(true);
          //   setDisabled(true);
          //   if (data.name_fetched) {
          //     setVisibleConfirmMessage(true);
          //     setConfirmVisible(true);
          //     setVerifyVisible(false);
          //     setDisabledName(true);
          //   } else {
          //     setDisabledName(false);
          //     setVisibleConfirmMessage(false);
          //     setConfirmVisible(false);
          //     setVerifyVisible(true);
          //   }
          // }
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  /**
   * pan validation
   * @param {*} event
   * @returns
   */

  // const validatepan = (pan) => {
  //   let isValid=true;
  //   const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  //   if (regpan.test(pan)) {
  //     setPanError(" ");
  //   } else {
  //     setPanError(strings.validpan);
  //     setConfirmVisible(false);
  //     setVerifyVisible(true);
  //     setVisiblewrongname(false);
  //      isValid=false;
  //   }
  //   return isValid;
  // };

  /**
   * validation for verify
   */

  const mandatoryValidationVerify = () => {
    let isValid = true;
    if (showFields.name) {
      if (!formData.name) {
        setErrors((oldErrors) => ({ ...oldErrors, name: true }));
        isValid = false;
      } else {
        setErrors((oldErrors) => ({ ...oldErrors, name: false }));
      }
    }
    if (showFields.dob) {
      if (!formData.dob) {
        setDateError(strings.mandatory);
        isValid = false;
      } else {
        setDateError("");
      }
    }

    if (showFields.pan) {
      if (!formData.pan) {
        setPanError(strings.mandatory);
        isValid = false;
      } else {
        const regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        if (regpan.test(formData.pan)) {
          setPanError(" ");
        } else {
          setPanError(strings.validpan);
          isValid = false;
        }
      }
    }
    return isValid;
  };

  /**
   * pan validation Api call
   */
  const verifyPAN = () => {
    if (mandatoryValidationVerify()) {
      setLoading(true);
      let body = {
        pan: formData.pan,
        dob: moment(formData.dob).format(format_without_time),
        name: formData.name,
      };
      setApiMessage("");
      LeadService.validatePAN(body)
        .then((data) => {
          if (data.success) {
            let sf = { ...showFields };
            let df = { ...disabledFields };
            setFormData((fd) => ({ ...fd, name: data.pan_name || fd.name }));
            if (data.pan_fetched || !data.fetch_name) {
              df.pan = true;
              df.dob = true;
              df.name = true;
              sf.pan = true;
              sf.dob = true;
              sf.name = true;
              sf.confirmButton = true;
              sf.note = true;
              sf.wrongName = true;
              sf.verifyButton = false;
            } else if (
              data.fetch_name &&
              data.refetch_left > 0
            ) {
              df.pan = true;
              df.dob = true;
              df.name = true;
              sf.pan = true;
              sf.dob = true;
              sf.name = false;
              sf.confirmButton = true;
              sf.note = true;
              sf.wrongName = true;
              sf.verifyButton = false;
              sf.refetch_left = true;
            } else if (
              !data.pan_fetched &&
              data.fetch_name &&
              data.nsdl_fetched === 1
            ) {
              df.pan = true;
              df.dob = true;
              df.name = true;
              sf.pan = true;
              sf.dob = true;
              sf.name = false;
              sf.confirmButton = true;
              sf.note = true;
              sf.wrongName = true;
              sf.verifyButton = false;
            } else {
              df.pan = true;
              df.dob = true;
              df.name = false;
              sf.pan = true;
              sf.dob = true;
              sf.name = true;
              sf.confirmButton = true;
              sf.note = true;
              sf.wrongName = true;
              sf.verifyButton = false;
            }
            setShowFields(sf);
            setDisabledFields(df);

            // setVisiblewrongname(true);
            // setConfirmVisible(true);
            // setVerifyVisible(false);
            // setDisabled(true);
            // setDisabledName(true);
            // setVisibleConfirmMessage(true);
          } else {
            setApiMessage(data.error);
            // if (data.error === "PAN Exist") {
            //   formData.name = "";
            //   formData.dob = "";
            //   formData.pan = "";
            // }
            // setDisabled(false);
            // setDisabledName(false);
            // setVisiblewrongname(false);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setDisabled(false);
      setDisabledName(false);
    }
  };

  const RefetchPan = () => {
    if (mandatoryValidationVerify()) {
      setLoading(true);
      let body = {
        pan: formData.pan,
        dob: moment(formData.dob).format(format_without_time),
        name: formData.name,
      };
      setApiMessage("");
      LeadService.getRefetchPanDetails(body)
        .then((data) => {
          if (data.success) {
            let sf = { ...showFields };
            let df = { ...disabledFields };
            setFormData((fd) => ({ ...fd, name: data.pan_name || fd.name }));
            if (
              data.fetch_name &&
              data.refetch_left > 0
            ) {
              df.pan = true;
              df.dob = true;
              df.name = true;
              sf.pan = true;
              sf.dob = true;
              sf.name = false;
              sf.confirmButton = true;
              sf.note = true;
              sf.wrongName = true;
              sf.verifyButton = false;
              sf.refetch_left = true;
            }
            setShowFields(sf);
            setDisabledFields(df);
          } else {
            setApiMessage(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setDisabled(false);
      setDisabledName(false);
    }
  };

  // const validate = () => {
  //   return true;
  // };
  /**
   * varify user name
   */
  const handleConfirmSubmit = () => {
    if (mandatoryValidationVerify() && formData.dob) {
      setLoading(true);
      let body = {
        pan: formData.pan,
        dob: moment(formData.dob).format(format_without_time),
        name: formData.name,
      };
      setApiMessage("");
      LeadService.submitPANName(body)
        .then((data) => {
          setLoading(false);
          if (data.success) {
            setConfirmVisible(true);
            setVerifyVisible(false);
            setVisiblewrongname(true);
            history.push(getPath(data.next_step));
            setCurrentStep(getCurrentStep(data.current_step));
          } else {
            setApiMessage(data.error);
            setDisabled(true);
            setDisabledName(true);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false), setDisabled(false));
      // setVisibleSubmit(true);
      // setVisibleContinue(false);
    }
  };
  const enableFields = () => {
    setApiMessage("");
    setConfirmVisible(false);
    setVerifyVisible(true);
    setVisiblewrongname(false);
    setVisibleConfirmMessage(false);
    setDisabledName(false);
    if (panFetched) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };
  /**
   * validation and pan number submission
   * @param {*} event
   */
  const handleChange = (event) => {
    event.persist();
    setApiMessage("");
    if (event.target.name === "pan") {
      setFormData((oldData) => ({ ...oldData, pan: event.target.value }));
      setPanError(" ");
      let pan = event.target.value;
    }
    if (event.target.name === "name") {
      setFormData((oldData) => ({
        ...oldData,
        name: event.target.value.replace(/[^a-zA-Z ]/g, ""),
      }));
      setErrors((oldErrors) => ({ ...oldErrors, name: false }));
    }
    setHasChanges(true);
  };

  /**
   * datepicker onclick
   * @param {*} value
   */
  const handleChangeDate = (value) => {
    setFormData((oldData) => ({ ...oldData, dob: value }));
    setApiMessage("");
    setDateError("");
    setHasChanges(true);
  };

  return (
    <div className="inner-section">
      <div className="details">
        <div className="container-fluid">
          <div className="row">
            <Header2 />

            <div className="col-md-9 pl-5 pt-55 pb-5 pr-5">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="pb-4">{strings.panheading}</h5>
                </div>
                <div className="col-md-4 pb-3 ">
                  {showFields.pan && (
                    <>
                      <TextBox
                        reference={panRef}
                        tabIndex="1"
                        label={strings.pantext}
                        placehold={strings.pan_placeholder}
                        inputclass="paninput"
                        classes="inuput-box"
                        type="text"
                        textId="pan"
                        textMaxLength="10"
                        disabled={disabledFields.pan}
                        fieldValue={formData.pan || ""}
                        onTextChange={handleChange}
                      />
                      <div className="text-danger errorfont api-errormessage mh-25">
                        {panError}
                      </div>
                    </>
                  )}
                </div>
                <div className="col-md-4 pb-3">
                  {showFields.dob && (
                    <div className="inuput-box">
                      <label>{strings.dateofbirth}</label>

                      <DatePicker
                        renderCustomHeader={({
                          date,
                          changeYear,
                          changeMonth,
                          decreaseMonth,
                          increaseMonth,
                          prevMonthButtonDisabled,
                          nextMonthButtonDisabled,
                        }) => (
                          <div
                            style={{
                              margin: 10,
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              onClick={decreaseMonth}
                              disabled={prevMonthButtonDisabled}
                            >
                              {"<"}
                            </button>
                            <select
                              value={getYear(date)}
                              onChange={({ target: { value } }) =>
                                changeYear(value)
                              }
                              style={{ width: 75 }}
                            >
                              {years.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <select
                              value={months[getMonth(date)]}
                              onChange={({ target: { value } }) =>
                                changeMonth(months.indexOf(value))
                              }
                              style={{ width: 100 }}
                            >
                              {months.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>

                            <button
                              onClick={increaseMonth}
                              disabled={nextMonthButtonDisabled}
                            >
                              {">"}
                            </button>
                          </div>
                        )}
                        tabIndex="2"
                        ref={dobRef}
                        selected={
                          formData.dob ? moment(formData.dob)._d : undefined
                        }
                        onChange={(value) => handleChangeDate(value)}
                        onKeyDown={(e) => {
                          if (e.keyCode === 9)
                            dobRef.current.setOpen(false, true);
                        }}
                        maxDate={moment().subtract(18, "years")._d}
                        dateFormat={format_date_only}
                        placeholderText="DD-MM-YYYY"
                        disabled={disabledFields.dob}
                        className="datePicker options-margin-top paninput"
                        closeOnScroll={true}
                        shouldCloseOnSelect={true}
                        onChangeRaw={(date) => {
                          if (
                            date &&
                            date.currentTarget &&
                            date.currentTarget.value
                          ) {
                            dobRef.current.input.value =
                              date.currentTarget.value
                                .replace(/[^0-9\-]/g, "")
                                .substring(0, 10);
                          }
                        }}
                      />
                      <div className="text-danger errorfont api-errormessage mh-25">
                        {dateError}
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-4 pb-3 ">
                  {showFields.name && (
                    <TextBox
                      tabIndex="3"
                      reference={nameRef}
                      label={strings.panname}
                      placehold={strings.panname}
                      inputclass="paninput"
                      classes="inuput-box"
                      type="text"
                      textId="name"
                      disabled={disabledFields.name}
                      fieldValue={formData.name || ""}
                      onTextChange={handleChange}
                      error={errors.name}
                      errorclass="text-danger errorfont mh-25"
                      errorMessage={strings.mandatory}
                      // textPattern='[A-Za-z]'
                      //patternTitle={"Letters only"}
                    />
                  )}
                </div>
                <div className="col-md-12 text-center ">
                  <label
                    id="txterror "
                    className="api-errormessage text-danger "
                    name="txterror"
                  >
                    {apiMessage}
                  </label>
                  {showFields.note && <p className="required">{strings.pan_note}</p>}
                </div>

                <div className="col-md-3  text-center" />
                <div className="col-md-6  text-center">
                  {showFields.verifyButton && (
                    <Button
                      classes="inp mt-4"
                      label={strings.verify}
                      click={verifyPAN}
                    />
                  )}
                  {showFields.confirmButton && (
                    <Button
                      classes="inp mt-4"
                      label={
                        formData && formData.name
                          ? <span>{strings.prefix_name}<br/>{formData.name}</span>
                          : strings.pansubmit
                      }
                      click={handleConfirmSubmit}
                    />
                  )}
                </div>
                <div className="col-md-3" />
                {showFields.wrongName && (
                  <div className="col-md-12 text-center pt-3">
                    <p>
                      <Button
                        classes="inp-back"
                        label={strings.pan_wrongDetails}
                        click={loadState}
                      />
                    </p>
                  </div>
                )}
                {showFields.refetch_left && (
                  <div className="col-md-12 text-center">
                    <p>
                      <Button
                        classes="inp-back"
                        label={strings.pan_wrongName}
                        click={RefetchPan}
                      />
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Pan;
