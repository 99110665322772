import React from "react";

function CheckBoxGroup(props) {
    const { label,
        mainclass,
        subclass,
        labelclass,
        htmlfor,
        options,
        error,
        errorMessage,
        errorclass,
        change,
        defaultValue,
        tabIndex } = props;
    return (
        <div className={mainclass}>
            <label htmlFor={htmlfor} className={labelclass}>{label}</label>
            <div className={subclass}>
                {
                    options.map((item, index) => (
                        <span key={index}>
                            <div className="show-inp">
                                <label className="sub-add" style={{paddingTop: 1}}>{item.label}
                                    <input type="checkbox" id={item.value} name={item.label} value={item.value} onChange={change} checked={defaultValue && defaultValue.length > 0 ? defaultValue.includes(item.value) : false} tabIndex={tabIndex} />
                                    <span className="geekmark"></span>
                                </label>
                            </div>
                        </span>
                    ))
                }
            </div>
            {
                error && (
                    <div className={errorclass}>{errorMessage}</div>
                )
            }
        </div>
    );
}

export default CheckBoxGroup;