import React from "react";
//import PropTypes from "prop-types";

/**
 *Reusable Button
 * @param {*} props
 * @returns
 */
function Button(props) {
  const {
    label,
    click,
    classes,
    tabIndex = "-1",
    prefix,
    classes1,
    postfix,
    btndisable,
    type = "button",
  } = props;
  return (
    <div className={classes || ""}>
      {prefix}
      <button
        tabIndex={tabIndex}
        type={type}
        disabled={btndisable}
        onClick={click}
        className={classes1 || ""}
      >
        {label}
        {postfix}
      </button>
    </div>
  );
}

export default Button;
