import React, { useEffect, useRef, useState } from "react";
import { strings } from "../Common/String";
import AlertBox from "./AlertBox";
import Button from "./Button";

function AadharDialog(props) {
  const { show, cancelButtonAction, confirmButtonAction } = props;
  const [field1, setField1] = useState("");
  const [field2, setField2] = useState("");
  const [field3, setField3] = useState("");
  const [visibleApiMesg, setVisibleApiMesg] = useState(false);
  const [apiMessage, setApiMessage] = useState("");
  const field1Ref = useRef();
  const field2Ref = useRef();
  const field3Ref = useRef();

  useEffect(() => {
    if (show) {
      field3Ref.current.focus();
    }
  }, [show]);

  const onChange = (e) => {
    setVisibleApiMesg(false);
    if (e.target.value) {
      if (e.target.name === "field3") {
        if (e.target.value.length > 0 && e.target.value.length < 5) {
          setField3(e.target.value);
        }
      }
    }
  };

  const onCancel = () => {
    setField1("");
    setField2("");
    setField3("");
    cancelButtonAction();
  };

  const onSubmit = () => {
    if (field3.length === 4) {
      setVisibleApiMesg(false);
      confirmButtonAction("0000" + "0000" + field3);
    } else {
      setVisibleApiMesg(true);
      setApiMessage(strings.error_aadhar);

      //to show error message
    }
  };

  return (
    <div className="loaderContainer">
      <AlertBox
        show={show}
        title={strings.aadhar_message}
        confirmButtonText={<Button
          classes="inp aadhar-btn"
          label={strings.continue}
        />}
        cancelButtonText={strings.cancel_title}
        confirmButtonAction={onSubmit}
        cancelButtonAction={onCancel}
      >
        <div className="contents-area text-center">
          <input
            className="aadhar-input"
            value={"XXXX"}
            ref={field1Ref}
            tabIndex="1"
            name="field1"
            type="text"
            placeholder="XXXX"
            maxLength="4"
            disabled={true}
          />
          <input
            className="aadhar-input"
            value={"XXXX"}
            ref={field2Ref}
            tabIndex="2"
            name="field2"
            type="text"
            placeholder="XXXX"
            maxLength="4"
            disabled={true}
          />
          <input
            className="aadhar-input"
            value={field3}
            ref={field3Ref}
            tabIndex="3"
            name="field3"
            type="number"
            placeholder="XXXX"
            maxLength="4"
            onChange={onChange}
          />
        </div>
        <div className="contents-area text-center mh-25">
          <div className="text-danger text-center mh-25">
            <label id="txterror" name="txterror" className="mb-0">
              {visibleApiMesg && apiMessage}
            </label>
          </div>
        </div>
      </AlertBox>
    </div>
  );
}
export default AadharDialog;
