import { flatten } from "lodash";
import React from "react";
import { Controller } from "react-hook-form";
import { useForm } from ".";
import PropTypes from "prop-types";

const Radio = (props) => {
  const {
    label = "",
    classes,
    name,
    disabled: disabledProp,
    mainClass,
    inputClass,
    postfix,
    labelClass,
    errorClass = "text-danger errorfont mh-18",
    reference,
    tabIndex,
    readOnly: isReadOnly,
    hidden = false,
    required = false,
    index = -1,
    parentNode = "",
    value,
    onChange,
    showError = true,
  } = props;

  const {
    control,
    errors,
    formIsDisabled,
    formIsReadOnly,
    formIsLoading,
    getValues,
  } = useForm();

  const disabled = disabledProp || formIsDisabled;
  const readOnly = isReadOnly || formIsReadOnly || formIsLoading;

  const hasError = (errors) => {
    if (index !== -1) {
      return (
        errors &&
        errors[parentNode] &&
        errors[parentNode][index] &&
        errors[parentNode][index][name] &&
        errors[parentNode][index][name].message
      );
    }
    if (name && name.includes(".")) {
      const parts = flatten(
        name
          .split(".")
          .map((item) => item.split(/\[/).map((item) => item.replace("]", "")))
      );
      let error = errors;
      parts.forEach((part) => {
        error = error?.[part] || {};
      });
      return error ? error.message : undefined;
    }
    return errors && errors[name] ? errors[name].message : undefined;
  };

  const error = hasError(errors);

  return (
    <div className={mainClass}>
      <div style={{ display: "inline-flex" }}>
        <Controller
          name={index !== -1 ? `${parentNode}[${index}].${name}` : name}
          control={control}
          defaultValue={getValues(
            index !== -1 ? `${parentNode}[${index}].${name}` : name
          )}
          render={({ field }) => (
            <>
              <div className={classes}>
                <input
                  {...field}
                  onChange={(event) => {
                    field.onChange(event);
                    if (onChange) onChange(event.target.value);
                  }}
                  tabIndex={tabIndex}
                  type="radio"
                  hidden={hidden}
                  required={required}
                  className={inputClass}
                  disabled={disabled}
                  readOnly={readOnly}
                  ref={reference}
                  error={errorClass}
                  value={value}
                  checked={
                    value ===
                    getValues(
                      index !== -1 ? `${parentNode}[${index}].${name}` : name
                    )
                  }
                />
                <label className={labelClass}>{label} </label>
              </div>
            </>
          )}
        />
      </div>
      {showError ? <div className={errorClass}>{error}</div> : null}
      {postfix}
    </div>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  classes: PropTypes.string,
  hidden: PropTypes.bool,
  step: PropTypes.string,
  min: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  parentNode: PropTypes.string,
  index: PropTypes.number,
  mainClass: PropTypes.string,
  inputClass: PropTypes.string,
  labelClass: PropTypes.string,
  errorClass: PropTypes.string,
  tabIndex: PropTypes.string,
  value: PropTypes.string.isRequired,
  showError: PropTypes.bool,
};

export { Radio };
