import React from "react";
import { Switch, Route } from "react-router-dom";
import MobilePage from "./MobilePage";
import CreatePassword from "./CreatePasswordPage";
import Email from "./EmailPage";
import NotFound from "./NotFound";
import Pan from "./PanPage";
import PersonalDetailsBasic from "./PersonalDetailsBasic";
import PersonalDetailsAddress from "./PersonalDetailsAddress";
import PersonalDetailsOthers from "./PersonalDetailsOthers";
import BankDetails from "./BankDetails";
import Relationship from "./Relationship";
import Esign from "./Esign";
import UploadProofs from "./UploadProofs";
import Signin from "./Signin";
import BankAccount from "./AddBank"

import ReKYCSignin from "./ReKYC/Signin";
import ClosureSignin from "./Closure/Signin";
import ReKYCThankYou from "./ReKYC/ThankYou";
import ReKYCEsign from "./ReKYC/Esign";
import ReKYCUploads from "./ReKYC/UploadProofs";
import ReKYCIPV from "./ReKYC/IPV";
import RekycLeadStatus from "./ReKYC/Status";
import { SummaryPage } from "./ReKYC/Summary";
import ClosureSummary from "./Closure/Summary";
import ClosureUploads from "./Closure/UploadProofs";
import ClosureEsign from "./Closure/Esign";
import ClosureThankYou from "./Closure/ThankYou";
import ClosureStatus from "./Closure/Status";

import TermsConditions from "./TermsConditions";
import SegmentPage from "./SegmentPage";
import ThankYou from "./ThankYou";
import DigiLocker from "./DigiLocker";
import PaymentPage from "./PaymentPage";
import InPerson from "./InPerson";
import { PATH } from "./Common/Path";
import LeadStatus from "./LeadStatus";
import NRI from "./NRI";

import PersonalDetailsNominee from "./PersonalDetailsNominee";

const Routes = () => {
  return (
    <Switch>
      <Route exact path={PATH.MOBILE_PAGE} component={MobilePage} />
      <Route path={PATH.EMAIL_PAGE} component={Email} />
      <Route path={PATH.CREATEPASSWORD_PAGE} component={CreatePassword} />
      <Route path={PATH.SIGNIN_PAGE} component={Signin} />
      <Route path={PATH.PERSONALBASIC_PAGE} component={PersonalDetailsBasic} />
      <Route
        path={PATH.PERSONALADDRESS_PAGE}
        component={PersonalDetailsAddress}
      />
      <Route
        path={PATH.PERSONALNOMINEE_PAGE}
        component={PersonalDetailsNominee}
      />
      <Route path={PATH.PERSONALOTHER_PAGE} component={PersonalDetailsOthers} />
      <Route path={PATH.BANKDETAILS_PAGE} component={BankDetails} />
      <Route path={PATH.BANKACCOUNT_PAGE} component={BankAccount} />
      <Route path={PATH.RELATIONSHIP_PAGE} component={Relationship} />
      <Route path={PATH.TERMSANSCONDITION_PAGE} component={TermsConditions} />
      <Route path={PATH.SEGMENTS_PAGE} component={SegmentPage} />
      <Route path={PATH.VERIFICATIONDIGILOCK_PAGE} component={DigiLocker} />
      <Route path={PATH.VERIFICATIONPAN_PAGE} component={Pan} />
      <Route path={PATH.UPLOADPROOFS_PAGE} component={UploadProofs} />
      <Route path={PATH.INPERSON_PAGE} component={InPerson} />
      <Route path={PATH.ESIGN_PAGE} component={Esign} />
      <Route path={PATH.PAYMENT_PAGE} component={PaymentPage} />
      <Route path={PATH.THANKYOU_PAGE} component={ThankYou} />
      <Route path={PATH.LEADSTATUS_PAGE} component={LeadStatus} />
      <Route path={PATH.NRI_PAGE} component={NRI} />

      {/* RE-KYC */}
      <Route path={PATH.REKYC_SIGNIN_PAGE} component={ReKYCSignin} />
      <Route path={PATH.REKYC_THANKYOU_PAGE} component={ReKYCThankYou} />
      <Route path={PATH.REKYC_ESIGN_PAGE} component={ReKYCEsign} />
      <Route path={PATH.REKYC_UPLOAD_PAGE} component={ReKYCUploads} />
      <Route path={PATH.REKYC_IPV_PAGE} component={ReKYCIPV} />
      <Route path={PATH.REKYC_SUMMARY_PAGE} component={SummaryPage} />
      <Route path={PATH.REKYC_STATUS_PAGE} component={RekycLeadStatus} />

      {/* Closure */}
      <Route path={PATH.CLOSURE_SIGNIN_PAGE} component={ClosureSignin} />
      <Route path={PATH.CLOSURE_SUMMARY_PAGE} component={ClosureSummary} />
      <Route path={PATH.CLOSURE_UPLOAD_PAGE} component={ClosureUploads} />
      <Route path={PATH.CLOSURE_ESIGN_PAGE} component={ClosureEsign} />
      <Route path={PATH.CLOSURE_THANKYOU_PAGE} component={ClosureThankYou} />
      <Route path={PATH.CLOSURE_STATUS_PAGE} component={ClosureStatus} />

      {/* Not found */}
      <Route component={NotFound} />
    </Switch>
  );
};
export default Routes;
