import React from "react";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

const SingleCheckbox = (props) => {
  const {
    label = "",
    classes,
    name,
    disabled,
    mainClass,
    inputClass,
    postfix,
    labelClass,
    errorClass = "text-danger errorfont mh-18",
    reference,
    tabIndex,
    readOnly,
    hidden = false,
    required = false,
    onChange = undefined,
    checked,
    defaultChecked,
    tooltip = "",
  } = props;

  return (
    <>
      <div className={mainClass}>
        <div className={classes}>
          <div
            data-for="checkbox"
            data-tip={tooltip}
            style={{ display: "inline-flex" }}
          >
            <input
              name={name}
              onChange={(event) => {
                if (onChange) onChange(event.target.checked);
              }}
              hidden={hidden}
              required={required}
              className={inputClass}
              disabled={disabled}
              readOnly={readOnly}
              type="checkbox"
              id={name}
              ref={reference}
              error={errorClass}
              tabIndex={tabIndex}
              checked={checked}
              defaultChecked={defaultChecked}
            />
            <label htmlFor={label} className={labelClass}>
              {label}
            </label>
          </div>
        </div>
        {postfix}
      </div>
      <ReactTooltip
        id="checkbox"
        className="tooltip-text"
        effect="solid"
        getContent={(dataTip) => dataTip}
      />
    </>
  );
};

SingleCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  hidden: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  mainClass: PropTypes.string,
  inputClass: PropTypes.string,
  classes: PropTypes.string,
  errorClass: PropTypes.string,
  tabIndex: PropTypes.string,
};

export { SingleCheckbox };
