import { createContext, useContext } from "react"

const FormContext = createContext()

const Form = ({ reactHookForm, onSubmit = values => { }, onError = values => { }, isDisabled = false, isLoading = false, isReadOnly = false, autoFocusElement = null, children }) => {
  const { handleSubmit, register, formState: { errors }, control, watch, setValue, getValues } = reactHookForm
  return (
    <FormContext.Provider value={{ register, errors, control, watch, setValue, getValues, isDisabled, isLoading, isReadOnly, autoFocusElement }}>
      <form
        onSubmit={event => {
          event.preventDefault()
          handleSubmit(onSubmit, onError)(event)
        }}
        //onKeyDown={preventEnterSubmittingForm} 
        noValidate
      >
        {children}
      </form>
    </FormContext.Provider>
  )
}

const useForm = () => {
  const context = useContext(FormContext)
  if (!context) throw new Error("Form compound components cannot be rendered outside the Form component")
  const { register, control, watch, setValue, getValues, isDisabled, isLoading, isReadOnly, errors, autoFocusElement } = context
  return { register, control, watch, errors, setValue, getValues, formIsDisabled: isDisabled, formIsLoading: isLoading, formIsReadOnly: isReadOnly, autoFocusElement }
}

export { Form, useForm }
