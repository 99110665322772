import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import throttle from "lodash/throttle";
import { AppContext } from "../Context/AppContext";
import Button from "../Component/Button";
import { LeadService } from "../Services/LeadService";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../footer";
import Header2 from "../HeaderStatus";
import TextBox from "../Component/TextField";
import { strings } from "../Common/String";
import { MasterService } from "../Services/MasterService";
import { PATH } from "../Common/Path";
import { getCurrentStep, getPath } from "../Common/utils";
import PincodeBoxV2 from "../Component/PincodeBoxV2";

const PersonalDetailsAddress = (props) => {
  const {
    steps,
    setSteps,
    setLoading,
    currentStep,
    setCurrentStep,
    showConfirmDialog,
    closeConfirmDialog,
    hasChanges,
    setHasChanges,
  } = useContext(AppContext);
  const [errors, setErrors] = useState({
    permanent_flat: false,
    permanent_street: false,
    permanent_area: false,
    permanent_pincode: false,
    permanent_city: false,
    permanent_state: false,
    current_flat: false,
    current_street: false,
    current_area: false,
    current_pincode: false,
    current_city: false,
    current_state: false,
  });
  const [apiMessage, setApiMessage] = useState("");
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [options, setOptions] = React.useState([]);
  const [options2, setOptions2] = React.useState([]);
  const [disabledFields, setDisabledFields] = useState([]);
  const currentRef = useRef();
  const [showMsg, setShowMsg] = useState(true)

  useEffect(() => {
    setHasChanges(false);
    setLoading(true);
    LeadService.fillPersonalDetails("address")
      .then((data) => {
        setCurrentStep(getCurrentStep(data.current_step));
        let formData = {};
        if (data.success) {
          formData.permanent_flat = data.permanent_addr1;
          formData.permanent_street = data.permanent_addr2;
          formData.permanent_area = data.permanent_addr3;
          formData.permanent_pincode = data.permanent_pincode;
          formData.permanent_city = data.permanent_city;
          formData.permanent_state = data.permanent_state;
          if (data.current_addr_same === "Y") {
            setShowMsg(false)
            formData.sameAsPermanent = true;
            formData.sameAsPermanentStatus = "Y";
            formData.current_flat = data.permanent_addr1;
            formData.current_street = data.permanent_addr2;
            formData.current_area = data.permanent_addr3;
            formData.current_pincode = data.permanent_pincode;
            formData.current_city = data.permanent_city;
            formData.current_state = data.permanent_state;
          } else {
            setShowMsg(true)
            formData.sameAsPermanent = false;
            formData.sameAsPermanentStatus = "N";
            formData.current_flat = data.current_addr1;
            formData.current_street = data.current_addr2;
            formData.current_area = data.current_addr3;
            formData.current_pincode = data.current_pincode;
            formData.current_city = data.current_city;
            formData.current_state = data.current_state;
          }

          setDisabledFields(data.disabled_fields || []);
        } else {
          formData.permanent_flat = "";
          formData.permanent_street = "";
          formData.permanent_area = "";
          formData.permanent_pincode = "";
          formData.permanent_city = "";
          formData.permanent_state = "";
          formData.current_flat = "";
          formData.current_street = "";
          formData.current_area = "";
          formData.current_pincode = "";
          formData.current_city = "";
          formData.current_state = "";
        }
        setFormData((oldData) => ({ ...oldData, ...formData }));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
    currentRef.current.focus();
  }, [true]);

  useEffect(() => {
    steps.forEach((step) => {
      if (step.id === currentStep) {
        step.status = 1;
      } else if (step.id < currentStep) {
        step.status = 2;
      }
    });
    setSteps(steps);
  }, [currentStep]);
  /**
   * validation for final submit
   */

  const validate = () => {
    let isValid = true;
    let err = Object.assign({}, errors);
    if (
      !formData.permanent_area ||
      formData.permanent_area.trim().length === 0
    ) {
      err.permanent_area = true;
      isValid = false;
    } else {
      err.permanent_area = false;
    }

    if (!formData.current_area || formData.current_area.trim().length === 0) {
      err.current_area = true;
      isValid = false;
    } else {
      err.current_area = false;
    }

    if (
      !formData.permanent_pincode ||
      (typeof formData.permanent_pincode === "string" &&
        formData.permanent_pincode.trim().length === 0)
    ) {
      err.permanent_pincode = true;
      isValid = false;
    } else {
      err.permanent_pincode = false;
    }

    if (
      !formData.current_pincode ||
      (typeof formData.current_pincode === "string" &&
        formData.current_pincode.trim().length === 0)
    ) {
      err.current_pincode = true;
      isValid = false;
    } else {
      err.current_pincode = false;
    }

    if (
      !formData.permanent_city ||
      formData.permanent_city.trim().length === 0
    ) {
      err.permanent_city = true;
      isValid = false;
    } else {
      err.permanent_city = false;
    }

    if (!formData.current_city || formData.current_city.trim().length === 0) {
      err.current_city = true;
      isValid = false;
    } else {
      err.current_city = false;
    }

    if (
      !formData.permanent_flat ||
      formData.permanent_flat.trim().length === 0
    ) {
      err.permanent_flat = true;
      isValid = false;
    } else {
      err.permanent_flat = false;
    }

    if (!formData.current_flat || formData.current_flat.trim().length === 0) {
      err.current_flat = true;
      isValid = false;
    } else {
      err.current_flat = false;
    }

    if (
      !formData.permanent_state ||
      formData.permanent_state.trim().length === 0
    ) {
      err.permanent_state = true;
      isValid = false;
    } else {
      err.permanent_state = false;
    }

    if (!formData.current_state || formData.current_state.trim().length === 0) {
      err.current_state = true;
      isValid = false;
    } else {
      err.current_state = false;
    }

    if (
      !formData.permanent_street ||
      formData.permanent_street.trim().length === 0
    ) {
      err.permanent_street = true;
      isValid = false;
    } else {
      err.permanent_street = false;
    }

    if (
      !formData.current_street ||
      formData.current_street.trim().length === 0
    ) {
      err.current_street = true;
      isValid = false;
    } else {
      err.current_street = false;
    }

    setErrors(err);
    return isValid;
  };

  /**
   * button click
   * @param {*} event
   */
  const nextClick = () => {
    //console.log(formData.permanent_flat, '...', formData.current_flat, '...', formData.permanent_street, '...', formData.current_street, '...', formData.permanent_area, '...', formData.current_area, '...', formData.permanent_city, '...', formData.current_city, '...', formData.permanent_state, "...", formData.current_state, "...", formData.permanent_pincode, "...", formData.current_pincode);
    if (validate()) {
      setLoading(true);
      let body = {
        type: "address",
        details: {
          permanent_addr1: formData.permanent_flat.trim(),
          permanent_addr2: formData.permanent_street.trim(),
          permanent_addr3: formData.permanent_area.trim(),
          permanent_city: formData.permanent_city.trim(),
          permanent_state: formData.permanent_state.trim(),
          permanent_country: "India",
          permanent_pincode:
            typeof formData.permanent_pincode === "string"
              ? formData.permanent_pincode.trim()
              : formData.permanent_pincode,
          current_addr_same: formData.sameAsPermanentStatus,
          current_addr1: formData.current_flat.trim(),
          current_addr2: formData.current_street.trim(),
          current_addr3: formData.current_area.trim(),
          current_city: formData.current_city.trim(),
          current_state: formData.current_state.trim(),
          current_country: "India",
          current_pincode:
            typeof formData.current_pincode === "string"
              ? formData.current_pincode.trim()
              : formData.current_pincode,
          // key:formData.key,
        },
      };
      setApiMessage("");
      LeadService.submitAboutYouAddress(body)
        .then((data) => {
          if (data.success) {
            history.push(getPath(data.next_step));
          } else {
            setApiMessage(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    } else {
    }
  };
  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        MasterService.getPincodeList(request.input).then((data) =>
          callback(data)
        );
      }, 200),
    []
  );
  const pincodeSelect = (field, location) => {
    if (field === "permanent_pincode") {
      setFormData((oldData) => ({
        ...oldData,
        [field]: location.pincode,
        permanent_city: location.city,
        permanent_state: location.state,
      }));
      setErrors((oldErrors) => ({
        ...oldErrors,
        [field]: false,
        permanent_city: false,
        permanent_state: false,
      }));
    }
    if (field === "current_pincode" || formData.sameAsPermanent) {
      setFormData((oldData) => ({
        ...oldData,
        current_pincode: location.pincode,
        current_city: location.city,
        current_state: location.state,
      }));
      setErrors((oldErrors) => ({
        ...oldErrors,
        [field]: false,
        current_city: false,
        current_state: false,
      }));
    }
    setOptions([]);
    setOptions2([]);
  };

  const pinHandleChange = (event) => {
    let active = true;
    fetch({ input: event.target.value }, (results) => {
      if (active) {
        let newOptions = [];
        // if (value) {
        //   newOptions = [value];
        // }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        if (event.target.name === "permanent_pincode") {
          setOptions(newOptions);
        } else {
          setOptions2(newOptions);
        }
      }
    });
    setFormData((oldData) => ({
      ...oldData,
      [event.target.name]: event.target.value,
    }));
    setErrors((oldErrors) => ({
      ...oldErrors,
      [event.target.name]:
        !event.target.value || event.target.value.trim().length === 0,
    }));
    setApiMessage("");
    setHasChanges(true);
  };

  /**
   * input onchange(this function saves the state of the form )
   * @param {} event
   */
  const handleChange = (event) => {
    setApiMessage("");
    // event.persist();
    if (
      event.target.name === "current_flat" ||
      event.target.name === "current_street" ||
      event.target.name === "current_area" ||
      event.target.name === "current_city" ||
      event.target.name === "current_state"
    ) {
      setFormData((oldData) => ({
        ...oldData,
        [event.target.name]: event.target.value,
      }));
      if (formData.sameAsPermanent) {
        const key = event.target.name.replace("current", "permanent");
        setFormData((oldData) => ({ ...oldData, [key]: event.target.value }));
      }
    } else if (
      event.target.name === "permanent_flat" ||
      event.target.name === "permanent_street" ||
      event.target.name === "permanent_area" ||
      event.target.name === "permanent_city" ||
      event.target.name === "permanent_state"
    ) {
      setFormData((oldData) => ({
        ...oldData,
        [event.target.name]: event.target.value,
      }));
    }
    if (event.target.name === "current_flat") {
      setErrors((oldErrors) => ({
        ...oldErrors,
        current_flat:
          !event.target.value || event.target.value.trim().length === 0,
      }));
    }
    if (event.target.name === "current_street") {
      setErrors((oldErrors) => ({
        ...oldErrors,
        current_street:
          !event.target.value || event.target.value.trim().length === 0,
      }));
    }
    if (event.target.name === "current_area") {
      setErrors((oldErrors) => ({
        ...oldErrors,
        current_area:
          !event.target.value || event.target.value.trim().length === 0,
      }));
    }
    if (event.target.name === "current_city") {
      setErrors((oldErrors) => ({
        ...oldErrors,
        current_city:
          !event.target.value || event.target.value.trim().length === 0,
      }));
    }
    if (event.target.name === "current_state") {
      setErrors((oldErrors) => ({
        ...oldErrors,
        current_state:
          !event.target.value || event.target.value.trim().length === 0,
      }));
    }
    if (event.target.name === "permanent_flat") {
      setErrors((oldErrors) => ({
        ...oldErrors,
        permanent_flat:
          !event.target.value || event.target.value.trim().length === 0,
      }));
    }
    if (event.target.name === "permanent_street") {
      setErrors((oldErrors) => ({
        ...oldErrors,
        permanent_street:
          !event.target.value || event.target.value.trim().length === 0,
      }));
    }
    if (event.target.name === "permanent_area") {
      setErrors((oldErrors) => ({
        ...oldErrors,
        permanent_area:
          !event.target.value || event.target.value.trim().length === 0,
      }));
    }
    if (event.target.name === "permanent_city") {
      setErrors((oldErrors) => ({
        ...oldErrors,
        permanent_city:
          !event.target.value || event.target.value.trim().length === 0,
      }));
    }
    if (event.target.name === "permanent_state") {
      setErrors((oldErrors) => ({
        ...oldErrors,
        permanent_state:
          !event.target.value || event.target.value.trim().length === 0,
      }));
    }

    setHasChanges(true);
  };

  /**
   * previous button
   */
  const previousClick = () => {
    if (hasChanges) {
      showConfirmDialog(strings.confirm_msg, undefined, () => {
        closeConfirmDialog();
        history.push(PATH.PERSONALBASIC_PAGE);
      });
    } else {
      history.push(PATH.PERSONALBASIC_PAGE);
    }
  };

  const copyAddress = () => {
    // if (formData.sameAsPermanent) {
    setFormData((formData) => ({
      ...formData,
      current_flat: formData.permanent_flat,
      current_street: formData.permanent_street,
      current_area: formData.permanent_area,
      current_pincode: formData.permanent_pincode,
      current_city: formData.permanent_city,
      current_state: formData.permanent_state,
    }));
    // }
    // else {
    //   setFormData(formData => ({
    //     ...formData,
    //     current_flat: "",
    //     current_street: "",
    //     current_area: "",
    //     current_pin: "",
    //     current_city: "",
    //     current_state: ""
    //   }))
    // }
  };

  /**
   * checkbox onchange function
   */
  const handleCheckBoxChange = (event) => {
    setApiMessage("");
    setFormData((formData) => ({
      ...formData,
      sameAsPermanent: event.target.checked,
    }));
    if (event.target.checked) {
      setErrors({});
      copyAddress();
      setShowMsg(false);
      setFormData((oldData) => ({ ...oldData, sameAsPermanentStatus: "Y" }));
    } else {
      setFormData((oldData) => ({ ...oldData, sameAsPermanentStatus: "N" }));
      setShowMsg(true);
    }
    setHasChanges(true);
  };

  return (
    <div className="inner-section details">
      <div className="container-fluid">
        <div className="row">
          <Header2 />
          {/* <div className=" pt-4 pb-4 pl-4 pr-4" /> */}
          <div className="col-md-9 pt-55 pb-5 pl-5 pr-5">
            <div className="row">
              <div className="col-md-12">
                <h5 className="pb-4">{strings.address_header}</h5>
              </div>
              <div className="col-md-12">
                <label>{strings.permanent_address}</label>
              </div>
              <TextBox
                reference={currentRef}
                tabIndex="1"
                mainclass="col-md-4"
                // label={strings.address_flat}
                placehold={strings.address_flat}
                classes="inuput-box"
                inputclass="mb-0"
                type="text"
                textId="permanent_flat"
                disabled={disabledFields.includes("permanent_addr1") || false}
                fieldValue={formData.permanent_flat || ""}
                onTextChange={handleChange}
                error={errors.permanent_flat}
                errorclass="text-danger errorfont mh-18"
                errorMessage={strings.mandatory}
              />
              <TextBox
                tabIndex="2"
                mainclass="col-md-4"
                // label={strings.address_street}
                placehold={strings.address_street}
                classes="inuput-box"
                inputclass="mb-0"
                type="text"
                textId="permanent_street"
                disabled={disabledFields.includes("permanent_addr2") || false}
                fieldValue={formData.permanent_street || ""}
                onTextChange={handleChange}
                error={errors.permanent_street}
                errorclass="text-danger errorfont mh-18"
                errorMessage={strings.mandatory}
              />
              <TextBox
                tabIndex="3"
                mainclass="col-md-4 mb-2"
                //  label={strings.address_area}
                placehold={strings.address_area}
                classes="inuput-box"
                inputclass="mb-0"
                type="text"
                textId="permanent_area"
                disabled={disabledFields.includes("permanent_addr3") || false}
                fieldValue={formData.permanent_area || ""}
                onTextChange={handleChange}
                error={errors.permanent_area}
                errorclass="text-danger errorfont mh-18"
                errorMessage={strings.mandatory}
              />
              <PincodeBoxV2
                tabIndex="4"
                mainclass="col-md-4"
                placehold={strings.pincode}
                classes="inuput-box"
                inputclass="mb-0"
                textMaxLength="6"
                textId="permanent_pincode"
                disabled={disabledFields.includes("permanent_pincode") || false}
                options={options || []}
                fieldValue={formData.permanent_pincode || ""}
                onTextChange={pinHandleChange}
                optionClick={(location) =>
                  pincodeSelect("permanent_pincode", location)
                }
                error={errors.permanent_pincode}
                errorclass="text-danger errorfont mh-18"
                errorMessage={strings.mandatory}
              />
              <TextBox
                tabIndex="5"
                mainclass="col-md-4 "
                //label={strings.city}
                placehold={strings.city}
                classes="inuput-box"
                inputclass="mb-0"
                type="text"
                //read={false}
                textId="permanent_city"
                disabled={disabledFields.includes("permanent_city") || false}
                fieldValue={formData.permanent_city || ""}
                onTextChange={handleChange}
                error={errors.permanent_city}
                errorclass="text-danger errorfont mh-18"
                errorMessage={strings.mandatory}
              />
              <TextBox
                tabIndex="6"
                mainclass="col-md-4 "
                //label={strings.state}
                placehold={strings.state}
                classes="inuput-box"
                inputclass="mb-0"
                type="text"
                //read={false}
                textId="permanent_state"
                disabled={disabledFields.includes("permanent_state") || false}
                fieldValue={formData.permanent_state || ""}
                onTextChange={handleChange}
                error={errors.permanent_state}
                errorclass="text-danger errorfont mh-18"
                errorMessage={strings.mandatory}
              />
              <div className="col-md-12 mt-3">
                <label>{strings.currentaddress}</label>
              </div>

              <div className="col-md-12 ">
                <span>
                  <div className="show-inp">
                    <label className="sub-add">
                      {strings.sameaspermaddress}
                      <input
                        type="checkbox"
                        placeholder={strings.permanent_address_holder}
                        onChange={handleCheckBoxChange}
                        checked={formData.sameAsPermanent || false}
                        tabIndex="7"
                      />
                      <span className="geekmark"></span>
                    </label>
                  </div>
                </span>
              </div>
              <TextBox
                tabIndex="8"
                mainclass="col-md-4"
                //  label={strings.address_flat}
                placehold={strings.address_flat}
                classes="inuput-box"
                inputclass="mb-0"
                type="text"
                textId="current_flat"
                disabled={formData.sameAsPermanent}
                fieldValue={formData.current_flat || ""}
                onTextChange={handleChange}
                error={errors.current_flat}
                errorclass="text-danger errorfont mh-18"
                errorMessage={strings.mandatory}
              />
              <TextBox
                tabIndex="9"
                mainclass="col-md-4"
                // label={strings.address_street}
                placehold={strings.address_street}
                classes="inuput-box"
                inputclass="mb-0"
                type="text"
                textId="current_street"
                disabled={formData.sameAsPermanent}
                fieldValue={formData.current_street || ""}
                onTextChange={handleChange}
                error={errors.current_street}
                errorclass="text-danger errorfont mh-18"
                errorMessage={strings.mandatory}
              />
              <TextBox
                tabIndex="10"
                mainclass="col-md-4 mb-2"
                //  label={strings.address_area}
                placehold={strings.address_area}
                classes="inuput-box"
                inputclass="mb-0"
                type="text"
                textId="current_area"
                disabled={formData.sameAsPermanent}
                fieldValue={formData.current_area || ""}
                onTextChange={handleChange}
                error={errors.current_area}
                errorclass="text-danger errorfont mh-18"
                errorMessage={strings.mandatory}
              />
              <PincodeBoxV2
                tabIndex="11"
                mainclass="col-md-4"
                placehold={strings.pincode}
                classes="inuput-box"
                inputclass="mb-0"
                type="text"
                textId="current_pincode"
                options={options2 || []}
                disabled={formData.sameAsPermanent}
                fieldValue={formData.current_pincode || ""}
                textMaxLength="6"
                // fieldValue={formData.pincode2? formData.pincode2:""|| input.pin2}
                onTextChange={pinHandleChange}
                optionClick={(location) =>
                  pincodeSelect("current_pincode", location)
                }
                error={errors.current_pincode}
                errorclass="text-danger errorfont mh-18"
                errorMessage={strings.mandatory}
              />

              <TextBox
                tabIndex="12"
                mainclass="col-md-4 "
                //label={strings.city}
                placehold={strings.city}
                classes="inuput-box"
                inputclass="mb-0"
                type="text"
                //read={false}
                textId="current_city"
                disabled={
                  formData.sameAsPermanent ||
                  disabledFields.includes("current_city") ||
                  false
                }
                fieldValue={formData.current_city || ""}
                //fieldValue={cityValue2}
                onTextChange={handleChange}
                error={errors.current_city}
                errorclass="text-danger errorfont mh-18"
                errorMessage={strings.mandatory}
              />
              <TextBox
                tabIndex="13"
                mainclass="col-md-4"
                //label={strings.state}
                placehold={strings.state}
                classes="inuput-box"
                inputclass="mb-0"
                type="text"
                //read={false}
                textId="current_state"
                disabled={
                  formData.sameAsPermanent ||
                  disabledFields.includes("current_state") ||
                  false
                }
                fieldValue={formData.current_state || ""}
                // fieldValue={stateValue2}
                onTextChange={handleChange}
                error={errors.current_state}
                errorclass="text-danger errorfont mh-18"
                errorMessage={strings.mandatory}
              />
              <div className="col-md-12">
                {
                  showMsg && (
                    <div className="alert alert-success" role="alert">
                      {strings.current_address_note}
                    </div>
                  )
                }
              </div>
              <div className="col-md-12">
                <div className="row mh-42">
                  <div className="col-md-12 pt-2">
                    {" "}
                    <div className="inp api-errormessage text-danger text-center">
                      <label id="txterror" name="txterror">
                        {apiMessage}
                      </label>{" "}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 pt-4">
                    <Button
                      classes="inp-back"
                      label={strings.previous}
                      click={previousClick}
                    />
                  </div>
                  <div className="col-6 pt-3 text-right">
                    <Button
                      classes="inp"
                      classes1="buttonmystyle"
                      label={strings.nextbtn}
                      click={nextClick}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default PersonalDetailsAddress;
