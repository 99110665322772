import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../Context/AppContext";
import Count from "../Component/CountComponent/Count";
import { strings } from "../Common/String";
import Button from "../Component/Button";
import EditPen from "../Component/EditPen";
import validator from "validator";
import GoogleOauth from "../googlesignin/GoogleOauth";
import { LeadService, } from "../Services/LeadService";
import OTPInput from "../Component/OTPInput";
import { OTP_TYPES } from "../Common/Constants";
import Header from "../Header";
import Footer from "../footer";
import SignInButton from "../Component/SignInButton";
import { PATH } from "../Common/Path";
import TextBox from "../Component/TextField";

const Email = (props) => {
  //----------------------------context start--------------------
  const { user, setUser, setLoading } = useContext(AppContext);
  //------------end--------------------------
  const history = useHistory();
  const [showOTPBlockEmail, setShowOTPBlockEmail] = useState(false);
  const [showpencil, setShowpencil] = useState(false);
  const [showresendotp, setshowresendotp] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [otp, setOtp] = useState("");
  const [apiMessage, setApiMessage] = useState("");
  const [showGoogleOAuth, setShowGoogleOAuth] = useState(true);
  const [formData, setFormData] = useState({})
  const [restartTimer, setRestartTimer] = useState(0);
  const emailRef = useRef();
  const otpRef = useRef();

  //------------for hide and visible---------------------
  const [visibleContinue, setVisibleContinue] = useState(true);
  const [visibleSubmit, setVisibleSubmit] = useState(false);


  //------------------end----------------------------------

  useEffect(() => {

    //   setLoading(true);
    //   if(!user || !user.mobile)
    //   {
    //     history.push("/");
    //   }
    if (user && user.email && !user.is_editable) {
      formData.email = user.email
      setFormData(oldData => ({ ...oldData, email: user.email }));
      requestOTP(OTP_TYPES.EMAIL)
      setShowpencil(false);
      setShowGoogleOAuth(false)
    }
    emailRef.current.focus()
  }, [])



  /**
   * email validation
   * @param {} e
   */
  const validateEmail = (email) => {
    if (!formData.email) {
      setApiMessage(strings.mandatory)
    } else {
      let e = formData.email;
      if (validator.isEmail(e)) {
        setApiMessage("")
        return true;
      } else {
        setApiMessage(strings.validemail);
        return false;
      }
    }
  };
  // const handleChange = (event) => {
  //   setInput({ [event.target.name]: event.target.value });
  // };
  /**
   * pen icone click events
   * @param {*} event
   */
  const handlepenmcil = (event) => {
    event.preventDefault();
    if (validator.isEmail(formData.email)) {
      setShowOTPBlockEmail(false);
      setDisabled(false);
      setShowpencil(false);
      setshowresendotp(false);
      setApiMessage('');
      setVisibleSubmit(false);
      setVisibleContinue(true);
      setShowGoogleOAuth(true);
    }
  };


  /**
   * conter function
   */
  const counterFinished = () => {
    setshowresendotp(true);
    //  console.log("Counter Finished");
  };
  // function onFinish() {
  //   setshowresendotp(true);
  // }
  /**
   * otp validataion
   * @param {} e
   */
  const onOTPchange = (e) => {
    setApiMessage("")
    setApiMessage("");
    if (e.target.value.length !== null) {
      setOtp(e.target.value);
      setApiMessage("")
    } else {
      setOtp("");
      setApiMessage(strings.mandatory)
    }
  };
  /**
   * request otp
   * @param {*} type
   */
  const requestOTP = (type) => {
    if (validateEmail(formData.email)) {
      setLoading(true);
      setApiMessage("")
      LeadService.requestOTP(type, user.mobile, !user.is_editable ? user.email : formData.email)
        .then((data) => {
          if (data.success) {
            setshowresendotp(false);
            setShowOTPBlockEmail(true);
            setShowpencil(user.is_editable);
            setVisibleSubmit(true);
            setVisibleContinue(false);
            // setDisabled(!disabled);
            setDisabled(true);
            setShowGoogleOAuth(false);
            otpRef.current.focus()
            setRestartTimer(old => old + 1)
          }
          else {
            setApiMessage(data.error)
          }
        })
        .catch((error) => { console.error(error) })
        .finally(() => setLoading(false))
    }
  }
  /**
   * button click for otp request
   * @param {*} event
   */
  const handleSubmit = (event) => {
    event.preventDefault();

    requestOTP(OTP_TYPES.EMAIL)

  };

  const mandatoryValidationVerify = () => {
    let isValid = true;
    if (!otp) {
      setApiMessage(strings.mandatory)
      isValid = false;
    }
    else {
      setApiMessage("")
    }
    return isValid
  }
  /**
   * otp validation
   */
  const handleOTPSubmit = () => {
    if (mandatoryValidationVerify()) {
      setLoading(true);
      setApiMessage("")
      LeadService.validateOTP(OTP_TYPES.EMAIL, user.mobile, formData.email, otp)
        .then((data) => {
          if (data.success) {
            let email = formData.email;
            setUser({ ...user, email });
            history.replace(PATH.CREATEPASSWORD_PAGE);
          }
          else {
            setApiMessage(data.error)
          }
        })
        .catch((error) => { console.error(error) })
        .finally(() => setLoading(false))
    }
  };
  /**
   * email validation error settings
   * @param {} e
   */
  const onChange = (e) => {
    setApiMessage("");
    setFormData(oldData => ({ ...oldData, email: e.target.value }));
    setApiMessage("")
  };

  const onSuccess = (res) => {
    setUser({ ...user, email: res.profileObj.email, });
    setApiMessage("");
    console.log(res, 'google')
    let body = {
      response: {
        qc: {
          scope: res.qc ? res.qc.scope : (res.mc ? res.mc.scope : ''),
          first_issued_at: res.qc ? res.qc.first_issued_at : (res.mc ? res.mc.first_issued_at : ''),
          expires_at: res.qc ? res.qc.expires_at : (res.mc ? res.mc.expires_at : ''),
          idpId: res.qc ? res.qc.idpId : (res.mc ? res.mc.idpId : ''),
        },
        ft: res.ft ? res.ft : '',
        googleId: res.googleId,

        tokenObj: {
          first_issued_at: res.tokenObj.first_issued_at,
          expires_at: res.tokenObj.expires_at,
          idpId: res.tokenObj.idpId,
        },
        profileObj: res.profileObj,
      },
      email: res.profileObj.email,
      mobile: user.mobile,
    }
    console.log(body, 'body')
    setLoading(true);
    LeadService.validateGoogle(body)
      .then((data) => {
        setFormData(oldData => ({ ...oldData, email: res.profileObj.email}));
        if (data.success) {
          history.replace(PATH.CREATEPASSWORD_PAGE);
        }
        else {
          setApiMessage(data.error)
        }
      })
      .catch((error) => { console.error(error) })
      .finally(() => setLoading(false))
  }

  const onFailure = (res) => {
    setApiMessage("")
    console.log(res)
    let message = null;
    switch (res.error) {
      case 'idpiframe_initialization_failed': message = strings.failed_google_initialization_failed; break;
      default:
    }
    if (message) {
      setApiMessage(strings.failed_google)
    } else {
      setApiMessage("");
    }
  }

  const onSignInClick = () => {
    history.replace(PATH.SIGNIN_PAGE);
  }

  return (
    <>
      <Header />
      <div className="inner pb-4">
        <div className="container">
          <div className="row">
            <div className="col-md-50">
              <div className="images-add">
                <img src={process.env.PUBLIC_URL + "/img/email-img.gif"} alt="" />
              </div>
            </div>
            <div className="col-md-50">
              <form onSubmit={handleSubmit}>
                <div className="s-box">
                  <h1 className="text-center pb-2">{strings.email_header}</h1>
                  {
                    showGoogleOAuth && (
                      <div className="row">
                        <div className=" col-md-3 "></div>
                        <div className="google col-md-12 ">
                          <GoogleOauth onSuccess={onSuccess} onFailure={onFailure} />
                        </div>
                        <div className="col-md-3 "></div>
                      </div>
                    )
                  }
                  {
                    showGoogleOAuth && (
                      <div className="or-devide">
                        <hr />
                        <span>or</span>
                      </div>
                    )
                  }
                  <TextBox
                    reference={emailRef}
                    mainclass="inp pt-3"
                    placehold={strings.enterEmail}
                    type="email"
                    textId="email"
                    fieldValue={formData.email}
                    disabled={disabled}
                    onTextChange={onChange}
                    postfix={
                      showpencil && (
                        <EditPen
                          classes={"edit-btn1 icon-topemail"}
                          imgsrc={process.env.PUBLIC_URL + "/img/pen.png"}
                          imgclass="peneimgsrcemail"
                          click={handlepenmcil}
                        />
                      )
                    }
                  />
                  {showOTPBlockEmail && (
                    <OTPInput
                      reference={otpRef}
                      divClasses="inp pt-3"
                      maxLength="6"
                      classes={"mb-0"}
                      textType="number"
                      onChange={onOTPchange}
                      otpPlaceholder={strings.otpenter}
                      textId={otp}
                      postfix={
                        <div className="time-otp">
                          <Count onFinish={counterFinished} restart={restartTimer} />
                        </div>
                      }
                    />

                  )}
                  {showresendotp && (
                    <div className="pt-1">
                      <p className="mb-0">
                        {strings.cmn_otp} <a href="# " onClick={(e) => requestOTP(OTP_TYPES.EMAIL)}>{strings.resend}</a>
                      </p>
                    </div>
                  )}
                  <div id="txterror" name="txterror" className=" api-errormessage text-danger mh-25">
                    {apiMessage}
                  </div>
                  {visibleContinue && (
                    <Button
                      classes="inp "
                      label={strings.continue}
                      click={handleSubmit}
                      inputType="text"
                      classes1="buttonmystyle"
                    />
                  )}
                  {visibleSubmit && (
                    <Button
                      classes="inp "
                      label={strings.verify}
                      click={handleOTPSubmit}
                      inputType="text"
                      classes1="buttonmystyle"
                    />
                  )}

                  <div className="or-devide">
                    <hr />
                    <span>or</span>
                  </div>
                  <div className="continue-with">
                    <p className="text-center mb-0">{strings.cmncontinue}</p>
                    <a href="# " > </a>

                    <SignInButton
                      classes={"sign-b"}
                      label={strings.signin}
                      onclick={onSignInClick}
                    ></SignInButton>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Email;