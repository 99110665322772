import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../Context/AppContext";
import Count from "../Component/CountComponent/Count";
import { strings } from "../Common/String";
import Button from "../Component/Button";
import EditPen from "../Component/EditPen";
import validator from "validator";
import GoogleOauth from "../googlesignin/GoogleOauth";
import { LeadService, } from "../Services/LeadService";
import Header from "../Header";
import Footer from "../footer";
import SignInButton from "../Component/SignInButton";
import { PATH } from "../Common/Path";
import TextBox from "../Component/TextField";

const NRI = (props) => {
    //----------------------------context start--------------------
    const { user, setUser, setLoading } = useContext(AppContext);
    //------------end--------------------------
    const history = useHistory();
    const emailRef = useRef();
    const otpRef = useRef();
    const [apiMessage, setApiMessage] = useState("");

    //------------for hide and visible---------------------
    const [visibleContinue, setVisibleContinue] = useState(true);
    const [visibleSubmit, setVisibleSubmit] = useState(false);


    //------------------end----------------------------------

    useEffect(() => {

        //   setLoading(true);

    }, [])


    return (
        <>
            <Header />
            <div className="inner">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                            <section id="section2">
                            <div className="col-md-12 text-center">
                                    <h5 className="pb-2">section1</h5>
                                </div>
                                
                            
                            </section>
                            <div className="col-md-12" />
                            <section id="section2">
                            <div className="col-md-12 text-center">
                                    <h5 className="pb-2">section2</h5>
                                </div>
                                
                            
                            </section>
                            <div className="col-md-12" />
                            <section id="section3">
                            <div className="col-md-12 text-center">
                                    <h5 className="pb-2">section3</h5>
                                </div>
                                
                              
                            </section>
                            <div className="col-md-12" />
                            <section id="section4">
                            <div className="col-md-12 text-center">
                                    <h5 className="pb-2">section4</h5>
                                </div>
                               
                            </section>
                            <div className="col-md-12" />
                            <section id="section5">
                            <div className="col-md-12 text-center">
                                    <h5 className="pb-2">section5</h5>
                                </div>
                                
                              
                            </section>
                            <div className="col-md-12" />
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
export default NRI;