export const PATH = {
  MOBILE_PAGE: "/",
  EMAIL_PAGE: "/email",
  CREATEPASSWORD_PAGE: "/create/password",
  SIGNIN_PAGE: "/signin",
  RELATIONSHIP_PAGE: "/relationship",
  VERIFICATIONDIGILOCK_PAGE: "/verification/1",
  VERIFICATIONPAN_PAGE: "/verification/2",
  PERSONALBASIC_PAGE: "/personal/details/basic",
  PERSONALADDRESS_PAGE: "/personal/details/address",
  PERSONALNOMINEE_PAGE: "/personal/details/nominee",
  PERSONALOTHER_PAGE: "/personal/details/other",
  TERMSANSCONDITION_PAGE: "/personal/details/tnc",
  BANKACCOUNT_PAGE: "/bank/account",
  BANKDETAILS_PAGE: "/bank/details",
  SEGMENTS_PAGE: "/segments",
  UPLOADPROOFS_PAGE: "/upload/proof",
  INPERSON_PAGE: "/ipv",
  ESIGN_PAGE: "/esign",
  PAYMENT_PAGE: "/payment",
  THANKYOU_PAGE: "/thankyou",
  LEADSTATUS_PAGE: "/lead/status",
  NRI_PAGE: "/nri",

  // RE-KYC
  REKYC_SIGNIN_PAGE: "/rekyc/signin",
  REKYC_THANKYOU_PAGE: "/rekyc/thankyou",
  REKYC_ESIGN_PAGE: "/rekyc/esign",
  REKYC_UPLOAD_PAGE: "/rekyc/upload/proof",
  REKYC_IPV_PAGE: "/rekyc/ipv",
  REKYC_STATUS_PAGE: "/rekyc/lead/status",
  REKYC_SUMMARY_PAGE: "/rekyc/summary",

  // Closure
  CLOSURE_SIGNIN_PAGE: "/closure/signin",
  CLOSURE_SUMMARY_PAGE: "/closure/summary",
  CLOSURE_UPLOAD_PAGE: "/closure/upload/proof",
  CLOSURE_ESIGN_PAGE: "/closure/esign",
  CLOSURE_THANKYOU_PAGE: "/closure/thankyou",
  CLOSURE_STATUS_PAGE: "/closure/status",
};
