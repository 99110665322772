import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../Context/AppContext";
import { strings } from "../Common/String";
import Button from "../Component/Button";
import { LeadService } from "../Services/LeadService";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../footer";
import Header2 from "../HeaderStatus";
import TextBox from "../Component/TextField";
import DropDown from "../Component/DropDown";
import RadoiButtonGroup from "../Component/RadioButtonGroup";
import RadioGroupOptions from "../Common/RadioGroupOptions";
import { MasterService } from "../Services/MasterService";
import { getCurrentStep, getPath } from "../Common/utils";
import { throttle } from "lodash";
import DynamicDropdown from "../Component/DynamicDropdown";

const PersonalDetailsBasic = (props) => {
  const {
    steps,
    setSteps,
    setLoading,
    currentStep,
    setCurrentStep,
    hasChanges,
    setHasChanges,
  } = useContext(AppContext);
  const [errors, setErrors] = useState(false);
  const history = useHistory();
  const [apiMessage, setApiMessage] = useState(" ");
  const [formData, setFormData] = useState({});
  const [ddIncome, setDDIncome] = useState([]);
  const [ddEducation, setDDEducation] = useState([]);
  const [ddOccupation, setDDOccupation] = useState([]);
  const [disabledFields, setDisabledFields] = useState([]);
  const [options, setOptions] = useState([]);
  const currentRef = useRef();

  useEffect(() => {
    setLoading(true);
    //   if(!user || !user.mobile|| !user.email || !user.name)
    //   {
    //     history.push("/");
    //   }

    //setName(user.name);
    setHasChanges(false);
    setLoading(true);
    Promise.all([
      MasterService.getDropdown("annual_income"),
      MasterService.getDropdown("education"),
      MasterService.getDropdown("occupation"),
      LeadService.fillPersonalDetails("basic"),
    ])
      .then((datas) => {
        if (datas[0] && datas[0].annual_income) {
          setDDIncome(datas[0].annual_income);
          const def = datas[0].annual_income.find((d) => d.is_default === true);
          setFormData((formData) => ({
            ...formData,
            incOption: def ? def.value : datas[0].annual_income[0].value,
          }));
        }
        if (datas[1] && datas[1].education) {
          setDDEducation(datas[1].education);
          const def = datas[1].education.find((d) => d.is_default === true);
          setFormData((formData) => ({
            ...formData,
            eduOption: def ? def.value : datas[1].education[0].value,
          }));
        }
        if (datas[2] && datas[2].occupation) {
          setDDOccupation(datas[2].occupation);
          const def = datas[2].occupation.find((d) => d.is_default === true);
          setFormData((formData) => ({
            ...formData,
            occOption: def ? def.value : datas[2].occupation[0].value,
          }));
        }
        if (datas[3]) {
          setCurrentStep(getCurrentStep(datas[3].current_step));
          let data = datas[3];
          if (data.success) {
            let formData = {};
            formData.fname = data.first_name;
            formData.mname = data.middle_name;
            formData.lname = data.last_name;
            formData.gender =
              data.gender === "M"
                ? RadioGroupOptions.GENDER[0]
                : data.gender === "F"
                ? RadioGroupOptions.GENDER[1]
                : data.gender === "O"
                ? RadioGroupOptions.GENDER[2]
                : "";
            formData.marital =
              data.marital_status === "S"
                ? RadioGroupOptions.MARITAL_STATUS[0]
                : data.marital_status === "M"
                ? RadioGroupOptions.MARITAL_STATUS[1]
                : data.marital_status === "O"
                ? RadioGroupOptions.MARITAL_STATUS[2]
                : "";
            formData.motherName = data.mother_name;
            formData.fatherName = data.father_name;
            formData.birthPlace = data.birth_place;
            if (data.education) {
              formData.eduOption = data.education;
            }
            if (data.occupation) {
              formData.occOption = data.occupation;
            }
            if (data.annual_income) {
              formData.incOption = data.annual_income;
            }
            setFormData((fd) => ({ ...fd, ...formData }));
            setDisabledFields(data.disabled_fields || []);
          }
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
    currentRef.current.focus();
  }, [true]);

  useEffect(() => {
    steps.forEach((step) => {
      if (step.id === currentStep) {
        step.status = 1;
      } else if (step.id < currentStep) {
        step.status = 2;
      }
    });
    setSteps(steps);
  }, [currentStep]);
  /**
   * validation for final submit
   */

  const mandatoryValidationSubmit = () => {
    let isValid = true;
    if (formData.fname) {
      formData.fname = formData.fname.trim();
    }

    if (!formData.fname) {
      setErrors((oldErrors) => ({ ...oldErrors, fname: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, fname: false }));
    }
    if (formData.lname) {
      formData.lname = formData.lname.trim();
    }

    if (!formData.lname) {
      setErrors((oldErrors) => ({ ...oldErrors, lname: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, lname: false }));
    }

    if (!formData.gender) {
      setErrors((oldErrors) => ({ ...oldErrors, gender: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, gender: false }));
    }

    if (!formData.marital) {
      setErrors((oldErrors) => ({ ...oldErrors, marital: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, marital: false }));
    }

    if (formData.motherName) {
      formData.motherName = formData.motherName.trim();
    }

    if (!formData.motherName) {
      setErrors((oldErrors) => ({ ...oldErrors, motherName: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, motherName: false }));
    }

    if (formData.fatherName) {
      formData.fatherName = formData.fatherName.trim();
    }

    if (!formData.fatherName) {
      setErrors((oldErrors) => ({ ...oldErrors, fatherName: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, fatherName: false }));
    }

    if (formData.birthPlace) {
      formData.birthPlace = formData.birthPlace.trim();
    }

    if (!formData.birthPlace) {
      setErrors((oldErrors) => ({ ...oldErrors, birthPlace: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, birthPlace: false }));
    }

    if (!formData.eduOption) {
      setErrors((oldErrors) => ({ ...oldErrors, ddl_edu: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, ddl_edu: false }));
    }

    if (!formData.occOption) {
      setErrors((oldErrors) => ({ ...oldErrors, ddl_ocu: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, ddl_ocu: false }));
    }
    if (!formData.incOption) {
      setErrors((oldErrors) => ({ ...oldErrors, ddl_inc: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, ddl_inc: false }));
    }
    return isValid;
  };

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        MasterService.getCityList(request.input).then((data) =>
          callback(data)
        );
      }, 200),
    []
  );
  const citySelect = (field, location) => {
    if (field === "birthPlace") {
      setFormData((oldData) => ({
        ...oldData,
        [field]: location.city,
      }));
      setErrors((oldErrors) => ({
        ...oldErrors,
        [field]: false,
      }));
    }
    setOptions([]);
  };

  const cityHandleChange = (event) => {
    let active = true;
    fetch({ input: event.target.value }, (results) => {
      if (active) {
        let newOptions = [];
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        if (event.target.name === "birthPlace") {
          setOptions(newOptions);
        }
      }
    });
    setFormData((oldData) => ({
      ...oldData,
      [event.target.name]: event.target.value,
    }));
    setErrors((oldErrors) => ({
      ...oldErrors,
      [event.target.name]:
        !event.target.value || event.target.value.trim().length === 0,
    }));
    setApiMessage("");
    setHasChanges(true);
  };


  /**
   * button click submitt
   */
  const aboutYouNextClick = () => {
    //console.log(formData.fname, formData.fname, '---', formData.lname, '...', formData.gender, '...', formData.marital, '...', formData.motherName, '...', formData.fatherName, '...', formData.eduOption, '...', formData.occOption, '...', formData.incOption);
    if (mandatoryValidationSubmit()) {
      setLoading(true);
      let gender = "";
      switch (formData.gender) {
        case "Male":
          gender = "M";
          break;
        case "Female":
          gender = "F";
          break;
        case "Others":
          gender = "O";
          break;
        default:
          break;
      }

      let marital = "";
      switch (formData.marital) {
        case "Single":
          marital = "S";
          break;
        case "Married":
          marital = "M";
          break;
        case "Others":
          marital = "O";
          break;
        default:
          break;
      }

      let body = {
        type: "basic",
        details: {
          first_name: formData.fname,
          middle_name: formData.mname || null, //Not Mandatory
          last_name: formData.lname,
          education: formData.eduOption,
          occupation: formData.occOption,
          annual_income: formData.incOption,
          gender: gender,
          marital_status: marital,
          mother_name: formData.motherName,
          father_name: formData.fatherName,
          birth_place: formData.birthPlace
        },
      };
      setApiMessage("");
      LeadService.submitBasicDetails(body)
        .then((data) => {
          if (data.success) {
            history.push(getPath(data.next_step));
          } else {
            setApiMessage(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    } else {
    }
  };
  /**
   * onchange function of all fields
   * @param {} event
   */
  const handleChange = (event) => {
    //event.persist();
    if (event.target.name === "fname") {
      setFormData((oldData) => ({
        ...oldData,
        [event.target.name]: event.target.value,
      }));
      setErrors((oldErrors) => ({ ...oldErrors, fname: false }));
    }
    if (event.target.name === "mname") {
      setFormData((oldData) => ({
        ...oldData,
        [event.target.name]: event.target.value,
      }));
      setErrors((oldErrors) => ({ ...oldErrors, mname: false }));
    }
    if (event.target.name === "fatherName") {
      setFormData((oldData) => ({
        ...oldData,
        [event.target.name]: event.target.value,
      }));
      setErrors((oldErrors) => ({ ...oldErrors, fatherName: false }));
    }
    if (event.target.name === "motherName") {
      setFormData((oldData) => ({
        ...oldData,
        [event.target.name]: event.target.value,
      }));
      setErrors((oldErrors) => ({ ...oldErrors, motherName: false }));
    }
    if (event.target.name === "birthPlace") {
      setFormData((oldData) => ({
        ...oldData,
        [event.target.name]: event.target.value,
      }));
      setErrors((oldErrors) => ({ ...oldErrors, birthPlace: false }));
    }
    if (event.target.name === "lname") {
      setFormData((oldData) => ({
        ...oldData,
        [event.target.name]: event.target.value,
      }));
      setErrors((oldErrors) => ({ ...oldErrors, lname: false }));
    }
    if (event.target.name === "gender") {
      setFormData((oldData) => ({ ...oldData, gender: event.target.value }));
      setErrors((oldErrors) => ({ ...oldErrors, gender: false }));
    }
    if (event.target.name === "marital") {
      setFormData((oldData) => ({ ...oldData, marital: event.target.value }));
      setErrors((oldErrors) => ({ ...oldErrors, marital: false }));
    }
    if (event.target.name === "ddl_edu") {
      setFormData((oldData) => ({ ...oldData, eduOption: event.target.value }));
      setErrors((oldErrors) => ({ ...oldErrors, ddl_edu: false }));
    }

    if (event.target.name === "ddl_ocu") {
      setFormData((oldData) => ({ ...oldData, occOption: event.target.value }));
      setErrors((oldErrors) => ({ ...oldErrors, ddl_ocu: false }));
    }
    if (event.target.name === "ddl_inc") {
      setFormData((oldData) => ({ ...oldData, incOption: event.target.value }));
      setErrors((oldErrors) => ({ ...oldErrors, ddl_inc: false }));
    }
    setApiMessage("");
    setHasChanges(true);
    // mandatoryValidationSubmit();
  };

  return (
    <div className="inner-section">
      <div className="details">
        <div className="container-fluid">
          <div className="row">
            <Header2 />
            <div className="col-md-9 pl-5 pt-55 pb-5 pr-5">
              <div className="row">
                <div className="col-md-12">
                  <h5 className="pb-2">{strings.about_subhead}</h5>
                </div>
                <TextBox
                  reference={currentRef}
                  tabIndex="1"
                  mainclass="col-md-6 pb-1"
                  label={strings.about_first_name}
                  placehold={strings.about_first_nameholder}
                  classes="inuput-box"
                  inputclass="mb-0"
                  type="text"
                  textId="fname"
                  disabled={disabledFields.includes("first_name") || false}
                  fieldValue={formData.fname || ""}
                  onTextChange={handleChange}
                  error={errors.fname}
                  errorclass="text-danger errorfont mh-18"
                  errorMessage={strings.mandatory}
                  prefix={
                    <div className="info-btn ">
                      <a href="# ">
                        <i className="fa fa-info-circle " aria-hidden="true" />
                      </a>
                      <div className=" tooltiptext-fname">
                        {strings.tooltiplabel}
                      </div>
                    </div>
                  }
                />
                <TextBox
                  tabIndex="2"
                  mainclass="col-md-6 pb-1"
                  label={strings.about_middle_name}
                  placehold={strings.about_middle_nameholder}
                  classes="inuput-box"
                  inputclass="mb-0"
                  type="text"
                  textId="mname"
                  disabled={disabledFields.includes("middle_name") || false}
                  fieldValue={formData.mname || ""}
                  onTextChange={handleChange}
                  error={errors.mname}
                  errorclass="text-danger errorfont mh-18"
                  errorMessage=""
                />
                <TextBox
                  tabIndex="3"
                  mainclass="col-md-6 pb-1"
                  label={strings.about_last_name}
                  placehold={strings.about_last_nameholder}
                  classes="inuput-box"
                  inputclass="mb-0"
                  type="text"
                  textId="lname"
                  disabled={disabledFields.includes("last_name") || false}
                  fieldValue={formData.lname || ""}
                  onTextChange={handleChange}
                  error={errors.lname}
                  errorclass="text-danger errorfont mh-18"
                  errorMessage={strings.mandatory}
                />
                <DropDown
                  tabIndex="4"
                  mainclass="col-md-6 pb-1"
                  label={strings.about_education_label}
                  classes="inuput-box"
                  type="form-control"
                  drId="edu"
                  selectid="ddl_edu"
                  disabled={disabledFields.includes("education") || false}
                  fieldValue={formData.eduOption}
                  options={ddEducation}
                  error={errors.edu}
                  errorMessage={strings.mandatory}
                  errorclass="text-danger errorfont mh-18"
                  onselectchange={handleChange}
                  initialValue={formData.eduOption}
                />
                <DropDown
                  tabIndex="5"
                  mainclass="col-md-6 pb-1"
                  label={strings.about_occupation_label}
                  classes="inuput-box "
                  type="form-control "
                  drId="ocu"
                  selectid="ddl_ocu"
                  disabled={disabledFields.includes("occupation") || false}
                  fieldValue={formData.occOption}
                  options={ddOccupation}
                  error={errors.ocu}
                  errorMessage={strings.mandatory}
                  errorclass="text-danger errorfont mh-18"
                  onselectchange={handleChange}
                  initialValue={formData.occOption}
                />

                <DropDown
                  tabIndex="6"
                  mainclass="col-md-6 pb-1"
                  label={strings.about_income_label}
                  classes="inuput-box"
                  type="form-control"
                  drId="inc"
                  selectid="ddl_inc"
                  disabled={disabledFields.includes("annual_income") || false}
                  options={ddIncome}
                  error={errors.inc}
                  fieldValue={formData.incOption}
                  errorMessage={strings.mandatory}
                  errorclass="text-danger errorfont mh-18"
                  onselectchange={handleChange}
                  initialValue={formData.incOption}
                />

                <RadoiButtonGroup
                  tabIndex="7"
                  mainclassName="col-md-6 pb-1"
                  label={strings.gender_label}
                  options={RadioGroupOptions.GENDER}
                  name="gender"
                  option="gender"
                  onOptionChange={handleChange}
                  error={errors.gender}
                  errorMessage={strings.mandatory}
                  errorclass="text-danger errorfont mh-18"
                  selected={formData.gender || undefined}
                  disabled={disabledFields.includes("gender") || false}
                />

                <RadoiButtonGroup
                  tabIndex="8"
                  mainclassName="col-md-6 pb-1"
                  label={strings.marital_status_label}
                  options={RadioGroupOptions.MARITAL_STATUS}
                  name="marital"
                  option="marital"
                  onOptionChange={handleChange}
                  disabled={disabledFields.includes("marital_status") || false}
                  error={errors.marital}
                  errorMessage={strings.mandatory}
                  errorclass="text-danger errorfont mh-18"
                  selected={formData.marital || undefined}
                />

                <TextBox
                  tabIndex="9"
                  mainclass="col-md-6 pb-1"
                  label={strings.mother_name}
                  placehold={strings.enter_mother_name}
                  classes="inuput-box"
                  inputclass="mb-0"
                  type="text"
                  textId="motherName"
                  disabled={disabledFields.includes("mother_name") || false}
                  fieldValue={formData.motherName || ""}
                  onTextChange={handleChange}
                  error={errors.motherName}
                  errorMessage={strings.mandatory}
                  errorclass="text-danger errorfont mh-18"
                />

                <TextBox
                  tabIndex="10"
                  mainclass="col-md-6 pb-1"
                  label={strings.father_name}
                  placehold={strings.enter_father_name}
                  classes="inuput-box"
                  inputclass="mb-0"
                  type="text"
                  textId="fatherName"
                  disabled={disabledFields.includes("father_name") || false}
                  fieldValue={formData.fatherName || ""}
                  onTextChange={handleChange}
                  error={errors.fatherName}
                  errorMessage={strings.mandatory}
                  errorclass="text-danger errorfont mh-18"
                />

                <DynamicDropdown
                  tabIndex="12"
                  mainclass="col-md-6"
                  headerlabel={strings.birth_place}
                  placehold={strings.enter_birth_place}
                  classes="inuput-box"
                  label="city"
                  inputclass="mb-0"
                  textMaxLength="20"
                  textId="birthPlace"
                  disabled={disabledFields.includes("birthPlace") || false}
                  options={options || []}
                  fieldValue={formData.birthPlace || ""}
                  onTextChange={cityHandleChange}
                  optionClick={(location) =>
                    citySelect("birthPlace", location)
                  }
                  error={errors.birthPlace}
                  errorclass="text-danger errorfont mh-18"
                  errorMessage={strings.mandatory}
                />
                <div className="col-md-12">
                  <div className="row mh-42">
                    <div className="col-md-12 pt-2">
                      {" "}
                      <div className=" inp api-errormessage text-danger text-center">
                        <label id="txterror" name="txterror">
                          {apiMessage}
                        </label>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 pt-4">
                      {/* <div className="inp-back">
                          <Button
                              classes="inp-back"
                              label={strings.previous}
                              click={prevClick}
                          />
                      </div> */}
                    </div>
                    <div className="col-6 pt-3 text-right">
                      <Button
                        classes="inp"
                        classes1="buttonmystyle"
                        label={strings.nextbtn}
                        click={aboutYouNextClick}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default PersonalDetailsBasic;
