import React, { useContext, useEffect, useState } from "react";
import { strings } from "../Common/String";
import { AppContext } from "../Context/AppContext";
import Footer from "../footer";
import { LeadService } from "../Services/LeadService";

const LeadStatus = (props) => {
  const { setLoading } = useContext(AppContext);
  const [apiMessage, setApiMessage] = useState("");
  const [name, setName] = useState(null);
  // const [minTime, setMintime] = useState(null);
  // const [maxTime, setMaxTime] = useState(null);
  const [appleURL, setAppleURL] = useState(null);
  const [googleURL, setGoogleURL] = useState(null);
  // const [mobile, setMobile] = useState(null);
  const [steps, setSteps] = useState(null);

  useEffect(() => {
    setLoading(true);
    setApiMessage("");
    LeadService.getEndLeadStatus()
      .then((data) => {
        if (data.success) {
          setName(data.name);
          // setMintime(data.min_time);
          // setMaxTime(data.max_time);
          setAppleURL(data.apple_url);
          setGoogleURL(data.google_url);
          // setMobile(data.support_mobile);
          setSteps(data.lead_status || []);
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="inner-section">
      <div className="details">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 head-section   pb-3">
              <div className="log-main  pt-4 pl-4">
                <img
                  src={process.env.PUBLIC_URL + "/img/acumen-logo.png"}
                  alt=" "
                />
              </div>
            </div>
            <div className="col-md-12 pl-3 pt-5 pb-5 pr-3 pl-md-5 pr-md-5">
              <div className="row">
                <div className="col-md-3 text-center" />
                <div className="col-md-6 bg-w text-center">
                  {/* <h6 className="pb-3 thank">{strings.hi} {name}</h6>
                  <p className="pt-1 mb-0 thankp">{strings.leasstatus_def1}{minTime}-{maxTime} {strings.leasstatus_def1A}</p>
                  <p className=" pt-3 mb-0 thankp">{strings.leasstatus_def2}{maxTime}{strings.leasstatus_def2_1} <b><u>{strings.leasstatus_def2_a}{mobile}</u></b> {strings.leasstatus_def2_b}</p>
                  <p className=" pt-3 mb-0 thankp">{strings.leasstatus_def3}</p>
                  <h6 className="pt-4 head-ov"> </h6>
                  <div className="col-md-12 pt-3">
                    <div className="row">
                      <div className="col-md-2 pt-4" />
                      <div className="col-md-8 pt-3 text-center">
                        <div className="app-btn">
                          <a href={googleURL ? googleURL : " #"}><img src={process.env.PUBLIC_URL + "/img/Play.png"} alt=" " /></a>
                          <a href={appleURL ? appleURL : " #"}><img src={process.env.PUBLIC_URL + "/img/app store.png"} alt=" " /></a>
                        </div>
                      </div>
                      <div className="col-md-2 pt-3 d-none" />
                        <div className="text-center api-errormessage text-danger"><label id="txterror" className="txterror"  >{apiMessage}</label> </div>
                    </div>
                  </div> */}
                  <div className="col-md-12 pb-3">
                    <h5 className="pb-2">
                      {strings.application_status_page_heading}
                    </h5>
                  </div>
                  <div className="col-md-12">
                    <p style={{ textAlign: "justify" }}>
                      <b>
                        {strings.hi}
                        {name}
                        {strings.application_status_page_text}
                      </b>
                    </p>
                    {steps && steps.length > 0 && (
                      <div className="ibox-content" id="ibox-content">
                        <div
                          id="vertical-timeline"
                          className="vertical-container dark-timeline "
                        >
                          {steps.map((step, index) => (
                            <Row
                              key={index}
                              label={step.label}
                              status={step.status}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 pt-3">
                    <div className="pb-3">{strings.leasstatus_def3}</div>
                    <div className="row">
                      {/* <div className="col-md-2 pt-4" /> */}
                      <div className="col-md-12 pt-3 text-center">
                        <div className="app-btn">
                          <a href={googleURL ? googleURL : " #"}>
                            <img
                              src={process.env.PUBLIC_URL + "/img/Play.png"}
                              alt=" "
                            />
                          </a>
                          <a href={appleURL ? appleURL : " #"}>
                            <img
                              src={
                                process.env.PUBLIC_URL + "/img/app store.png"
                              }
                              alt=" "
                            />
                          </a>
                        </div>
                      </div>
                      {/* <div className="col-md-2 pt-3 d-none" /> */}
                      <div className="text-center api-errormessage text-danger">
                        <label id="txterror" className="txterror">
                          {apiMessage}
                        </label>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

const Row = ({ label, status }) => {
  if (status === 2) {
    return (
      <div className="vertical-timeline-block">
        <div className="vertical-timeline-icon navy-bg">
          <i className="fa fa-check" aria-hidden="true"></i>
        </div>
        <div className="vertical-timeline-content">
          <h2>{label}</h2>
        </div>
      </div>
    );
  } else if (status === 1) {
    return (
      <div className="vertical-timeline-block">
        <div className="vertical-timeline-icon navy-bg"></div>
        <div className="vertical-timeline-content">
          <h2>{label}</h2>
        </div>
      </div>
    );
  } else if (status === -1) {
    return (
      <div className="vertical-timeline-block">
        <div className="vertical-timeline-icon bg-warning">
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
        <div className="vertical-timeline-content">
          <h2>{label}</h2>
        </div>
      </div>
    );
  } else {
    return (
      <div className="vertical-timeline-block">
        <div className="vertical-timeline-icon lazur-bg"></div>
        <div className="vertical-timeline-content not-act">
          <h2>{label}</h2>
        </div>
      </div>
    );
  }
};

export default LeadStatus;
