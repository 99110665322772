import React from "react";

const LayoutWrapper = ({ children }) => {
  return (
    <div>
      <header className="navbars container-fluid">
        <div className="navbar__title navbar__item">
          {" "}
          <img
            src={process.env.PUBLIC_URL + "/img/Acumen_logo.png"}
            className="acumen-logo"
          />
        </div>
      </header>
      {children}
    </div>
  );
};

export default LayoutWrapper;
