import React, { useEffect, useState } from "react";

function TextBox(props) {
  const {
    label,
    placehold,
    classes,
    type,
    textId,
    textMaxLength,
    disabled,
    fieldValue,
    onTextChange,
    mainclass,
    inputclass,
    error,
    postfix,
    lblclass,
    errorMessage,
    errorclass,
    spanerrorclass,
    reference,
    tabIndex,
    textPattern,
    patternTitle,
    labeltitile,
    prefix,
    onBlur,
  } = props;
  const [value, setValue] = useState(fieldValue);
  const [hasError, setHasError] = useState(error);

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    setHasError(error);
  }, [error]);

  const onChange = (e) => {
    if (!textMaxLength) {
      setValue(e.target.value);
      onTextChange(e);
    } else if (e.target.value.length <= parseInt(textMaxLength)) {
      setValue(e.target.value);
      onTextChange(e);
    }
  };

  return (
    <div className={mainclass}>
      <div className={classes}>
        {label && (
          <>
            <label htmlFor={label} className={lblclass} title={labeltitile}>
              {label}
            </label>
            {prefix}
            <div className={spanerrorclass}></div>
          </>
        )}
        <input
          className={inputclass}
          disabled={disabled}
          type={type}
          placeholder={placehold}
          //id={textId}
          name={textId}
          value={isNaN(value) ? value || "" : value >= 0 ? value : ""}
          onChange={onChange}
          autoComplete="off"
          ref={reference}
          tabIndex={tabIndex}
          onBlur={onBlur}
          pattern={textPattern}
          title={patternTitle}
        />
      </div>
      <div className={errorclass}>{hasError && errorMessage}</div>
      {postfix}
    </div>
  );
}

export default TextBox;
