import React from "react";

function LoaderSpin() {  
  return (
    <div className="ajax-loader">
    <img src={process.env.PUBLIC_URL+"/img/123.gif"} alt=""/>
  </div>

  );
}

export default LoaderSpin;
