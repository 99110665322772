import React, { useEffect, useState } from "react";
/**
 * Field for Textbox
 * @param {*} props
 * @returns
 */
function TextInput(props) {
  const {
    fieldName,
    fieldValue,
    textChange,
    holder,
    classes,
    textId,
    textClasses,
    classes2,
    postfix,
    enabDisab,
    textMaxLength,
    textType,
    required,
    reference,
  } = props;

  const [value, setValue] = useState(fieldValue);

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  const onChange = (e) => {
    if (!textMaxLength) {
      setValue(e.target.value);
      textChange(e);
    } else if (e.target.value.length <= parseInt(textMaxLength)) {
      setValue(e.target.value);
      textChange(e);
    }
  };

  return (
    <div className={classes}>
      <input
        ref={reference}
        type={textType}
        name={fieldName}
        value={value || ""}
        disabled={enabDisab}
        className={textClasses}
        maxLength={textMaxLength}
        onChange={onChange}
        required={required}
        placeholder={holder}
        id={textId}
      />
      <div className={classes2}>+91</div>
      {postfix}
    </div>
  );
}

export default TextInput;
