import React, { useEffect, useState } from "react";

function DropDown(props) {
  const {
    label,
    classes,
    type,
    drId,
    options,
    mainclass,
    error,
    errorMessage,
    errorclass,
    selectid,
    onselectchange,
    fieldValue,
    placeHolder,
    disabled,
    tabIndex,
    reference,
  } = props;

  const [hasError, setHasError] = useState(error);

  useEffect(() => {
    setHasError(error);
  }, [error]);

  return (
    <div className={mainclass}>
      <div className={classes} id={drId}>
        <label>{label}</label>
        <select
          ref={reference}
          tabIndex={tabIndex}
          className={type}
          onChange={onselectchange}
          id={selectid}
          name={selectid}
          //defaultValue={fieldValue !== null ? fieldValue : undefined}
          value={fieldValue !== null ? fieldValue : undefined}
          placeholder={placeHolder}
          disabled={disabled}
        >
          {options.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
      <div className={errorclass}>{hasError && errorMessage}</div>
    </div>
  );
}

export default DropDown;
