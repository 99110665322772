import React, { useContext, useEffect, useState, useRef } from "react";
import { AppContext } from "../Context/AppContext";
import { useHistory } from "react-router";
import { strings } from "../Common/String";
import Button from "../Component/Button";
import Footer from "../footer";
import Header2 from "../HeaderStatus";
import { PATH } from "../Common/Path";
import { LeadService } from "../Services/LeadService";
import { getCurrentStep } from "../Common/utils";
import useScript from "../useScript";
import TextBox from "../Component/TextField";
import RadoiButtonGroup from "../Component/RadioButtonGroup";
import RadioGroupOptions from "../Common/RadioGroupOptions";
import PincodeBoxV2 from "../Component/PincodeBoxV2";
import { MasterService } from "../Services/MasterService";
import { getPath } from "../Common/utils";
import { throttle } from "lodash";

const BankAccountSelection = (props) => {
    useScript("https://app.digio.in/sdk/v9/digio.js");
    let digio = null;
    const { steps, setSteps, setLoading, currentStep, setCurrentStep, setHasChanges } = useContext(AppContext);

    const [showPD, setShowPD] = useState(false);
    const [showRPD, setShowRPD] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showPrevious, setShowPrevious] = useState(false);
    const [showNext, setShowNext] = useState(false);
    const [formData, setFormData] = useState({});

    const [errors, setErrors] = useState(false);
    const [apiMessage, setApiMessage] = useState("");
    const [options, setOptions] = React.useState([]);
    const [visibleBankMainAddress, setVisibleBankMainAddress] = useState(false);
    const [visibleBankName, setVisibleBankName] = useState(false);
    const [visibleMICR, setVisibleMICR] = useState(false);

    const [accNameDisabled, setAccNameDisabled] = useState(false);
    const [accTypeDisabled, setAccTypeDisabled] = useState(false);
    const [accNoDisabled, setAccNoDisabled] = useState(false);
    const [ifscDisabled, setIfscDisabled] = useState(false);
    const [showDetails, setShowDetails] = useState(true);



    const history = useHistory();
    const currentRef = useRef();

    useEffect(() => {
        setHasChanges(false);
        setLoading(true);
        LeadService.getBankDetails()
            .then((data) => {
                setCurrentStep(getCurrentStep(data.current_step));
                if (data.success) {
                    if (data.show_fields.options.penny_drop === true) {
                        setShowPD(true)
                        setShowDetails(false)
                    }
                    if (data.show_fields.options.reverse_penny_drop === true) {
                        setShowRPD(true)
                        setShowDetails(false)
                    }
                    if (data.show_fields.buttons.previous === true) {
                        setShowPrevious(true)
                    }
                    if (data.show_fields.buttons.next === true) {
                        setShowNext(true)
                    }
                    if (data.show_fields.buttons.edit === true) {
                        setShowEdit(true)
                    }
                    let formData = {};
                    formData.rdAccTye =
                        data.bank_account_type === "Savings"
                            ? RadioGroupOptions.ACC_TYPE[0]
                            : data.bank_account_type === "Current"
                                ? RadioGroupOptions.ACC_TYPE[1]
                                : data.bank_account_type === "Others"
                                    ? RadioGroupOptions.ACC_TYPE[2]
                                    : "";
                    formData.txtAccHolder = data.account_holder_name;
                    formData.txtAccNo = data.bank_account_no;
                    formData.txtBank = data.bank_name;
                    formData.ac_holdername = data.account_holder_name;
                    formData.txtIFC_code = data.bank_ifsc;
                    formData.txtMICE = data.bank_micr;
                    formData.txtBuilding = data.bank_addr1;
                    formData.txtLocality = data.bank_addr2;
                    formData.txtStreet = data.bank_addr3;
                    formData.permanent_pincode = data.bank_pincode;
                    formData.permanent_state = data.bank_state;
                    formData.permanent_city = data.bank_city;
                    formData.bank_pincode_fetched = data.bank_pincode_fetched;
                    if (data.reverse_penny_drop === 1) {
                        if (data.show_fields.fields.account_holder_name.edit === false) {
                            setAccNameDisabled(true);
                        } else {
                            setAccNameDisabled(false);
                        }
                        if (data.show_fields.fields.bank_account_type.edit === false) {
                            setAccTypeDisabled(true);
                        } else {
                            setAccTypeDisabled(false);
                        }
                        if (data.show_fields.fields.bank_account_no.edit === false) {
                            setAccNoDisabled(true);
                        } else {
                            setAccNoDisabled(false);
                        }
                        if (data.show_fields.fields.bank_ifsc.edit === false) {
                            setIfscDisabled(true);
                        } else {
                            setIfscDisabled(false);
                        }
                        setShowEdit(true);
                    } else if (data.reverse_penny_drop === 0) {
                        setAccNameDisabled(false);
                        setAccTypeDisabled(false);
                        setAccNoDisabled(false);
                        setIfscDisabled(false);
                        setShowEdit(true);
                    } else {
                        if (data.penny_drop === 1 && data.bank_fetched === 1 && data.bank_pincode_fetched === 0) {
                            history.push(PATH.BANKDETAILS_PAGE)
                        }
                        setShowEdit(false);
                    }

                    if (data.micr_fetched === 1) {
                        setVisibleMICR(false);
                        formData.txtMICE = data.bank_micr;
                        setShowEdit(true);
                    } else if (data.micr_fetched === 0) {
                        setVisibleMICR(true);
                        setShowEdit(true);
                        if (data.penny_drop === 1 && data.micr_fetched === 0) {
                            history.push(PATH.BANKDETAILS_PAGE)
                        }
                    } else {
                        setVisibleMICR(false);
                        setShowEdit(false);
                    }

                    if (data.bank_fetched === 1) {
                        setVisibleBankMainAddress(false);
                        setVisibleBankName(false);
                        formData.txtBank = data.bank_name;
                        formData.txtBuilding = data.bank_addr1;
                        formData.txtLocality = data.bank_addr2;
                        formData.permanent_pincode = data.bank_pincode;
                        formData.permanent_state = data.bank_state;
                        formData.permanent_city = data.bank_city;
                        formData.txtStreet = data.bank_addr3;
                        setShowEdit(true);
                    } else if (data.bank_fetched === 0) {
                        setVisibleBankMainAddress(true);
                        setVisibleBankName(true);
                        setShowEdit(true);
                    } else {
                        setVisibleBankMainAddress(false);
                        setVisibleBankName(false);
                        setShowEdit(false);
                    }

                    setFormData((old) => ({ ...old, ...formData }));
                }
                else {
                    setShowEdit(false);
                    formData.rdAccTye = "";
                    formData.txtAccHolder = "";
                    formData.txtAccNo = "";
                    formData.txtBank = "";
                    formData.txtBuilding = "";
                    formData.txtIFC_code = "";
                    formData.txtLocality = "";
                    formData.txtMICE = "";
                    formData.permanent_pincode = "";
                    formData.permanent_state = "";
                    formData.permanent_city = "";
                    formData.txtStreet = "";
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoading(false));
    }, []);

    useEffect(() => {
        steps.forEach((step) => {
            if (step.id === currentStep) {
                step.status = 1;
            } else if (step.id < currentStep) {
                step.status = 2;
            }
        });
        setSteps(steps);
    }, [currentStep]);

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                MasterService.getPincodeList(request.input).then((data) =>
                    callback(data)
                );
            }, 200),
        []
    );
    const pincodeSelect = (field, location) => {
        if (field === "permanent_pincode") {
            setFormData((oldData) => ({
                ...oldData,
                [field]: location.pincode,
                permanent_city: location.city,
                permanent_state: location.state,
            }));
        }
        setOptions([]);
    };

    const previousClick = () => {
        history.push(PATH.TERMSANSCONDITION_PAGE);
    };

    const VerifyAccount = () => {
        history.push(PATH.BANKDETAILS_PAGE);
    }

    const handleEditClick = () => {
        setShowRPD(true);
        setShowPD(true);
        setShowDetails(false);
        setShowNext(false);
        setShowEdit(false)
    }

    const mandatoryValidationSubmit = () => {
        let isValid = true;
        if (formData.txtAccHolder) {
            formData.txtAccHolder = formData.txtAccHolder.trim();
        }
        if (!formData.txtAccHolder) {
            setErrors((oldErrors) => ({ ...oldErrors, txtAccHolder: true }));
            isValid = false;
        } else {
            setErrors((oldErrors) => ({ ...oldErrors, txtAccHolder: false }));
        }
        if (formData.txtBank) {
            formData.txtBank = formData.txtBank.trim();
        }
        if (!formData.txtBank) {
            setErrors((oldErrors) => ({ ...oldErrors, txtBank: true }));
            isValid = false;
        } else {
            setErrors((oldErrors) => ({ ...oldErrors, txtBank: false }));
        }
        if (formData.txtBuilding) {
            formData.txtBuilding = formData.txtBuilding.trim();
        }
        if (!formData.txtBuilding) {
            setErrors((oldErrors) => ({ ...oldErrors, txtBuilding: true }));
            isValid = false;
        } else {
            setErrors((oldErrors) => ({ ...oldErrors, txtBuilding: false }));
        }
        if (formData.permanent_city) {
            formData.permanent_city = formData.permanent_city.trim();
        }
        if (!formData.permanent_city) {
            setErrors((oldErrors) => ({ ...oldErrors, permanent_city: true }));
            isValid = false;
        } else {
            setErrors((oldErrors) => ({ ...oldErrors, permanent_city: false }));
        }
        if (formData.permanent_state) {
            formData.permanent_state = formData.permanent_state.trim();
        }
        if (!formData.permanent_state) {
            setErrors((oldErrors) => ({ ...oldErrors, permanent_state: true }));
            isValid = false;
        } else {
            setErrors((oldErrors) => ({ ...oldErrors, permanent_state: false }));
        }
        if (formData.txtIFC_code) {
            formData.txtIFC_code = formData.txtIFC_code.trim();
        }
        if (!formData.txtIFC_code) {
            setErrors((oldErrors) => ({ ...oldErrors, txtIFC_code: true }));
            isValid = false;
        } else {
            setErrors((oldErrors) => ({ ...oldErrors, txtIFC_code: false }));
        }
        if (formData.txtLocality) {
            formData.txtLocality = formData.txtLocality.trim();
        }
        if (!formData.txtLocality) {
            setErrors((oldErrors) => ({ ...oldErrors, txtLocality: true }));
            isValid = false;
        } else {
            setErrors((oldErrors) => ({ ...oldErrors, txtLocality: false }));
        }
        if (formData.txtMICE) {
            formData.txtMICE = formData.txtMICE.trim();
        }
        if (!formData.txtMICE) {
            setErrors((oldErrors) => ({ ...oldErrors, txtMICE: true }));
            isValid = false;
        } else {
            setErrors((oldErrors) => ({ ...oldErrors, txtMICE: false }));
        }
        if (!formData.permanent_pincode) {
            setVisibleBankMainAddress(true);
            setVisibleBankName(true);
            isValid = false;
        }
        else {
            setErrors(oldErrors => ({ ...oldErrors, permanent_pincode: false }))
        }
        return isValid;
    };

    const nextClick = () => {
        if (mandatoryValidationSubmit()) {
            setLoading(true);
            let body = {
                details: {
                    bank_account_type: formData.rdAccTye,
                    bank_account_no: formData.txtAccNo,
                    bank_ifsc: formData.txtIFC_code,
                    account_holder_name: formData.txtAccHolder,
                    bank_micr: formData.txtMICE,
                    bank_name: formData.txtBank,
                    bank_addr1: formData.txtBuilding,
                    bank_addr2: formData.txtLocality,
                    bank_addr3: formData.txtStreet,
                    bank_city: formData.permanent_city,
                    bank_state: formData.permanent_state,
                    bank_country: "India",
                    bank_pincode: formData.permanent_pincode,
                },
            };
            setLoading(true);
            setApiMessage("");
            LeadService.submit_about_bank_details(body)
                .then((data) => {
                    if (data.success) {
                        history.push(getPath(data.next_step));
                    } else {
                        setApiMessage(data.error);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => setLoading(false));
        }
    };

    const pinHandleChange = (event) => {
        let active = true;
        if (event.target.value) {
            fetch({ input: event.target.value }, (results) => {
                if (active) {
                    let newOptions = [];
                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }
                    setOptions(newOptions);
                }
            });
            setFormData((oldData) => ({
                ...oldData,
                [event.target.name]: event.target.value,
            }));
            setHasChanges(true);
        }
    };

    const updateDetails = (response) => {
        setLoading(true);
        let body = {
            status: "success",
            request_id: response.digio_doc_id,
        };
        LeadService.postReversePennyDrop(body)
            .then((data) => {
                if (data.success) {
                    setShowPD(false)
                    setShowRPD(false)
                    setShowNext(true)
                    setShowDetails(true)
                    history.push(PATH.BANKDETAILS_PAGE);
                    history.push(PATH.BANKACCOUNT_PAGE);
                } else {
                    setApiMessage(data.error);
                }
            })
            .catch((error) => console.error(error))
            .finally(() => setLoading(false));
    };

    const handleChange = (event) => {
        setApiMessage("");
        if (
            event.target.name === "rdAccTye" ||
            event.target.name === "txtAccHolder" ||
            event.target.name === "txtBank" ||
            event.target.name === "txtBuilding" ||
            event.target.name === "permanent_city" ||
            event.target.name === "txtLocality" ||
            event.target.name === "txtMICE" ||
            event.target.name === "permanent_pincode" ||
            event.target.name === "permanent_state" ||
            event.target.name === "txtStreet"
        ) {
            if (event.target.value) {
                setFormData((oldData) => ({
                    ...oldData,
                    [event.target.name]: event.target.value,
                }));
            }
        }

        if (event.target.name === "txtAccNo") {
            setFormData((oldData) => ({
                ...oldData,
                [event.target.name]: event.target.value,
            }));
            setErrors((oldErrors) => ({ ...oldErrors, txtAccNo: false }));
        }
        if (event.target.name === "txtIFC_code") {
            setFormData((oldData) => ({
                ...oldData,
                [event.target.name]: event.target.value,
            }));
            setErrors((oldErrors) => ({ ...oldErrors, txtIFC_code: false }));
        }

        if (event.target.name === "rdAccTye") {
            setErrors((oldErrors) => ({ ...oldErrors, rdAccTye: false }));
        }
        if (event.target.name === "txtAccHolder") {
            setErrors((oldErrors) => ({ ...oldErrors, txtAccHolder: false }));
        }
        if (event.target.name === "txtBank") {
            setErrors((oldErrors) => ({ ...oldErrors, txtBank: false }));
        }
        if (event.target.name === "txtBuilding") {
            setErrors((oldErrors) => ({ ...oldErrors, txtBuilding: false }));
        }
        if (event.target.name === "permanent_city") {
            setErrors((oldErrors) => ({ ...oldErrors, permanent_city: false }));
        }
        if (event.target.name === "txtLocality") {
            setErrors((oldErrors) => ({ ...oldErrors, txtLocality: false }));
        }
        if (event.target.name === "txtMICE") {
            setErrors((oldErrors) => ({ ...oldErrors, txtMICE: false }));
        }
        if (event.target.name === "permanent_pincode") {
            setErrors((oldErrors) => ({ ...oldErrors, permanent_pincode: false }));
        }
        if (event.target.name === "permanent_state") {
            setErrors((oldErrors) => ({ ...oldErrors, permanent_state: false }));
        }
        if (event.target.name === "txtStreet") {
            setErrors((oldErrors) => ({ ...oldErrors, txtStreet: false }));
        }
        setHasChanges(true);
    };

    const ReversePennyDrop = () => {
        setLoading(true);
        setApiMessage("");
        LeadService.getReversePennyDrop()
            .then((data) => {
                if (data.success) {
                    if (data.use_sdk) {
                        let options = data.sdk.options;
                        options.callback = function (response) {
                            if (response.hasOwnProperty("error_code")) {
                                return console.log(strings.processerror);
                            }
                            updateDetails(response);
                        };
                        digio = new window.Digio(options);
                        digio.init();
                        digio.submit(
                            data.sdk.requestId,
                            data.sdk.identifier,
                            data.sdk.token_id
                        );
                    } else {
                        setApiMessage(data.error);
                    }
                } else {
                    setApiMessage(data.error);
                }
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => setLoading(false));
    }

    return (
        <div className="inner-section">
            <div className="details">
                <div className="container-fluid">
                    <div className="row">
                        <Header2 />
                        <div className="col-md-9 pl-3 pt-55 pb-5 pr-3 pl-md-5 pr-md-5">
                            <div className="row">
                                <div className="col-md-12 pb-4">
                                    <h5 className="pb-2">{strings.add_bank}</h5>
                                </div>
                                {showEdit && (
                                    <div className="col-md-12 bank_pencil">
                                        <label>
                                            <p className="paddingleft">
                                                <img
                                                    src={process.env.PUBLIC_URL + "/img/pen.png"}
                                                    style={{ width: "18px", marginRight: "5px" }}
                                                    alt=""
                                                />
                                                <a href="# "
                                                    onClick={handleEditClick}
                                                >
                                                    {strings.doyowantedit}
                                                </a>
                                            </p>
                                        </label>
                                    </div>
                                )}

                                {showDetails ?
                                    <>
                                        <RadoiButtonGroup
                                            reference={currentRef}
                                            tabIndex="1"
                                            mainclass="col-md-6 pb-3 "
                                            label={strings.acctype}
                                            options={RadioGroupOptions.ACC_TYPE}
                                            type="radio"
                                            name="rdAccTye"
                                            option="rdAccTye"
                                            onOptionChange={handleChange}
                                            error={errors.rdAccTye}
                                            errorMessage={strings.mandatory}
                                            errorclass="text-danger errorfont mh-18"
                                            selected={formData.rdAccTye || undefined}
                                            disabled={formData.rdAccTye ? true : false}
                                        />

                                        <div className="col-md-6 pb-2">
                                            <TextBox
                                                tabIndex="2"
                                                label={strings.accno}
                                                placehold={strings.accno}
                                                classes="inuput-box"
                                                inputclass="paninput"
                                                type="text"
                                                textId="txtAccNo"
                                                disabled={formData.txtAccNo ? true : false}
                                                fieldValue={formData.txtAccNo || ""}
                                                onTextChange={handleChange}
                                                error={errors.txtAccNo}
                                                errorclass="text-danger errorfont mh-18"
                                                errorMessage={strings.mandatory}
                                            />
                                        </div>
                                        <div className="col-md-6 pb-2">
                                            <TextBox
                                                tabIndex="3"
                                                label={strings.ifc}
                                                placehold={strings.ifc}
                                                classes="inuput-box"
                                                type="text"
                                                textId="txtIFC_code"
                                                inputclass="paninput"
                                                disabled={formData.txtIFC_code ? true : false}
                                                fieldValue={formData.txtIFC_code || ""}
                                                onTextChange={handleChange}
                                                error={errors.txtIFC_code}
                                                errorclass="text-danger errorfont mh-18"
                                                errorMessage={strings.mandatory}
                                            />
                                        </div>
                                        <TextBox
                                            tabIndex="4"
                                            mainclass="col-md-6 pb-2"
                                            label={strings.ac_holdername}
                                            placehold={strings.ac_holdername}
                                            classes="inuput-box"
                                            type="text"
                                            textId="txtAccHolder"
                                            inputclass="paninput"
                                            fieldValue={formData.txtAccHolder || ""}
                                            onTextChange={handleChange}
                                            error={errors.txtAccHolder}
                                            errorclass="text-danger errorfont mh-18"
                                            errorMessage={strings.mandatory}
                                            disabled={formData.txtAccHolder ? true : false}
                                        />

                                        {visibleMICR && (
                                            <TextBox
                                                tabIndex="5"
                                                mainclass="col-md-6 pb-2"
                                                label={strings.micr}
                                                placehold={strings.micr}
                                                classes="inuput-box"
                                                type="text"
                                                textId="txtMICE"
                                                inputclass="paninput"
                                                disabled={false}
                                                fieldValue={formData.txtMICE || ""}
                                                onTextChange={handleChange}
                                                error={errors.txtMICE}
                                                errorclass="text-danger errorfont mh-18"
                                                errorMessage={strings.mandatory}
                                            />
                                        )}
                                        {visibleBankName && (
                                            <TextBox
                                                tabIndex="6"
                                                mainclass="col-md-6 pb-2"
                                                label={strings.bankname}
                                                placehold={strings.bankname}
                                                classes="inuput-box"
                                                type="text"
                                                textId="txtBank"
                                                inputclass="paninput"
                                                disabled={false}
                                                fieldValue={formData.txtBank || ""}
                                                onTextChange={handleChange}
                                                error={errors.txtBank}
                                                errorclass="text-danger errorfont mh-18"
                                                errorMessage={strings.mandatory}
                                            />
                                        )}
                                        {visibleBankMainAddress && (
                                            <React.Fragment>
                                                <div className="col-md-12">
                                                    <label for="">{strings.bankaddress}</label>
                                                </div>

                                                <TextBox
                                                    tabIndex="7"
                                                    mainclass="col-md-4"
                                                    label=""
                                                    placehold={strings.buildingno}
                                                    classes="inuput-box"
                                                    type="text"
                                                    textId="txtBuilding"
                                                    inputclass="paninput"
                                                    disabled={false}
                                                    fieldValue={formData.txtBuilding || ""}
                                                    onTextChange={handleChange}
                                                    error={errors.txtBuilding}
                                                    errorclass="text-danger errorfont mh-18"
                                                    errorMessage={strings.mandatory}
                                                />

                                                <TextBox
                                                    tabIndex="8"
                                                    mainclass="col-md-4"
                                                    label=""
                                                    placehold={strings.street}
                                                    classes="inuput-box"
                                                    type="text"
                                                    textId="txtStreet"
                                                    inputclass="paninput"
                                                    disabled={false}
                                                    fieldValue={formData.txtStreet || ""}
                                                    onTextChange={handleChange}
                                                    error={errors.txtStreet}
                                                    errorclass="text-danger errorfont mh-18"
                                                    errorMessage={strings.mandatory}
                                                />
                                                <TextBox
                                                    tabIndex="9"
                                                    mainclass="col-md-4"
                                                    label=""
                                                    placehold={strings.bankaddressarea}
                                                    classes="inuput-box"
                                                    type="text"
                                                    textId="txtLocality"
                                                    inputclass="paninput"
                                                    disabled={false}
                                                    fieldValue={formData.txtLocality || ""}
                                                    onTextChange={handleChange}
                                                    error={errors.txtLocality}
                                                    errorclass="text-danger errorfont mh-18"
                                                    errorMessage={strings.mandatory}
                                                />
                                                <PincodeBoxV2
                                                    tabIndex="10"
                                                    mainclass="col-md-4 pb-2"
                                                    placehold={strings.pincode}
                                                    classes="inuput-box"
                                                    inputclass="mb-0"
                                                    type="text"
                                                    textId="permanent_pincode"
                                                    textMaxLength="6"
                                                    disabled={false}
                                                    options={options || []}
                                                    fieldValue={formData.permanent_pincode || ""}
                                                    onTextChange={pinHandleChange}
                                                    optionClick={(location) =>
                                                        pincodeSelect("permanent_pincode", location)
                                                    }
                                                    error={errors.permanent_pincode}
                                                    errorclass="text-danger errorfont mh-18"
                                                    errorMessage={strings.mandatory}
                                                />

                                                <TextBox
                                                    tabIndex="11"
                                                    mainclass="col-md-4"
                                                    label=""
                                                    placehold={strings.city}
                                                    classes="inuput-box"
                                                    type="text"
                                                    textId="permanent_city"
                                                    inputclass="paninput"
                                                    disabled={false}
                                                    fieldValue={formData.permanent_city || ""}
                                                    onTextChange={handleChange}
                                                    error={errors.permanent_city}
                                                    errorclass="text-danger errorfont mh-18"
                                                    errorMessage={strings.mandatory}
                                                />

                                                <TextBox
                                                    tabIndex="12"
                                                    mainclass="col-md-4"
                                                    label=""
                                                    placehold={strings.state}
                                                    classes="inuput-box"
                                                    type="text"
                                                    textId="permanent_state"
                                                    inputclass="paninput"
                                                    disabled={false}
                                                    fieldValue={formData.permanent_state || ""}
                                                    onTextChange={handleChange}
                                                    error={errors.permanent_state}
                                                    errorclass="text-danger errorfont mh-18"
                                                    errorMessage={strings.mandatory}
                                                />
                                            </React.Fragment>
                                        )}
                                    </> : null}


                                {showRPD ?
                                    <div className="col-md-6 pt-2 pb-2">
                                        <div className="box-ad">
                                            <div className="justify-center pb-4">
                                                <img src={process.env.PUBLIC_URL + "/img/upi.jpg"} width="100px" height="100px" className="" />
                                            </div>
                                            <h5>
                                                {strings.reverse_penny_drop_head}
                                            </h5>
                                            <p>{strings.reverse_penny_drop_note}</p>
                                            <a
                                                className="e-sign"
                                                style={{ textDecoration: "none" }}
                                                onClick={ReversePennyDrop}
                                                href="# "
                                            >
                                                {strings.verify_now} &#40;{strings.recommended}&#41;
                                            </a>
                                        </div>
                                    </div>
                                    : null}
                                {showPD ?
                                    <div className="col-md-6 pt-2 pb-2">
                                        <div className="box-ad">
                                            <div className="justify-center pb-4">
                                                <img src={process.env.PUBLIC_URL + "/img/penny-drop.png"} width="100px" height="100px" className="" />
                                            </div>
                                            <h5>
                                                {strings.penny_drop_head}
                                            </h5>
                                            <p>{strings.penny_drop_note}</p>
                                            <a
                                                className="courier"
                                                style={{ textDecoration: "none" }}
                                                onClick={VerifyAccount}
                                                href="# "
                                            >
                                                {strings.verify_now}
                                            </a>
                                        </div>
                                    </div> : null}
                                {showRPD ?
                                    <div className="col-md-12 mt-3">
                                        <div className="alert alert-success" role="alert">
                                            {strings.primary_bank_note}
                                        </div>
                                    </div>
                                    : null}
                                <div className="col-md-12 pt-4">
                                    <div className="row mh-42">
                                        <div className="col-md-12 pt-2">
                                            {" "}
                                            <div className=" inp text-danger api-errormessage text-center">
                                                <label id="txterror" name="txterror">
                                                    {apiMessage}
                                                </label>{" "}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {showPrevious ?
                                            <div className="col-6 pt-4">
                                                <Button
                                                    classes="inp-back"
                                                    label={strings.previous}
                                                    click={previousClick}
                                                />
                                            </div>
                                            : null}
                                        {showNext ?
                                            <div className="col-6 pt-3 text-right">
                                                <Button
                                                    classes="inp"
                                                    classes1="buttonmystyle"
                                                    label={strings.nextbtn}
                                                    click={nextClick}
                                                />
                                            </div>
                                            : null}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};
export default BankAccountSelection;