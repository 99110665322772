import React, { useEffect, useState } from "react";

function PincodeBoxV2(props) {
  const {
    label,
    placehold,
    classes,
    textId,
    fieldValue,
    textMaxLength,
    disabled,
    onTextChange,
    mainclass,
    inputclass,
    error,
    errorMessage,
    errorclass,
    options,
    optionClick,
    tabIndex,
  } = props;
  const [ddVisible, setDDVisible] = useState(false);
  const [value, setValue] = useState(fieldValue);
  const [hasError, setHasError] = useState(error);
  const [pinOptions, setPinOptions] = useState([]);

  useEffect(() => {
    setHasError(error);
  }, [error]);

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  useEffect(() => {
    setPinOptions(options);
    let o = options.find((i) => i.pincode === parseInt(value));
    if (o) {
      optionClick(o);
      setDDVisible(false);
    }
    return () => {
      setPinOptions([]);
    };
  }, [options]);

  const onChange = (e) => {
    let value = e.target.value;
    if (!textMaxLength) {
      setValue(value);
      onTextChange(e);
      setDDVisible(false);
    } else if (value.length <= parseInt(textMaxLength)) {
      setValue(value);
      onTextChange(e);
      setDDVisible(true);
    }
    let o = pinOptions.find((i) => i.pincode === parseInt(value));
    if (o) {
      optionClick(o);
      setDDVisible(false);
    }
  };

  return (
    <div className={mainclass}>
      <div className={classes}>
        <label htmlFor={textId}>{label}</label>
        <input
          className={inputclass}
          type="number"
          disabled={disabled}
          placeholder={placehold}
          tabIndex={tabIndex}
          id={textId}
          name={textId}
          value={value || ""}
          onChange={onChange}
          list="options"
        />
        {ddVisible && (
          <datalist id="options">
            {pinOptions.map((item, index) => (
              <option
                className="list-group-item"
                key={index}
                value={item.pincode}
              >
                {item.pincode}
              </option>
            ))}
          </datalist>
        )}
        <div className="paddingleft">
          <div className={errorclass}>{hasError && errorMessage}</div>{" "}
        </div>
      </div>
    </div>
  );
}

export default PincodeBoxV2;
