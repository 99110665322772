import React, { useEffect, useRef, useState } from "react";
import { strings } from "../Common/String";
import { copyTextToClipboard } from "../Common/utils";
import { MasterService } from "../Services/MasterService";
import AlertBox from "./AlertBox";

function PANFetchDialog(props) {
  const { show, cancelButtonAction } = props;
  const [field2, setField2] = useState("");
  const [apiMessage, setApiMessage] = useState("");
  const field1Ref = useRef();

  useEffect(() => {
    if (show) {
      field1Ref.current.focus();
    }
  }, [show]);

  const onCancel = () => {
    setField2("");
    cancelButtonAction();
  };

  const fetchName = () => {
    if (
      field1Ref &&
      field1Ref.current &&
      field1Ref.current.value &&
      field1Ref.current.value.length === 10
    ) {
      MasterService.fetchPANName(field1Ref.current.value)
        .then((data) => {
          console.log(data);
          if (data.success) {
            let name = [];
            if (data.data.first_name) name.push(data.data.first_name);
            if (data.data.middle_name) name.push(data.data.middle_name);
            if (data.data.last_name) name.push(data.data.last_name);
            setField2(name.join(" "));
          } else {
            setApiMessage(data.message, "error");
          }
        })
        .catch((error) => {
          setApiMessage("Something went wrong. Please try again.", "error");
        });
    }
  };

  return (
    <div className="loaderContainer panName" style={{ minWidth: "60vw" }}>
      <AlertBox
        show={show}
        title={strings.help}
        confirmButtonText={strings.close_text}
        cancelButtonAction={onCancel}
      >
        <div className="contents-area text-center">
          <ol>
            <li>
              <div className="text-left">{strings.name_note_1}</div>
              <div className="row ml-2 mt-2 mb-2">
                <input
                  className="text-uppercase"
                  ref={field1Ref}
                  tabIndex="1"
                  name="field1"
                  type="text"
                  placeholder={strings.pan_placeholder}
                  maxLength="10"
                />
                <div
                  className="digi-btn ml-2 mr-2"
                  tabIndex="2"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    cursor: "pointer",
                    textTransform: "uppercase",
                  }}
                  onClick={fetchName}
                >
                  {strings.fetch}
                </div>
                {field2 ? (
                  <React.Fragment>
                    <ClipboardCopy copyText={field2} />
                  </React.Fragment>
                ) : null}
              </div>
              <div className="text-danger text-left mh-25 ml-3">
                <label id="txterror" name="txterror" className="mb-0">
                  {apiMessage}
                </label>
              </div>
            </li>
            <li>
              <div className="text-left">{strings.name_note_2}</div>
            </li>
          </ol>
        </div>
      </AlertBox>
    </div>
  );
}

const ClipboardCopy = ({ copyText }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <input type="text" value={copyText} readOnly />
      <div
        className="digi-btn ml-2 mr-2"
        tabIndex="2"
        style={{
          textDecoration: "none",
          color: "white",
          cursor: "pointer",
          textTransform: "uppercase",
        }}
        onClick={handleCopyClick}
      >
        <span>{isCopied ? "Copied!" : "Copy"}</span>
      </div>
    </React.Fragment>
  );
};

export default PANFetchDialog;
