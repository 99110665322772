import React from "react";
import { strings } from "../../Common/String";
import AlertBox from "../../Component/AlertBox";
import Button from "../../Component/Button";

function KnowMoreDialog(props) {
  const { show, cancelButtonAction } = props;

  const onCancel = () => {
    cancelButtonAction();
  };

  const sendEmail = (e) => {
    e.preventDefault();
    window.open(
      `mailto:${strings.know_more_1b}?subject=Temporary de-activation of account&body=Hi Team,%0D%0DClient ID ___________.%0D%0DI would like to deactivate/freeze my trading account temporarily due to __________. My registered e-mail ID is _________________.%0D%0DThanks.`,
      "_blank"
    );
  };

  return (
    <div className="loaderContainer" style={{ minWidth: "60vw" }}>
      <AlertBox
        show={show}
        title={strings.closure_signin_popup_header}
        classes="box-section know-more-box-section"
        cancelButtonText={"Send Email"}
        cancelButtonAction={sendEmail}
        confirmButtonText={strings.close_text}
        confirmButtonAction={onCancel}
      >
        <div className="contents-area text-center m-2">
          <ol>
            <li>
              <div className="text-left flex-wrap">
                {strings.know_more_1a}
                {` `}
                {strings.know_more_1b}
                {` `}
                {strings.know_more_1c}
              </div>
            </li>
            <li>
              <div className="text-left">{strings.know_more_2}</div>
            </li>
            <li>
              <div className="text-left">{strings.know_more_3}</div>
            </li>
            <li>
              <div className="text-left">{strings.know_more_4}</div>
            </li>
            <li>
              <div className="text-left">{strings.know_more_5}</div>
            </li>
            <li>
              <div className="text-left">{strings.know_more_6}</div>
            </li>
          </ol>
        </div>
      </AlertBox>
    </div>
  );
}

export default KnowMoreDialog;
