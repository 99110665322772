import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { array, object, string } from "yup";
import LayoutWrapper from "../../Common/LayoutWrapper";
import { strings } from "../../Common/String";
import { getPath, mask, maskEmail } from "../../Common/utils";
import { Form } from "../../Component/Form";
import { CheckboxGroup } from "../../Component/Form/CheckboxGroup";
import { RadioGroup } from "../../Component/Form/RadioGroup";
import { AppContext } from "../../Context/AppContext";
import { LeadService } from "../../Services/LeadService";
import { MasterService } from "../../Services/MasterService";
import RadioGroupOptions from "../../Common/RadioGroupOptions";
import { isEmpty } from "lodash";
import { Input } from "../../Component/Form/Input";
import Button from "../../Component/Button";
import { useHistory } from "react-router-dom";

const OTHER_REASON = "Others";

const Summary = () => {
  const { setLoading, setHasChanges, isLoading } = useContext(AppContext);
  const [closureReasons, setClosureReasons] = useState([]);
  const [lead, setLead] = useState({});
  const [formData, setFormData] = useState({});
  const history = useHistory();
  const [apiMessage, setApiMessage] = useState("");
  const [showOtherReason, setShowOtherReason] = useState(false);

  const schema = object().shape({
    closureReasons: array().min(1, strings.closure_reason_error),
    otherReason: string().test("required", strings.rekyc_error, (value) => {
      return !isEmpty(selectedClosureReasons) &&
        selectedClosureReasons.includes(OTHER_REASON)
        ? !isEmpty(value)
        : true;
    }),
    holdingsToBe: string().test("required", strings.rekyc_error, (value) => {
      return formData.hasHoldings ? !isEmpty(value) : true;
    }),
    targetDPId: string().test("required", strings.rekyc_error, (value) => {
      return !isEmpty(selectedHoldingsToBe) &&
        [
          RadioGroupOptions.HOLDINGS_OPTIONS[1],
          RadioGroupOptions.HOLDINGS_OPTIONS[2],
        ].includes(selectedHoldingsToBe)
        ? !isEmpty(value)
        : true;
    }),
    targetClientId: string().test("required", strings.rekyc_error, (value) => {
      return !isEmpty(selectedHoldingsToBe) &&
        [
          RadioGroupOptions.HOLDINGS_OPTIONS[1],
          RadioGroupOptions.HOLDINGS_OPTIONS[2],
        ].includes(selectedHoldingsToBe)
        ? !isEmpty(value)
        : true;
    }),
  });

  const resolver = yupResolver(schema);

  const registerForm = useForm({
    defaultValues: {
      closureReasons: [],
      otherReason: "",
      holdingsToBe: "",
      targetDPId: "",
      targetClientId: "",
    },
    resolver,
  });

  const getHoldingsValue = (key) => {
    switch (key) {
      case "remat":
        return "Re-materialise";
      case "partly":
        return "Partly re-materialise and partly transfer.";
      case "transfer":
        return "Transfer to another account";
      default:
        break;
    }
    return "";
  };

  useEffect(() => {
    setHasChanges(false);
    setLoading(true);
    Promise.all([
      MasterService.getDropdown("client_closure_reason"),
      LeadService.getClosureSummary(),
    ])
      .then((datas) => {
        if (datas[0] && datas[0].client_closure_reason) {
          const reasons = datas[0].client_closure_reason;
          const index = reasons.findIndex(
            (reason) => reason.value === OTHER_REASON
          );
          if (index !== -1) {
            const others = reasons.find(
              (reason) => reason.value === OTHER_REASON
            );
            reasons.splice(index, 1);
            reasons.push(others);
          }
          setClosureReasons(reasons);
        }
        if (datas[1] && datas[1].success) {
          setLead(datas[1]);
          let formData = {};
          const data = datas[1];
          if (data.success) {
            formData.closureReasons = data.closure_reasons || [];
            formData.hasHoldings = data.holdings_exist;
            formData.holdingsToBe = getHoldingsValue(data.holdings_to_be || "");
            formData.targetDPId = data.target_dp_id || "";
            formData.targetClientId = data.target_dp_client_id || "";
            formData.otherReason = data.other_reason || "";
            if (formData.otherReason) {
              setShowOtherReason(true);
            }
          }
          registerForm.reset({
            ...formData,
          });
          setFormData((oldData) => ({ ...oldData, ...formData }));
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, []);

  const selectedHoldingsToBe = registerForm.watch("holdingsToBe");
  const selectedClosureReasons = registerForm.watch("closureReasons");

  useEffect(() => {
    if (selectedClosureReasons.includes(OTHER_REASON)) {
      setShowOtherReason(true);
    } else {
      const otherReasonInput = document.getElementById("otherReason");
      if (otherReasonInput) {
        otherReasonInput.value = "";
        registerForm.setValue("otherReason", "");
      }
      setShowOtherReason(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClosureReasons]);

  const onSubmit = (requestData) => {
    setHasChanges(true);
    setLoading(true);
    const getHoldingsToBe = (holdings) => {
      switch (holdings) {
        case "Re-materialise":
          return "remat";
        case "Partly re-materialise and partly transfer.":
          return "partly";
        case "Transfer to another account":
          return "transfer";
        default:
          break;
      }
      return holdings;
    };
    let details = {};
    details.closure_reasons = requestData.closureReasons;
    details.other_reason = requestData.otherReason;
    if (formData.hasHoldings) {
      details.holdings_to_be = getHoldingsToBe(requestData.holdingsToBe);
    }

    if (
      [
        RadioGroupOptions.HOLDINGS_OPTIONS[1],
        RadioGroupOptions.HOLDINGS_OPTIONS[2],
      ].includes(selectedHoldingsToBe)
    ) {
      details.target_dp_id = requestData.targetDPId;
      details.target_dp_client_id = requestData.targetClientId;
    }
    LeadService.updateClosureLeadDetails({ details })
      .then((data) => {
        if (data.success) {
          history.push(getPath(data.next_step));
        } else {
          if (data.invalid_fields) {
            onError(
              data.invalid_fields.reduce(
                (acc, curr) => (acc[curr.field] = true),
                {}
              )
            );
            data.invalid_fields.forEach((f) => {
              registerForm.setError(f.field, {
                message: f.error,
              });
            });
          }
          if (data.error !== "Invalid Input Value") setApiMessage(data.error);
          console.error(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const onError = (data) => {
    if (data.targetClientId || data.targetDPId || data.holdingsToBe) {
      var section1 = document.querySelector(".holdings-section");
      section1.scrollIntoView({
        behavior: "smooth",
      });
    } else if (data.closureReasons || data.otherReason) {
      var section2 = document.querySelector(".closure-reason-section");
      section2.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const getLedgerBalanceMessage = () => {
    const amountSplit = (lead.ledger_balance.message || "").split("{{amount}}");
    const beforeAmountText = amountSplit[0];
    const afterAmountText = amountSplit[1];
    const linkIndex = afterAmountText.indexOf("{{link}}");
    const hasLink = linkIndex !== -1;
    if (hasLink) {
      const linkSplit = afterAmountText.split("{{link}}");
      return (
        <span>
          {beforeAmountText}
          <b>Rs. {lead.ledger_balance.amount}</b>
          {linkSplit[0]}
          <a
            target="_blank"
            href={lead.ledger_balance.link || "#"}
            rel="noreferrer"
          >
            {lead.ledger_balance.link_label || "click here"}
          </a>
          {linkSplit[1]}
        </span>
      );
    }
    return (
      <span>
        {beforeAmountText}
        <b>Rs. {lead.ledger_balance.amount || 0}</b>
        {afterAmountText}
      </span>
    );
  };

  return (
    <LayoutWrapper>
      {isLoading ? null : (
        <div className="summarydiv">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10 marg">
                <div className="forms">
                  <div className="col-md-12">
                    <h5 className="pb-2 head5">
                      {strings.hi}
                      {lead.pan_name}
                      {lead.client_code ? ` (${mask(lead.client_code)})` : null}
                    </h5>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="flex align-items-center mb-3 mt-3">
                          <label className="p-0 col-md-2 mb-0 mt-sm-24">
                            Mobile
                          </label>
                          <div className="row ml-0 col-md-9">
                            <div className="disable color-gray">
                              {lead.mobile && mask(lead.mobile)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="flex align-items-center mb-3 mt-1">
                          <label className="p-0 col-md-2 mb-0 mt-sm-24">
                            E-mail
                          </label>
                          <div className="row ml-0 col-md-9">
                            <div className="disable color-gray">
                              {lead.email && maskEmail(lead.email, "*", 2, 2)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="flex align-items-center mb-3 mt-1">
                          <label className="p-0 col-md-2 mb-0 mt-sm-24">
                            Permanent address
                          </label>
                          <div className="row ml-0 col-md-9">
                            <div className="disable color-gray">
                              {lead.permanent_addr}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mt-4">
                        <span>{strings.please_note}</span>
                        <ol style={{ marginLeft: 16 }}>
                          <li>{strings.closure_note_1}</li>
                          <li>{strings.closure_note_2}</li>
                        </ol>
                      </div>
                    </div>
                    <div className="row">
                      <div className="closure-reason-section">
                        <Form
                          reactHookForm={registerForm}
                          onSubmit={onSubmit}
                          onError={onError}
                        >
                          <div className="col-md-12 pt-3">
                            <label>{strings.closure_reason_title}</label>
                          </div>
                          <div className="col-md-12 ml-1">
                            <CheckboxGroup
                              type="checkbox"
                              classes="closure-reasons"
                              inputClass="segchks1"
                              name="closureReasons"
                              tabIndex="12"
                              options={closureReasons}
                              style={{}}
                            />
                          </div>
                          {showOtherReason ? (
                            <div className={formData.hasHoldings ? "col-md-12 ml-1" : "col-md-12 ml-1 mb-3"}>
                              <label className={formData.hasHoldings ? "p-0 col-md-4 pt-2" : "p-0 col-md-12 pt-2"}>
                                {strings.closure_reason_other_title}
                              </label>
                              <Input
                                mainclass="col-md-6"
                                classes="inp"
                                tabIndex="10"
                                inputClass="mninp"
                                type="text"
                                name="otherReason"
                                maxLength={50}
                                errorClass="text-danger errorfont mnerr"
                              />
                            </div>
                          ) : null}
                          {lead.ledger_balance ? (
                            <div className="col-md-12">
                              <div className="mt-4 text_left" role="alert">
                                {getLedgerBalanceMessage()}
                              </div>
                            </div>
                          ) : null}
                          {formData.hasHoldings ? (
                            <div className="holdings-section">
                              <div className="col-md-12 pt-3 mt-2">
                                <label>{strings.closure_holdings_title}</label>
                              </div>
                              <div className="col-md-12">
                                <RadioGroup
                                  mainClass="row ml-0 pt-2 pb-3"
                                  tabIndex="3"
                                  options={RadioGroupOptions.HOLDINGS_OPTIONS}
                                  name="holdingsToBe"
                                  errorClass="text-danger errorfont radio_error ml-11"
                                />
                              </div>
                            </div>
                          ) : null}
                          {[
                            RadioGroupOptions.HOLDINGS_OPTIONS[1],
                            RadioGroupOptions.HOLDINGS_OPTIONS[2],
                          ].includes(selectedHoldingsToBe) ? (
                            <>
                              <div className="col-md-12 pt-3">
                                <label>
                                  {strings.closure_holdings_information}
                                </label>
                              </div>
                              <div className="col-md-6">
                                <div className="flex align-items-center mb-4">
                                  <label className="p-0 col-md-4 pt-2">
                                    Target DP ID
                                  </label>
                                  <Input
                                    mainclass="col-md-6"
                                    classes="inp"
                                    tabIndex="10"
                                    inputClass="mninp"
                                    type="text"
                                    name="targetDPId"
                                    maxLength={50}
                                    errorClass="text-danger errorfont mnerr"
                                  />
                                </div>
                                <div className="flex align-items-center mb-4">
                                  <label className="p-0 col-md-4 pt-2">
                                    Target Client ID
                                  </label>
                                  <Input
                                    mainclass="col-md-6"
                                    classes="inp"
                                    tabIndex="10"
                                    inputClass="mninp"
                                    type="text"
                                    name="targetClientId"
                                    maxLength={50}
                                    errorClass="text-danger errorfont mnerr"
                                  />
                                </div>
                              </div>
                            </>
                          ) : null}
                          {RadioGroupOptions.HOLDINGS_OPTIONS[0] ===
                            selectedHoldingsToBe && lead.remat_message ? (
                            <div className="col-md-12">
                              <div
                                className="alert alert-info text_left"
                                role="alert"
                              >
                                {lead.remat_message}
                              </div>
                            </div>
                          ) : null}
                          {apiMessage ? (
                            <div className="text-danger text-center mt-4">
                              <label id="txterror" name="txterror">
                                {apiMessage}
                              </label>
                            </div>
                          ) : null}
                          <div className={formData.hasHoldings ? "col-md-12" : "m-65"} >
                            <Button
                              type="submit"
                              classes1="inps mt-0"
                              label="Continue"
                            />
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </LayoutWrapper>
  );
};

export default Summary;
