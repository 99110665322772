import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { strings } from "../../Common/String";
import Count from "../../Component/CountComponent/Count";
import { Checkbox } from "../../Component/Form/Checkbox";
import { Input } from "../../Component/Form/Input";
import { yupResolver } from "@hookform/resolvers/yup";
import { bool, object, string } from "yup";
import { Form } from "../../Component/Form";
import { Dropdown } from "../../Component/Form/Dropdown";
import { LeadService } from "../../Services/LeadService";
import { AppContext } from "../../Context/AppContext";
import AlertBox from "../../Component/AlertBox";
import { OTP_TYPES } from "../../Common/Constants";
import IconButton from "../../Common/IconButton";
import Button from "../../Component/Button";
import { SummaryContext } from "../Summary/context";
import { isEmpty } from "lodash";

const Email = (props) => {
  const { show, cancelButtonAction, onSuccess } = props;
  const { setLoading } = useContext(AppContext);
  const { masterRelationships: ddRelationship } = useContext(SummaryContext);

  const [showOTPBlock, setShowOTPBlock] = useState(false);
  const [isShow, setShow] = useState(false);
  const [isShowPencil, setShowPencil] = useState(false);
  const [restartTimer, setRestartTimer] = useState(0);
  const [showRelation, setShowRelation] = useState(false);
  const [apiMessage, setApiMessage] = useState(undefined);

  const phoneRef = useRef();
  const otpRef = useRef();

  const defOption = useMemo(
    () => ddRelationship.find((d) => d.is_default === true),
    [ddRelationship]
  );

  const schema = object().shape({
    email: string()
      .email(strings.rekyc_emailerror)
      .required(strings.rekyc_error),
    tnc: bool().test("required", strings.rekyc_chkerror, (value) => {
      return !showOTPBlock ? value : true;
    }),
    otp: string()
      .test("required", strings.rekyc_otp_error, (value) => {
        return showOTPBlock ? value && !isNaN(value) : true;
      })
      // .min(showOTPBlock ? 6 : 0, strings.rekyc_otp_error)
      .max(6, strings.rekyc_otp_error),
    relationship: string().test(strings.rekyc_error, (value) => {
      return showRelation ? value : true;
    }),
  });

  const resolver = yupResolver(schema);

  const registerForm = useForm({
    defaultValues: {
      email: "",
      tnc: false,
      otp: "",
      relationship: "",
    },
    // reValidateMode: undefined,
    resolver,
  });

  useEffect(() => {
    if (defOption) {
      registerForm.setValue(
        "relationship",
        defOption?.value || ddRelationship[0].value
      );
    }
  }, [defOption]);

  useEffect(() => {
    if (show && phoneRef && phoneRef.current) phoneRef.current.focus();
  }, [show]);

  const requestOTP = (data) => {
    setLoading(true);
    LeadService.rekycSectionRequestOTP(OTP_TYPES.EMAIL, data.email)
      .then((data) => {
        if (data.success) {
          setShowOTPBlock(true);
          setShow(true);
          setRestartTimer((old) => old + 1);
          if (otpRef && otpRef.current) otpRef.current.focus();
          setShowRelation(data.relationship_required);
          setApiMessage(null);
        } else {
          setApiMessage(data.error);
          console.error(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const onSubmit = (data) => {
    setLoading(true);
    LeadService.rekycSectionValidateOTP(
      OTP_TYPES.EMAIL,
      data.email,
      data.otp,
      data.relationship
    )
      .then((data) => {
        if (data.success) {
          resetForm();
          onSuccess();
        } else {
          setApiMessage(data.error);
          console.error(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  };

  const resetForm = () => {
    const checkbox = document.getElementById("email-tnc");
    if (checkbox) checkbox.checked = false;
    registerForm.reset({
      mobile: "",
      tnc: false,
      otp: "",
      relationship: defOption?.value,
    });
    setShowOTPBlock(false);
    setShowPencil(false);
    setShow(false);
    setShowRelation(false);
    setApiMessage(null);
  };

  const onCancel = () => {
    resetForm();
    cancelButtonAction();
  };

  const resendOTP = () => {
    setShowPencil(false);
    requestOTP(registerForm.getValues());
  };

  const editpencil = (event) => {
    event.preventDefault();
    setShowOTPBlock(false);
    setShow(false);
    setShowRelation(false);
    registerForm.resetField("otp");
    setApiMessage(null);
  };

  const ApiError = () =>
    !isEmpty(apiMessage) ? (
      <div className="text-danger errorfont text-center">
        <label id="txterror" name="txterror">
          {apiMessage}
        </label>{" "}
      </div>
    ) : null;

  return (
    <div className="loaderContainers">
      <AlertBox
        show={show}
        title={
          <div className="ml-4 mt-3 w-75">
            <h5 className="update-label">{strings.rekyc_update_label}</h5>
          </div>
        }
        showCancelIcon={true}
        cancelButtonAction={onCancel}
        classes="box-sections"
      >
        <div className="container" id="pd-50">
          <hr />
          <Form
            autoFocusElement="email"
            reactHookForm={registerForm}
            onSubmit={showOTPBlock ? onSubmit : requestOTP}
          >
            <Input
              reference={phoneRef}
              classes="inp pt-3"
              tabIndex="91"
              placeholder={strings.rekyc_email_label}
              inputClass={showOTPBlock ? "email-input pr-5" : "email-input"}
              type="email"
              name="email"
              disabled={isShow}
              maxLength={250}
              errorclass="text-danger err"
              postfix={
                isShow && (
                  <IconButton
                    classes={"edit-bt"}
                    imgsrc={process.env.PUBLIC_URL + "/img/pen.png"}
                    click={editpencil}
                  />
                )
              }
            />
            {!showOTPBlock ? (
              <>
                <Checkbox
                  type="checkbox"
                  classes="check1 mt-2"
                  inputClass="check2 mt-0"
                  name="tnc"
                  id="email-tnc"
                  tabIndex="92"
                  label={strings.rekyc_emailpara_label}
                  labelClass="lblcheck mb-0 -mt-2"
                  errorclass="text-danger err"
                />
                {/* <ApiError /> */}
                <div className="pt-3 row justify-content-end align-items-center mr-0">
                  <Button
                    type="reset"
                    classes1="cancelbtn"
                    click={onCancel}
                    label="Cancel"
                  />
                  <Button
                    type="submit"
                    classes1="inps"
                    tabIndex="93"
                    label="Send OTP"
                  />
                </div>
              </>
            ) : (
              <>
                <Input
                  reference={otpRef}
                  placeholder="Enter OTP"
                  inputClass="mobinputs"
                  type="number"
                  name="otp"
                  tabIndex="92"
                  pattern="\d*"
                  maxLength={6}
                  errorclass="text-danger errorfont mh-18"
                  postfix={
                    <div className="otp-timer">
                      <Count
                        onFinish={() => setShowPencil(true)}
                        restart={restartTimer}
                      />
                    </div>
                  }
                />
                {isShowPencil && (
                  <p className="resends pt-1">
                    Didn't Receive OTP?{" "}
                    <a href="#" onClick={resendOTP}>
                      Resend
                    </a>
                  </p>
                )}
                {showRelation ? (
                  <Dropdown
                    classes="material-textfield mt-2"
                    label={strings.rekyc_relation_label}
                    labelClass="lbltransforms lblz"
                    selectClass="mobinputs pb-2 p-2"
                    drId="inc"
                    tabIndex="93"
                    selectid="ddl_inc"
                    name="relationship"
                    options={ddRelationship}
                    errorclass="text-danger errorfont mh-18"
                  />
                ) : null}
                <ApiError />
                <Button
                  type="submit"
                  classes1="inpt"
                  tabIndex="94"
                  label="Submit"
                />
              </>
            )}
          </Form>
        </div>
      </AlertBox>
    </div>
  );
};

export { Email };
