import React from "react";
import Button from "./Button";

function AlertBox({ children, ...props }) {
  const {
    show,
    message,
    title,
    confirmButtonText,
    cancelButtonText,
    confirmButtonAction,
    cancelButtonAction,
    classes,
    className,
    showCancelIcon = false,
  } = props;
  return (
    <div
      className={show ? `aadhar-popup-bg ${className}` : "d-none"}
      style={{ zIndex: 2000 }}
    >
      <div className={classes || "box-section"}>
        {showCancelIcon ? (
          <i className="mybutton" onClick={cancelButtonAction}>
            &times;
          </i>
        ) : null}
        {title && <div className="header-area alertbox_title">{title}</div>}
        {message && (
          <div
            className={
              !title ? "alertbox_message add-classpopup" : "alertbox_message"
            }
          >
            {message}
          </div>
        )}
        <div>{children}</div>
        <div className="btn-area1 text-right">
          <Button
            classes="inp-back cancel-button"
            label={confirmButtonAction ? cancelButtonText : confirmButtonText}
            click={cancelButtonAction}
          />

          {confirmButtonAction && (
            <Button
              classes="inp-back confirm-button"
              label={confirmButtonText}
              click={confirmButtonAction}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default AlertBox;
