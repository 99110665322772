import React, { useEffect, useState } from "react";
/**
 * Field for OTP
 * @param {*} props
 * @returns
 */
function OTPInput(props) {
  const {
    divClasses,
    classes,
    textType,
    textName,
    onChange: onTextChange,
    postfix,
    textId,
    fieldValue,
    otpPlaceholder,
    maxLength,
    reference,
    tabIndex,
  } = props;
  const [value, setValue] = useState(fieldValue);

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  const onChange = (e) => {
    if (!maxLength) {
      setValue(e.target.value);
      onTextChange(e);
    } else if (e.target.value.length <= parseInt(maxLength)) {
      setValue(e.target.value);
      onTextChange(e);
    }
  };

  return (
    <div className={divClasses}>
      <input
        ref={reference}
        className={classes}
        type={textType}
        name={textName}
        onChange={onChange}
        id={textId}
        tabIndex={tabIndex}
        //name={textId}
        value={value || ""}
        placeholder={otpPlaceholder}
      />
      {postfix}
    </div>
  );
}

export default OTPInput;
