import Config from "../Common/Config";
//  import { OTP_TYPES } from "../Common/Constants";
import { RestService } from "./RestService";

export const MasterService = {
  getGuestToken: async function () {
    return RestService.get(`${Config.guest_token}`, {}).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403
      ) {
        return response.json();
      }
    });
  },
  validateToken: async function () {
    return RestService.get(`${Config.validate_token}`, {}).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403
      ) {
        return response.json();
      }
    });
  },
  termsConditions: async function () {
    return RestService.get(`${Config.terms_conditions}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403
        ) {
          return response.json();
        }
      }
    );
  },
  getPincodeList: async function (filter) {
    return RestService.get(`${Config.pincode_url}?filter=${filter}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403
        ) {
          return response.json();
        }
      }
    );
  },
  getCityList: async function (filter) {
    return RestService.get(`${Config.city_url}?filter=${filter}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403
        ) {
          return response.json();
        }
      }
    );
  },
  getDropdown: async function (type) {
    return RestService.get(`${Config.get_dropdown}?type=${type}`, {}).then(
      (response) => {
        if (response.status === 200) {
          return response.json();
        } else if (
          response.status === 404 ||
          response.status === 409 ||
          response.status === 400 ||
          response.status === 403
        ) {
          return response.json();
        }
      }
    );
  },
  getSegments: async function () {
    return RestService.get(`${Config.get_segments}`, {}).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403
      ) {
        return response.json();
      }
    });
  },
  getBrokerage: async function () {
    return RestService.get(`${Config.get_brokerage}`, {}).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403
      ) {
        return response.json();
      }
    });
  },
  fetchPANName: async function (pan = null) {
    return RestService.get(
      `${Config.fetch_pan_name_url}?pan_no=${pan}`,
      {}
    ).then((response) => {
      if (response.status === 200) {
        return response.json();
      } else if (
        response.status === 404 ||
        response.status === 409 ||
        response.status === 400 ||
        response.status === 403
      ) {
        return response.json();
      }
    });
  },
};
