import React, { useEffect, useState } from "react";

function DynamicDropdown(props) {
    const {
        headerlabel,
        placehold,
        classes,
        textId,
        fieldValue,
        textMaxLength,
        disabled,
        onTextChange,
        mainclass,
        inputclass,
        error,
        errorMessage,
        errorclass,
        options,
        optionClick,
        tabIndex,
        label,
    } = props;
    const [ddVisible, setDDVisible] = useState(false);
    const [value, setValue] = useState(fieldValue);
    const [hasError, setHasError] = useState(error);
    const [dropdownOptions, setDropdownOptions] = useState([]);

    useEffect(() => {
        setHasError(error);
    }, [error]);

    useEffect(() => {
        setValue(fieldValue);
    }, [fieldValue]);

    useEffect(() => {
        setDropdownOptions(options);
        let o = options.find((i) => i.option === parseInt(value));
        if (o) {
            optionClick(o);
            setDDVisible(false);
        }
        return () => {
            setDropdownOptions([]);
        };
    }, [options]);

    const onChange = (e) => {
        let value = e.target.value;
        if (!textMaxLength) {
            setValue(value);
            onTextChange(e);
            setDDVisible(false);
        } else if (value.length <= parseInt(textMaxLength)) {
            setValue(value);
            onTextChange(e);
            setDDVisible(true);
        }
        let o = dropdownOptions.find((i) => i.option === parseInt(value));
        if (o) {
            optionClick(o);
            setDDVisible(false);
        }
    };

    return (
        <div className={mainclass}>
            <div className={classes}>
                <label htmlFor={textId}>{headerlabel}</label>
                <input
                    className={inputclass}
                    type="text"
                    disabled={disabled}
                    placeholder={placehold}
                    tabIndex={tabIndex}
                    id={textId}
                    name={textId}
                    value={value || ""}
                    onChange={onChange}
                    list="options"
                    label={label}
                />
                {ddVisible && (
                    <datalist id="options">
                        {dropdownOptions.map((item, index) => (
                            <option
                                className="list-group-item"
                                key={index}
                                value={item[label]}
                            >
                                {item[label]}
                            </option>
                        ))}
                    </datalist>
                )}
                <div className="paddingleft">
                    <div className={errorclass}>{hasError && errorMessage}</div>{" "}
                </div>
            </div>
        </div>
    );
}

export default DynamicDropdown;
