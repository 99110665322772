import React from "react";

function PennydropSpin() {  
  return (
    <div className="ajax-loader">
    <img src={process.env.PUBLIC_URL +"/img/Penny drop transparent.gif"} alt="" style={{ width: "25vw"}}/>
  </div>

  );
}
export default PennydropSpin;
