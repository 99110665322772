import { PATH } from "./Path";

const steps = [{
    id: 1,
    label: 'Phone Number',
    status: 0
}, {
    id: 2,
    label: 'Email ID',
    status: 0
}, {
    id: 3,
    label: 'PAN Verification',
    status: 0
}, {
    id: 4,
    label: 'Personal Details',
    status: 0,
    link: PATH.PERSONALBASIC_PAGE
}, {
    id: 5,
    label: 'Bank Details',
    status: 0,
    link: PATH.BANKACCOUNT_PAGE
}, {
    id: 6,
    label: 'Segment Selection',
    status: 0,
    link: PATH.SEGMENTS_PAGE
}, {
    id: 7,
    label: 'In Person Verification',
    status: 0,
    link: PATH.INPERSON_PAGE
}, {
    id: 8,
    label: 'Upload Proof',
    status: 0,
    link: PATH.UPLOADPROOFS_PAGE
}, {
    id: 9,
    label: 'E-sign',
    status: 0,
    link: PATH.ESIGN_PAGE
}]
export default steps;