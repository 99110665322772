import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../Context/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import { strings } from "../Common/String";
import Button from "../Component/Button";
import { LeadService } from "../Services/LeadService";
import Footer from "../footer";
import TextBox from "../Component/TextField";
import OTPInput from "../Component/OTPInput";
import Count from "../Component/CountComponent/Count";
import validator from "validator";
import { PATH } from "../Common/Path";
import { getCurrentStep, getPath } from "../Common/utils";
import SignInButton from "../Component/SignInButton";
import EditPen from "../Component/EditPen";
import queryString from "query-string";

const Signin = (props) => {
  const id = 3;
  const { steps, setSteps, setToken, setLoading, setCurrentStep } =
    useContext(AppContext);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [apiMessage, setApiMessage] = useState("");
  const [showPasswordBlock, setShowPasswordBlock] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [showresendotp, setshowresendotp] = useState(false);
  const [otp, setOtp] = useState("");
  const [showOTPFullBlock, setshowOTPFullBlock] = useState(false);
  const [showOTPBlock, setShowOTPBlock] = useState(false);
  const [showErrorPass, setShowErrorPass] = useState(false);
  const [showErrorOtpSec, setShowErrorOtpSec] = useState(false);
  const [restartTimer, setRestartTimer] = useState(0);
  const [showpencil, setShowpencil] = useState(false);
  const [disabled, setDisabled] = useState(false);
  // const phoneOTPRef = useRef();
  const otpRef = useRef();
  const emailpassRef = useRef();
  const passwordRef = useRef();

  //------------for hide and visible- otp--------------------
  const [visibleContinue, setVisibleContinue] = useState(true);
  const [visibleSubmit, setVisibleSubmit] = useState(false);
  const [referralId, setReferralId] = useState('');

  useEffect(() => {
    if (props.location.search) {
      let qString = queryString.parse(props.location.search);
      const referral_id = qString.referral_id || null;
      if (referral_id && referral_id.length > 0) {
        setReferralId(referral_id);
      }
    }
  }, []);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    steps.forEach((step) => {
      if (step.id === id) {
        step.status = 1;
      } else if (step.id < id) {
        step.status = 2;
      }
    });
    setSteps(steps);
  }, []);
  useEffect(() => {
    if (emailpassRef && emailpassRef.current) {
      emailpassRef.current.focus();
    }
  }, []);
  /**
   * email validation
   * @param {*} e
   * @returns
   */

  /**
   * phone validation
   * @param {} event
   */

  /**
   * selectbox onchange
   */

  const mandatoryValidationpassword = () => {
    let isValid = true;
    if (!formData.phnOrEmail) {
      setShowErrorPass(strings.mandatory);
      isValid = false;
    } else if (
      formData.phnOrEmail &&
      (validator.isEmail(formData.phnOrEmail) ||
        formData.phnOrEmail.length === 10)
    ) {
      setShowErrorPass("");
    } else {
      setShowErrorPass(strings.phoneoremailerror);
      isValid = false;
    }

    if (!formData.password) {
      setErrors((oldErrors) => ({ ...oldErrors, password: true }));
      isValid = false;
    } else {
      setErrors((oldErrors) => ({ ...oldErrors, password: false }));
    }
    return isValid;
  };
  const mandatoryValidationemailphn = () => {
    let isValid = true;
    var email1 = formData.phnEmailForOTP;
    if (!formData.phnEmailForOTP) {
      setShowErrorOtpSec(strings.mandatory);

      isValid = false;
    } else {
      if (
        (formData.phnEmailForOTP && validator.isEmail(email1)) ||
        email1.length === 10
      ) {
        setShowErrorOtpSec("");
      } else {
        isValid = false;
        setShowErrorOtpSec(strings.phoneoremailerror);
      }
    }
    return isValid;
  };

  const handleChange = (event) => {
    setShowErrorOtpSec("");
    if (event.target.name === "phnEmailForOTP") {
      setFormData((oldData) => ({
        ...oldData,
        phnEmailForOTP: event.target.value,
      }));
      setShowErrorOtpSec("");
    }

    if (event.target.name === "phnOrEmail") {
      setFormData((oldData) => ({
        ...oldData,
        phnOrEmail: event.target.value,
      }));
      setShowErrorPass("");
    }

    if (event.target.name === "otp") {
      setFormData((oldData) => ({ ...oldData, otp: event.target.value }));
    }

    /**
     * password
     */
    if (event.target.name === "password") {
      setFormData((oldData) => ({ ...oldData, password: event.target.value }));
      setErrors((oldErrors) => ({ ...oldErrors, password: false }));
    }
  };
  /**
   * buttonclick
   */
  // const nextClick = () => {

  // }
  /**
   * previous step
   */
  // const previousClick = () => { }

  const onClickPassword = () => {
    setShowPasswordBlock(true);
    setshowOTPFullBlock(false);
    //  emailpassRef.current.focus()
  };
  const onClickOTP = () => {
    //  phoneOTPRef.current.focus();
    setShowPasswordBlock(false);
    setshowOTPFullBlock(true);
  };
  /**
   * conter function
   */
  const counterFinished = () => {
    setshowresendotp(true);
    //  console.log("Counter Finished");
  };
  // function onFinish() {
  //   setshowresendotp(true);
  // }
  /**
   * otp validataion
   * @param {} e
   */
  const onOTPchange = (e) => {
    setShowErrorOtpSec("");
    setApiMessage("");
    if (e.target.value.length !== null) {
      setOtp(e.target.value);
      // setIsError(false);
    } else {
      setOtp("");
      //setIsError(true);
    }
  };
  /**
   * request otp
   * @param {*} type
   */
  const requestOTP = () => {
    if (mandatoryValidationemailphn()) {
      setLoading(true);
      setShowErrorOtpSec("");
      let body = {
        type: "send_otp", //Mandatory send_otp, validate_otp , password
        id: formData.phnEmailForOTP,
        password: formData.password,
        otp: otp,
        referral_id: referralId,
        created_by: "",
      };
      LeadService.SubmitEmailandPassword(body)
        .then((data) => {
          if (data.success) {
            setDisabled(true);
            setShowpencil(true);
            setShowOTPBlock(true);
            setVisibleSubmit(true);
            setVisibleContinue(false);
            setRestartTimer((old) => old + 1);
            otpRef.current.focus();
          } else {
            setShowErrorOtpSec(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    }
  };
  /**
   * OTP VALIDATION
   * @returns
   */

  const mandatoryValidationOTPVerify = () => {
    let isValid = true;
    if (!otp) {
      isValid = false;
      setShowErrorOtpSec(strings.please_enter_otp);
    } else {
      isValid = true;
      setShowErrorOtpSec("");
    }
    return isValid;
  };

  /**
   * otp validation
   */
  const handleOTPSubmit = () => {
    if (formData.phnEmailForOTP && mandatoryValidationOTPVerify()) {
      setLoading(true);
      let body = {
        type: "validate_otp", //Mandatory send_otp, validate_otp , password
        id: formData.phnEmailForOTP,
        password: formData.password,
        otp: otp,
        referral_id: referralId,
        created_by: "",
      };
      setShowErrorOtpSec("");
      LeadService.SubmitEmailandPassword(body)
        .then((data) => {
          if (data.success) {
            setToken(data.token);
            history.push(getPath(data.next_step));
          } else {
            setShowErrorOtpSec(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    }
  };
  /**
   * password submit
   */
  const submitPassword = () => {
    if (mandatoryValidationpassword()) {
      setLoading(true);
      let body = {
        type: "password", //Mandatory send_otp, validate_otp , password
        id: formData.phnOrEmail,
        password: formData.password,
        otp: "",
        referral_id: referralId,
        created_by: "",
      };
      setApiMessage("");
      LeadService.SubmitEmailandPassword(body)
        .then((data) => {
          if (data.success) {
            setToken(data.token);
            setCurrentStep(getCurrentStep(data.next_step));
            history.push(getPath(data.next_step));
          } else {
            setApiMessage(data.error);
            setErrors((oldErrors) => ({ ...oldErrors, password: true }));
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    } else {
    }
  };
  // const passwordChange =(event)=>
  // {
  //   event.persist();

  // }
  /**
   * RESEND OTP
   */
  const resendOTP = () => {
    // setShowOTPBlock(false);
    setshowresendotp(false);
    requestOTP();
  };
  /**
   * signup
   */
  const onSignUpClick = () => {
    history.push(`${PATH.MOBILE_PAGE}${referralId?`?referral_id=${referralId}`:''}`);
  };

  const handlepenmcil = (event) => {
    event.preventDefault();
    setshowresendotp(false);
    setShowOTPBlock(false);
    setVisibleSubmit(false);
    setVisibleContinue(true);
    setShowpencil(false);
    setDisabled(false);
    setApiMessage("");
    setShowErrorOtpSec("");
  };

  return (
    <>
      <div className="header pb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="logo-align">
                <img
                  src={process.env.PUBLIC_URL + "/img/acumen-logo.png"}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-6" />
          </div>
        </div>
      </div>
      <div className="inner">
        <div className="container">
          <div className="row">
            <div className="col-md-50 text-center">
              <div className="images-add">
                <img src={process.env.PUBLIC_URL + "/img/sign-up.gif"} alt="" />
              </div>
            </div>
            <div className="col-md-50 pt-3 pt-lg-5 mb-5">
              <div className="s-box">
                <h1 className="text-center pb-2">
                  {strings.continueyourapplication}
                </h1>
                <div className=" pt-3">
                  <div className="tab">
                    <button
                      className={`${
                        showPasswordBlock ? "tablinks active" : "tablinks"
                      }`}
                      onClick={onClickPassword}
                    >
                      {strings.password}
                      {showPasswordBlock && (
                        <img
                          src={process.env.PUBLIC_URL + "/img/arrow.png"}
                          alt=""
                        />
                      )}
                    </button>

                    <button
                      className={`${
                        showOTPFullBlock ? "tablinks active" : "tablinks"
                      }`}
                      onClick={onClickOTP}
                    >
                      {strings.otp}
                      {showOTPFullBlock && (
                        <img
                          src={process.env.PUBLIC_URL + "/img/arrow.png"}
                          alt=""
                        />
                      )}
                    </button>
                  </div>
                </div>

                {showPasswordBlock && (
                  <div className="tabcontent">
                    <TextBox
                      reference={emailpassRef}
                      mainclass="inp pt-3"
                      placehold={strings.phnoremail}
                      type="text"
                      textId="phnOrEmail"
                      fieldValue={formData.phnOrEmail}
                      onTextChange={handleChange}
                    />
                    <section className="mb-0 text-danger errorfont api-errormessage mh-25">
                      {showErrorPass}
                    </section>
                    <div></div>
                    <TextBox
                      reference={passwordRef}
                      mainclass="inp"
                      lblclass="d-none"
                      placehold={strings.enter_password}
                      inputclass="mb-0"
                      type={passwordShown ? "text" : "password"}
                      textId="password"
                      fieldValue={formData.password}
                      onTextChange={handleChange}
                      error={errors.password}
                      errorMessage={apiMessage || strings.mandatory}
                      errorclass="text-danger errorfont mh-25"
                      postfix={
                        <div className="edit-btn1 top-5">
                          <a href="# " onClick={togglePasswordVisiblity}>
                            {passwordShown && (
                              <img
                                src={process.env.PUBLIC_URL + "/img/eye.png"}
                                alt=""
                              />
                            )}
                            {!passwordShown && (
                              <img
                                src={
                                  process.env.PUBLIC_URL + "/img/eye-close.png"
                                }
                                alt=""
                              />
                            )}
                          </a>
                        </div>
                      }
                    />

                    {/* <section id="txterror" name="txterror" className="text-danger api-errormessage mh-25" >{apiMessage}</section> */}
                    <Button
                      classes="inp pt-1"
                      label={strings.signin}
                      click={submitPassword}
                      inputType="text"
                      classes1="buttonmystyle"
                    />
                  </div>
                )}

                {showOTPFullBlock && (
                  <div className="tabcontent">
                    <TextBox
                      mainclass="inp pt-3"
                      placehold={strings.phnoremail}
                      type="text"
                      textId="phnEmailForOTP"
                      fieldValue={formData.phnEmailForOTP}
                      onTextChange={handleChange}
                      disabled={disabled}
                      postfix={
                        showpencil && (
                          <EditPen
                            classes={"edit-btn1 icon-topsignin"}
                            imgsrc={process.env.PUBLIC_URL + "/img/pen.png"}
                            imgclass="signinpencil"
                            click={handlepenmcil}
                          />
                        )
                      }
                    />
                    {showOTPBlock && (
                      <OTPInput
                        divClasses="inp pt-3"
                        maxLength="6"
                        classes={"mb-0"}
                        textType="number"
                        otpPlaceholder={strings.otpenter}
                        onChange={onOTPchange}
                        holder={String.otpEnter}
                        textId={otp}
                        error={errors.otp}
                        errorclass="text-danger errorfont"
                        errorMessage={strings.mandatory}
                        postfix={
                          <div className="time-otp">
                            <Count
                              onFinish={counterFinished}
                              restart={restartTimer}
                            />
                          </div>
                        }
                      />
                    )}
                    {showresendotp && (
                      <div className="pt-1">
                        <p className="mb-0">
                          {strings.cmn_otp}{" "}
                          <a href="# " onClick={resendOTP}>
                            {strings.resend}{" "}
                          </a>
                        </p>
                      </div>
                    )}
                    <section
                      id="txterror"
                      name="txterror"
                      className="text-danger api-errormessage mh-25"
                    >
                      {showErrorOtpSec}
                    </section>
                    {visibleContinue && (
                      <Button
                        classes="inp pt-3"
                        label={strings.continue}
                        click={requestOTP}
                        inputType="text"
                        classes1="buttonmystyle"
                      />
                    )}
                    {visibleSubmit && (
                      <Button
                        classes="inp pt-1"
                        label={strings.signin}
                        click={handleOTPSubmit}
                        inputType="text"
                      />
                    )}
                  </div>
                )}

                <div className="or-devide">
                  <hr />
                  <span>or</span>
                </div>
                <div className="continue-with">
                  <p className="text-center mb-0">{strings.donthaveaccount}</p>
                  <a href="#"> </a>
                  <SignInButton
                    classes={"sign-b"}
                    label={strings.signup}
                    onclick={onSignUpClick}
                  ></SignInButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Signin;
