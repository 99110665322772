import React, { useContext } from "react";
import StatusRow from "./Component/StatusRow";
import { AppContext } from "./Context/AppContext";

/**
 * Header for the APP
 * @returns
 */
function Header2({ show = true }) {
  const { steps, isMobileView } = useContext(AppContext);
  return (
    <div className="col-md-3 side-section ">
      <div className="log-main  pt-4 pl-4 pb-5">
        <a href="#">
          <img src={process.env.PUBLIC_URL + "/img/acumen-logo.png"} alt="" />
        </a>
      </div>
      {!isMobileView &&
        show &&
        steps &&
        steps.map((step, i) => {
          if (step.status === 0) {
            // Not started
            return (
              // <div key={i} className="verification">
              //   <a className href="#">{step.label}</a>
              // </div>
              <StatusRow
                tickClasses="verification"
                label={step.label}
                key={i}
              />
            );
          } else if (step.status === 1) {
            // Current step
            return (
              // <div key={i} className="verification">
              //   <a className="process" href="#">{step.label}</a>
              // </div>
              <StatusRow
                tickClasses="verification"
                labelClasses="process"
                label={step.label}
                key={i}
                link={step.link}
              />
            );
          } else if (step.status === 2) {
            // Finished step
            return (
              <StatusRow
                tickClasses="verification"
                labelClasses={step.link ? "active" : "not-clickable"}
                label={step.label}
                key={i}
                link={step.link}
              />
            );
          } else if (step.status === 3) {
            return (
              <StatusRow
                tickClasses="verification"
                labelClasses="active"
                label={step.label}
                key={i}
                link={step.link}
              />
            );
          } else {
            return <></>;
          }
        })}
    </div>
  );
}

export default Header2;
