import { useFieldArray } from "react-hook-form";
import { useForm } from ".";

const useInputArray = (name = "") => {
  const { control } = useForm();
  const { fields, append, prepend, remove } = useFieldArray({ control, name });
  return { fields, append, prepend, remove };
};

export { useInputArray };
