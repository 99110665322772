import React from "react";
import { useHistory } from "react-router";
import Button from "../Component/Button";
import { strings } from "../Common/String";
import { PATH } from "../Common/Path";
// import "../css/NotFound.css";

const NotFound = (props) => {
  const history = useHistory();
  /**
   * back button
   * @param {} event 
   */
  const handleSubmit = (event) => {    
   history.push(PATH.MOBILE_PAGE);
  };
  return (
  <div className="details">
  <div className="container-fluid">
    <div className="row">
      <div className="col-md-12 pl-5 pt-55 pb-5 pr-5 text-center">
        <div className="row">
          <div className="col-md-12 text-center">
            <img src={process.env.PUBLIC_URL +"/img/404.png"} alt=""/>
               <Button
                  classes="inp pt-5 pb-5"
                  label={strings.backhome}
                  inputType="text"
                  click={handleSubmit}
                />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  );
};
export default NotFound;