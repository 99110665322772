import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../../Context/AppContext";
import { strings } from "../../Common/String";
import Button from "../../Component/Button";
import { LeadService } from "../../Services/LeadService";
import FileUpload from "../../Component/FileUpload";
import { getPath } from "../../Common/utils";
import LayoutWrapper from "../../Common/LayoutWrapper";

const UploadProofs = () => {
  const {
    setLoading,
    showConfirmDialog,
    closeConfirmDialog,
    hasChanges,
    setHasChanges,
  } = useContext(AppContext);
  const history = useHistory();
  const [uploadBlocks, setUploadBlocks] = useState([]);
  const [description, setDescription] = useState(null);
  const [step, setStep] = useState(null);

  useEffect(() => {
    setHasChanges(false);
    setLoading(true);
    Promise.all([
      LeadService.rekycGetUploadProofList(),
      LeadService.rekycGetLeadProofs(),
      LeadService.rekycGetStepDetails("ReKYCUploadProof"),
    ])
      .then((datas) => {
        let ub = [];
        if (datas[0] && datas[0].required_proofs) {
          ub = datas[0].required_proofs.map((d) => ({
            ...d,
            selectedOption: d.is_dropdown
              ? d.dropdown_options
                ? d.dropdown_options.find((o) => o.is_default)
                  ? d.dropdown_options.find((o) => o.is_default).value
                  : d.dropdown_options[0].value
                : null
              : null,
          }));
          setDescription(datas[0].description);
        }
        if (
          datas[1] &&
          datas[1].proofs &&
          ((datas[0] && !datas[0].rejection) || !datas[0])
        ) {
          ub.forEach((block) => {
            let p = datas[1].proofs.find(
              (d) => d.document_code === block.document_code
            );
            if (p) {
              block.fileURL = p.url;
              block.fileName = p.original_filename;
              if (block.is_dropdown) {
                block.selectedOption = p.dropdown_value;
              }
            }
          });
        }
        setUploadBlocks(ub);
        if (datas[2].success) {
          setStep(datas[2].previous_step);
        } else {
          setStep(false);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, []);

  const change = (field, file) => {
    let ub = uploadBlocks;
    let block = ub.find((b) => b.document_code === field);
    block.file = file;
    block.fileURL = null;
    block.isError = false;
    block.errorMessage = undefined;
    setUploadBlocks(ub);
    setHasChanges(true);
  };
  const save = () => {
    setLoading(true);
    let uploadReqs = uploadBlocks
      .filter((d) => d.file && !d.fileURL)
      .map((block) => {
        let uploadData = new FormData();
        uploadData.append("document_code", block.document_code);
        uploadData.append("file", block.file || null);
        uploadData.append("dropdown_value", block.selectedOption || null);
        return LeadService.rekycUploadProofs(uploadData);
      });

    Promise.all(uploadReqs)
      .then((datas) => {
        if (datas.filter((d) => !d.success).length === 0) {
          return LeadService.rekycGetLeadUploadStatus();
        } else {
          return new Promise((resolve) => {
            resolve({ success: false, datas });
          });
        }
      })
      .then((data) => {
        if (data.success) {
          history.push(getPath(data.next_step));
        } else {
          let failed = [],
            digio = [],
            showError = [];
          data.datas.forEach((data, i) => {
            if (!data.success) {
              if (data.digio_error) {
                digio.push(i);
              } else if (data.show_error) {
                showError.push({ i, error: data.error });
              } else {
                failed.push(i);
              }
            }
          });
          let uploadReqs = uploadBlocks.filter((d) => d.file && !d.fileURL);
          if (failed.length > 0) {
            failed.forEach((i) => {
              uploadReqs[i].isError = true;
              uploadReqs[i].errorMessage = strings.please_reupload;
            });
          }
          if (digio.length > 0) {
            digio.forEach((i) => {
              uploadReqs[i].isError = true;
              uploadReqs[i].errorMessage = strings.please_retry;
            });
          }
          if (showError.length > 0) {
            showError.forEach(({ i, error }) => {
              uploadReqs[i].isError = true;
              uploadReqs[i].errorMessage = error;
            });
          }

          uploadReqs.forEach((u) => {
            let u1 = uploadBlocks.find(
              (b) => b.document_code === u.document_code
            );
            if (u1) {
              u1.isError = u.isError;
              u1.errorMessage = u.errorMessage;
            }
          });
          setUploadBlocks(uploadBlocks);
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const isValid = () => {
    const invalidBlocks = uploadBlocks
      .filter((block) => block.is_required && !block.file && !block.fileURL)
      .map((b) => b.document_code);
    let ub = uploadBlocks;
    ub = ub.map((block) => ({
      ...block,
      isError: invalidBlocks.includes(block.document_code),
      errorMessage: strings.please_upload,
    }));
    setUploadBlocks(ub);
    return invalidBlocks.length === 0;
  };

  const nextClick = () => {
    if (isValid()) {
      save();
    }
  };

  const previousClick = () => {
    if (hasChanges) {
      showConfirmDialog(strings.confirm_msg, undefined, () => {
        closeConfirmDialog();
        history.push(getPath(step));
      });
    } else {
      history.push(getPath(step));
    }
  };
  const handleChange = (field, option) => {
    let ub = uploadBlocks;
    let block = ub.find((b) => b.document_code === field);
    block.selectedOption = option;
    block.isError = false;
    block.errorMessage = undefined;
    setUploadBlocks(ub);
    setHasChanges(true);
  };
  return (
    <LayoutWrapper>
      <div className="summarydiv">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-10 marg">
              <div className="forms ">
                <div className="row pb-2">
                  <div className="col-md-12 pb-3">
                    <h5 className="head4">{strings.uploadproof}</h5>
                  </div>
                  {description && (
                    <div className="col-md-12">
                      <div className="alert alert-danger" role="alert">
                        {description}
                      </div>
                    </div>
                  )}
                  {uploadBlocks.map((block, i) => {
                    const accept =
                      block.allowed_formats.includes("pdf") &&
                      block.allowed_formats.some(
                        (v) => ["png", "jpg", "jpeg"].indexOf(v) >= 0
                      )
                        ? "both"
                        : block.allowed_formats.includes("pdf")
                        ? "document"
                        : "image";
                    return (
                      <FileUpload
                        key={i}
                        maindivClass="col-md-4 pb-3 mb-3"
                        subDivClass=" files"
                        childDivClass={
                          block.is_dropdown
                            ? "pb-2"
                            : "uploadsdropdonpadding pb-2"
                        }
                        spanClass="info-btn"
                        iClass="fa fa-info-circle"
                        imgClass="info-img"
                        label={block.document_label || ""}
                        divClass="btn btn-default btn-file"
                        divid="files1"
                        uploadClass="upload-text"
                        uploadTitleClass="font-12"
                        selectClass="font-12"
                        imgSrc={block.preview_url || ""}
                        required={block.is_required || false}
                        link={block.download_url || ""}
                        isError={block.isError}
                        errorMessage={block.errorMessage || undefined}
                        linklabel={block.download_url ? "Download" : ""}
                        fileName={block.fileName}
                        fileURL={block.fileURL}
                        accept={accept}
                        allowedFormats={block.allowed_formats.join(", ")}
                        maxsize={block.max_size}
                        headView={block.is_dropdown ? "select" : "normal"}
                        options={
                          block.is_dropdown ? block.dropdown_options : undefined
                        }
                        selectedOption={
                          block.is_dropdown ? block.selectedOption : undefined
                        }
                        onChange={(file) => {
                          change(block.document_code, file);
                        }}
                        onSelectChange={(option) =>
                          handleChange(block.document_code, option)
                        }
                      />
                    );
                  })}
                  <div className="col-md-12">
                    <div className="alert alert-success text_left" role="alert">
                      {strings.unlock_file_note}&nbsp;
                      <a
                        href="https://smallpdf.com/unlock-pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        here
                      </a>
                    </div>
                  </div>
                  <div className="col-md-12 pt-5">
                    <div className="row">
                      <div className="col-6 pt-4">
                        {step && (
                          <Button
                            classes="inp-back"
                            label={strings.previous}
                            click={previousClick}
                          />
                        )}
                      </div>
                      <div className="col-6 pt-3 text-right">
                        <Button
                          classes="inp uploadbtn"
                          classes1="upbtn"
                          label={strings.nextbtn}
                          click={nextClick}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};
export default UploadProofs;
