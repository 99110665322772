import React from "react";
/**
 * Sign-In Button
 * @param {*} props 
 * @returns 
 */
function SignInButton(props) {
  const { label, classes,onclick } = props;
  return (
    <button className={classes} type="button" onClick={onclick}>
      {label}
    </button>
  );
}

export default SignInButton;
