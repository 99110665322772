import { isEmpty, isNil } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useForm } from "../../Component/Form";
import { useInputArray } from "../../Component/Form/useInputArray";

function SegmentSlab(props) {
  const {
    label,
    handleChange,
    mainclass,
    subclass,
    msg,
    type,
    labelClass,
    rupeclass,
    rupee,
    rupeelive,
    checkclass,
    checksubclass,
    name,
    index,
    parentNode,
    disableOnChange,
    disabled = false,
  } = props;

  const { control, watch, setValue } = useForm();
  const segmentFields = useInputArray(`${parentNode}[${index}].${name}`).fields;
  const [parentChecked, setParentChecked] = useState(
    segmentFields.filter((s) => s.checked).length > 0
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      setParentChecked(
        value?.segments[index]?.codes?.filter((s) => s.checked).length > 0 ||
          false
      );
    });
    return () => subscription.unsubscribe();
  }, [watch, index, parentNode]);

  const parentOnChange = (event) => {
    if (!disableOnChange) {
      if (!isEmpty(segmentFields)) {
        segmentFields.forEach((field, i) => {
          if (!disableOnChange) {
            if (handleChange) handleChange();
          }
          document.getElementsByName(
            `${parentNode}[${index}].${name}[${i}].checked`
          )[0].checked = event.target.checked;
          setValue(
            `${parentNode}[${index}].${name}[${i}].checked`,
            event.target.checked
          );
        });
      }
    } else {
      event.target.checked = !event.target.checked;
    }
  };

  return (
    <div className={mainclass}>
      <label className={subclass}>
        {label}
        <input type={type} onChange={parentOnChange} checked={parentChecked} />
        <span className={labelClass} />
        <h1> </h1>
        <p>{msg}</p>
        <div className={rupeclass}>
          {rupee &&
            (rupeelive ? (
              <>
                <strike>
                  <span>&#x20B9;</span>
                  {rupee}
                </strike>
                <br />
                &#x20B9; {rupeelive}
              </>
            ) : (
              <>&#x20B9; {rupee}</>
            ))}
        </div>
      </label>
      <div className={checkclass}>
        {segmentFields &&
          segmentFields.map((option, i) => (
            <span key={i}>
              <div className={checksubclass}>
                <label className="sub-add">
                  {option.label}
                  <Controller
                    name={`${parentNode}[${index}].${name}[${i}].checked`}
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        onChange={(event) => {
                          if (!disabled && !isNil(option.fixed)) {
                            field.onChange(event);
                            if (handleChange)
                              handleChange(event.target.checked);
                          } else {
                            event.target.checked = !event.target.checked;
                          }
                        }}
                        disabled={isNil(option.fixed) ? false : option.fixed}
                        type="checkbox"
                        id="checked"
                        defaultChecked={option.checked}
                      />
                    )}
                  />
                  <span className="geekmark"></span>
                </label>
              </div>
            </span>
          ))}
      </div>
    </div>
  );
}

export default SegmentSlab;
