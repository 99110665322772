import React, { useContext, useState, useRef, useEffect } from "react";
import { useHistory } from "react-router";
import { strings } from "../Common/String";
import Button from "../Component/Button";
import { LeadService } from "../Services/LeadService";
import Header from "../Header";
import TextBox from "../Component/TextField";
import { PATH } from "../Common/Path";
import Footer from "../footer";
import { AppContext } from "../Context/AppContext";

const Createpassword = (props) => {
  const { user, setToken, setLoading } = useContext(AppContext);
  const history = useHistory();
  const [formData, setFormData] = useState({});
  const [apiMessage, setApiMessage] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const passwordRef = useRef();

  useEffect(() => {
    passwordRef.current.focus();
  }, []);

  // useEffect(() => {
  //   if(!user || !user.mobile|| !user.email)
  //   {
  //     history.push("/");
  //   }

  // },[user])
  /**
   * Password visibility
   */
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const validatePassword = () => {
    let isValid = true;
    if (!formData.password) {
      setApiMessage(strings.mandatory);
      isValid = false;
    } else if (
      formData.password &&
      (formData.password.length < 4 || formData.password.length > 30)
    ) {
      setApiMessage(strings.passworderror);
      isValid = false;
    } else {
      setApiMessage("");
      isValid = true;
    }
    return isValid;
  };

  /**
   * password field onchange function and validation
   * @param {*} event
   */
  const handleChange = (event) => {
    // event.persist();
    setFormData((oldData) => ({
      ...oldData,
      [event.target.name]: event.target.value,
    }));
    setApiMessage("");
  };
  /**
   * password service call
   * @param {} event
   */
  const handleSubmit = (event) => {
    //event.preventDefault();

    if (validatePassword()) {
      setLoading(true);
      let body = {
        mobile: user.mobile,
        email: user.email,
        password: formData.password,
      };
      setApiMessage("");
      LeadService.requestSetPassword(body)
        .then((data) => {
          if (data.success) {
            setToken(data.token);
            if (data.has_previous_form) {
              history.replace(PATH.RELATIONSHIP_PAGE);
            } else {
              history.replace(PATH.VERIFICATIONDIGILOCK_PAGE);
            }
          } else {
            setApiMessage(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <>
      <Header />
      <div className="inner pb-4">
        <div className="container">
          <div className="row">
            <div className="col-md-50">
              <div className="images-add">
                <img
                  src={process.env.PUBLIC_URL + "/img/create-password.gif"}
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-50">
              <div className="s-box">
                <h1 className="text-center pb-2">{strings.password_header}</h1>
                <p className="text-center">{strings.password_subheader}</p>
                {/* <div className="inp"> */}
                <TextBox
                  reference={passwordRef}
                  mainclass="inp"
                  placehold={strings.enter_password}
                  inputclass="mb-2"
                  type={passwordShown ? "text" : "password"}
                  textId="password"
                  fieldValue={formData.password}
                  onTextChange={handleChange}
                  postfix={
                    <div className="edit-btn1 createpassword-tope">
                      <a href="# " onClick={togglePasswordVisiblity}>
                        {passwordShown && (
                          <img
                            src={process.env.PUBLIC_URL + "/img/eye.png"}
                            alt=""
                          />
                        )}
                        {!passwordShown && (
                          <img
                            src={process.env.PUBLIC_URL + "/img/eye-close.png"}
                            alt=""
                          />
                        )}
                      </a>
                    </div>
                  }
                />

                <div
                  id="txterror"
                  name="txterror"
                  className=" api-errormessage text-danger mh-25"
                >
                  {apiMessage}
                </div>
                <Button
                  classes="inp "
                  label={strings.continue}
                  inputType="text"
                  classes1="buttonmystyle"
                  click={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Createpassword;
