import React, { useContext, useEffect, useState } from "react";
import { strings } from "../../Common/String";
import { AppContext } from "../../Context/AppContext";
import { LeadService } from "../../Services/LeadService";
import LayoutWrapper from "../../Common/LayoutWrapper";

const Status = () => {
  const { setLoading } = useContext(AppContext);
  const [apiMessage, setApiMessage] = useState("");
  const [name, setName] = useState(null);
  const [steps, setSteps] = useState(null);

  useEffect(() => {
    setLoading(true);
    setApiMessage("");
    LeadService.closureGetLeadStatus()
      .then((data) => {
        if (data.success) {
          setName(data.name);
          setSteps(data.lead_status || []);
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <LayoutWrapper>
      <div className="summarydiv">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7 marg">
              <div className="forms">
                <div className="row pl-5 pb-2 status-pd">
                  <div className="col-md-12 pb-3">
                    <h5 className="head4 pb-2">
                      {strings.application_status_page_heading}
                    </h5>
                  </div>
                  <div className="col-md-12">
                    <p>
                      <b>
                        {strings.hi}
                        {name}
                        {strings.closure_application_status_page_text}
                      </b>
                    </p>
                    {steps && steps.length > 0 && (
                      <div className="ibox-content" id="ibox-content">
                        <div
                          id="vertical-timeline"
                          className="vertical-container dark-timeline "
                        >
                          {steps.map((step, index) => (
                            <RekycRow
                              key={index}
                              label={step.label}
                              status={step.status}
                            />
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-12 pt-3">
                    <div className="pb-3">{strings.closure_status}</div>
                    <div className="row">
                      <div className="text-center api-errormessage text-danger">
                        <label id="txterror" className="txterror">
                          {apiMessage}
                        </label>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

const RekycRow = ({ label, status }) => {
  if (status === 2) {
    return (
      <div className="vertical-timeline-block">
        <div className="vertical-timeline-icon navy-bg">
          <i className="fa fa-check" aria-hidden="true"></i>
        </div>
        <div className="vertical-timeline-content">
          <h2 className="head5">{label}</h2>
        </div>
      </div>
    );
  } else if (status === 1) {
    return (
      <div className="vertical-timeline-block">
        <div className="vertical-timeline-icon navy-bg"></div>
        <div className="vertical-timeline-content">
          <h2 className="head5">{label}</h2>
        </div>
      </div>
    );
  } else if (status === -1) {
    return (
      <div className="vertical-timeline-block">
        <div className="vertical-timeline-icon bg-warning">
          <i className="fa fa-times" aria-hidden="true"></i>
        </div>
        <div className="vertical-timeline-content">
          <h2 className="head5">{label}</h2>
        </div>
      </div>
    );
  } else {
    return (
      <div className="vertical-timeline-block">
        <div className="vertical-timeline-icon lazur-bg"></div>
        <div className="vertical-timeline-content not-act">
          <h2 className="head5">{label}</h2>
        </div>
      </div>
    );
  }
};

export default Status;
