import React, { useContext, useEffect, useState } from "react";
import { strings } from "../../Common/String";
import { AppContext } from "../../Context/AppContext";
import { LeadService } from "../../Services/LeadService";
import LayoutWrapper from "../../Common/LayoutWrapper";

const ThankYou = () => {
  const { setLoading, isLoading } = useContext(AppContext);
  const [apiMessage, setApiMessage] = useState("");
  const [data, setData] = useState(null);

  useEffect(() => {
    setLoading(true);
    setApiMessage("");
    LeadService.closureGetLeadStatus()
      .then((data) => {
        if (data.success) {
          setData(data);
        } else {
          setApiMessage(data.error);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <LayoutWrapper>
      {!isLoading ? (
        <div className="summarydiv" style={{ height: "100vh" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10 marg">
                <div className="forms ">
                  <div className="row pb-2">
                    <div className="col-md-12 pl-3 pt-5 pb-5 pr-3 pl-md-5 pr-md-5">
                      <div className="row">
                        <div className="col-md-2 text-center" />
                        <div className="col-md-8 bg-w text-center">
                          <p>
                            <img
                              src={process.env.PUBLIC_URL + "/img/check1.png"}
                              alt=""
                            />
                          </p>
                          <h6 className="pb-3 thank">
                            {strings.thankyou} {data && data.name}!
                          </h6>
                          <p className="pt-1 mb-0 thankp">
                            {data && data.summary}
                          </p>
                          <br />
                          {data && data.feedback_url ? (
                            <div className=" pt-3 mb-0 thankp">
                              <h6 className="text-md">
                                {strings.closure_thankyou_feedback}
                              </h6>
                            </div>
                          ) : null}
                          <div className="col-md-12">
                            <div className="row">
                              <div className="col-md-2 pt-4" />
                              {data && data.feedback_url ? (
                                <div className="col-md-8 pt-3 text-center">
                                  <div className="inp ">
                                    <a
                                      href={data.feedback_url}
                                      style={{ textDecoration: "none" }}
                                      target="blank"
                                    >
                                      {strings.share_feedback}
                                    </a>
                                    <br></br>
                                    <br></br>
                                  </div>
                                </div>
                              ) : null}
                              <div className="col-md-2 pt-4" />
                              <div className="col-md-2 pt-4" />
                              <div className="col-md-3 pt-3 d-none">
                                <div className="text-danger text-center">
                                  <label id="txterror" className="txterror">
                                    {apiMessage}
                                  </label>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 text-center" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </LayoutWrapper>
  );
};
export default ThankYou;
