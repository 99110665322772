
import React, { useEffect } from "react";
import { strings } from "../../Common/String";

/**
 * Showing Counter time
 * @param {*} props 
 * @returns 
 */
function Count(props) {
  const [seconds, setSeconds] = React.useState(30);
  const { onFinish, restart } = props

  useEffect(() => {
    let timer = null
    if (seconds > 0) {
      timer = setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
      onFinish();
    }
    return () => {
      clearTimeout(timer)
    }
  }, [seconds]);

  useEffect(() => {
    if (restart > 0) {
      setSeconds(30);
    }
  }, [restart])

  return (
    <div className="App">
      <section> {strings.resendin} <b>{seconds}</b> {strings.seconds}</section>
    </div>
  );
}

export default Count;
