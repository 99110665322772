import { throttle } from "lodash";
import React, { useMemo, useState } from "react";
import { useForm } from ".";
import { MasterService } from "../../Services/MasterService";
import { Input } from "./Input";

const PincodeBox = (props) => {
  const {
    label,
    placeholder,
    classes,
    textMaxLength = 6,
    disabled,
    mainclass,
    inputclass,
    errorclass,
    tabIndex,
    name,
    readOnly: isReadOnly,
    required = false,
    index = -1,
    parentNode = "",
    stateName,
    cityName,
  } = props;
  const [ddVisible, setDDVisible] = useState(false);
  const [options, setOptions] = useState([]);

  const { setValue } = useForm();

  const onChange = (value) => {
    if (!textMaxLength) {
      pinHandleChange(value);
      setDDVisible(false);
    } else if (value.length <= parseInt(textMaxLength)) {
      pinHandleChange(value);
      setDDVisible(true);
    }
    let o = options.find((i) => i.pincode === parseInt(value));
    if (o) {
      optionClick(o);
      setDDVisible(false);
    }
  };

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        MasterService.getPincodeList(request.input).then((data) =>
          callback(data)
        );
      }, 200),
    []
  );

  const pinHandleChange = (value) => {
    let active = true;
    fetch({ input: value }, (results) => {
      if (active) {
        let newOptions = [];
        // if (value) {
        //   newOptions = [value];
        // }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
        let o = newOptions.find((i) => i.pincode === parseInt(value));
        if (o) {
          optionClick(o);
          setDDVisible(false);
        }
      }
    });
  };

  const optionClick = (location) => {
    setValue(index !== -1 ? `${parentNode}[${index}].${cityName}` : cityName, location.city);
    setValue(index !== -1 ? `${parentNode}[${index}].${stateName}` : stateName, location.state);
  };

  return (
    <div className={mainclass}>
      <div className={classes}>
        <Input
          tabIndex={tabIndex}
          classes="material-textfield"
          placeholder={placeholder}
          inputClass={inputclass}
          type="number"
          name={name}
          onChange={onChange}
          disabled={disabled}
          label={label}
          labelClass="lblz"
          required={required}
          readOnly={isReadOnly}
          index={index}
          maxLength={6}
          parentNode={parentNode}
          errorclass={errorclass}
          list="options"
        />
        {ddVisible ? (
          <datalist id="options">
            {options.map((item, index) => (
              <option
                className="list-group-item"
                key={index}
                value={item.pincode}
              >
                {item.pincode}
              </option>
            ))}
          </datalist>
        ) : null}
      </div>
    </div>
  );
};

export default PincodeBox;
