import { uniqueId } from "lodash";
import React from "react";
import ReactTooltip from "react-tooltip";
/**
 * Edit icon
 * @param {*} props
 * @returns
 */
function IconButton(props) {
  const {
    id = uniqueId("icon-"),
    click,
    classes,
    imgsrc,
    imgclass,
    tooltip,
  } = props;
  return (
    <>
      {tooltip ? (
        <>
          <div data-for={id} data-tip={tooltip} className={classes}>
            <img src={imgsrc} alt="" onClick={click} className={imgclass} />
          </div>
          <ReactTooltip id={id} className="tooltip-text" effect="solid" />
        </>
      ) : (
        <div className={classes}>
          <img src={imgsrc} alt="" onClick={click} className={imgclass} />
        </div>
      )}
    </>
  );
}

export default IconButton;
