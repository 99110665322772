import { useEffect } from "react";
import { useState } from "react";
import { createContext } from "react";
import { MasterService } from "../../Services/MasterService";

const SummaryContext = createContext();

const SummaryProvider = ({ children }) => {
  const [masterRelationships, setMasterRelationships] = useState([]);
  const [nomineeRelationships, setNomineeRelationships] = useState([]);

  useEffect(() => {
    MasterService.getDropdown("relationship")
      .then((data) => {
        if (data && data.relationship) {
          setMasterRelationships(data.relationship);
          return Promise.resolve(data.relationship);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    MasterService.getDropdown("nominee_relationship")
      .then((data) => {
        if (data && data.nominee_relationship) {
          setNomineeRelationships(data.nominee_relationship);
          return Promise.resolve(data.nominee_relationship);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <SummaryContext.Provider
      value={{
        masterRelationships,
        nomineeRelationships,
      }}
    >
      {children}
    </SummaryContext.Provider>
  );
};

export { SummaryContext, SummaryProvider };
