import { PATH } from "./Path";
import steps from "./steps";

export const getPath = (stepId) => {
  let redirect = PATH.MOBILE_PAGE;
  switch (stepId) {
    case "Dependency":
      redirect = PATH.RELATIONSHIP_PAGE;
      break;
    case "AadhaarDigilocker":
      redirect = PATH.VERIFICATIONDIGILOCK_PAGE;
      break;
    case "PANVerification":
      redirect = PATH.VERIFICATIONPAN_PAGE;
      break;
    case "BasicPersonal":
      redirect = PATH.PERSONALBASIC_PAGE;
      break;
    case "AddressPersonal":
      redirect = PATH.PERSONALADDRESS_PAGE;
      break;
    case "NomineePersonal":
      redirect = PATH.PERSONALNOMINEE_PAGE;
      break;
    case "OtherPersonal":
      redirect = PATH.PERSONALOTHER_PAGE;
      break;
    case "TNC":
      redirect = PATH.TERMSANSCONDITION_PAGE;
      break;
    case "Bank":
      redirect = PATH.BANKACCOUNT_PAGE;
      break;
    case "Segment":
      redirect = PATH.SEGMENTS_PAGE;
      break;
    case "Payment":
      redirect = PATH.PAYMENT_PAGE;
      break;
    case "IPV":
      redirect = PATH.INPERSON_PAGE;
      break;
    case "UploadProof":
      redirect = PATH.UPLOADPROOFS_PAGE;
      break;
    case "ESign":
      redirect = PATH.ESIGN_PAGE;
      break;
    case "ThankYou":
      redirect = PATH.THANKYOU_PAGE;
      break;
    case "End":
      redirect = PATH.LEADSTATUS_PAGE;
      break;
    // -------------------------------------- ReKYC --------------------------------------
    case "ReKYCSummary":
      redirect = PATH.REKYC_SUMMARY_PAGE;
      break;
    case "ReKYCUploadProof":
      redirect = PATH.REKYC_UPLOAD_PAGE;
      break;
    case "ReKYCIPV":
      redirect = PATH.REKYC_IPV_PAGE;
      break;
    case "ReKYCESign":
      redirect = PATH.REKYC_ESIGN_PAGE;
      break;
    case "ReKYCThankYou":
      redirect = PATH.REKYC_THANKYOU_PAGE;
      break;
    case "ReKYCEnd":
      redirect = PATH.REKYC_STATUS_PAGE;
      break;
    // -------------------------------------- Closure --------------------------------------
    case "ClosureSummary":
      redirect = PATH.CLOSURE_SUMMARY_PAGE;
      break;
    case "ClosureUploadProof":
      redirect = PATH.CLOSURE_UPLOAD_PAGE;
      break;
    case "ClosureESign":
      redirect = PATH.CLOSURE_ESIGN_PAGE;
      break;
    case "ClosureThankYou":
      redirect = PATH.CLOSURE_THANKYOU_PAGE;
      break;
    case "ClosureEnd":
      redirect = PATH.CLOSURE_STATUS_PAGE;
      break;
    default:
      break;
  }
  return redirect;
};

export const getCurrentStep = (stepId) => {
  let currentStep = steps[0].id;
  switch (stepId) {
    case "AadhaarDigilocker":
      currentStep = steps[2].id;
      break;
    case "PANVerification":
      currentStep = steps[2].id;
      break;
    case "BasicPersonal":
      currentStep = steps[3].id;
      break;
    case "AddressPersonal":
      currentStep = steps[3].id;
      break;
    case "NomineePersonal":
      currentStep = steps[3].id;
      break;
    case "OtherPersonal":
      currentStep = steps[3].id;
      break;
    case "TNC":
      currentStep = steps[3].id;
      break;
    case "Bank":
      currentStep = steps[4].id;
      break;
    case "Segment":
      currentStep = steps[5].id;
      break;
    case "Payment":
      currentStep = steps[5].id;
      break;
    case "IPV":
      currentStep = steps[6].id;
      break;
    case "UploadProof":
      currentStep = steps[7].id;
      break;
    case "ESign":
      currentStep = steps[8].id;
      break;
    default:
      break;
  }
  return currentStep;
};
export function linkPreventCaching(link = undefined) {
  if (!link) {
    return undefined;
  }
  return link.includes("?")
    ? `${link}&${new Date().getTime()}`
    : `${link}?${new Date().getTime()}`;
}

export async function copyTextToClipboard(text) {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand("copy", true, text);
  }
}

export const getFullName = (firstname, middlename, lastname) => {
  const name = [];
  if (firstname) name.push(firstname);
  if (middlename) name.push(middlename);
  if (lastname) name.push(lastname);
  return name.join(" ");
};

export const mask = (string, mask = "*", first = 2, last = 2) => {
  if (!string || string.length < first + last) return string;
  return (
    string.slice(0, first) +
    string.slice(first, -last).replace(/./g, mask) +
    string.slice(-last)
  );
};

export const maskEmail = (string, mask = "*", first = 2, last = 2) => {
  if (!string || string.length < first + last) return string;
  const split = string.split("@");
  return (
    split[0].slice(0, first) +
    // split[0].slice(first, 6).replace(/./g, mask) +
    mask.repeat(6) +
    split[0].slice(-last) +
    "@" +
    split[1]
  );
};
