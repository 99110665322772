import React, { useContext, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { AppContext } from "../Context/AppContext";
import { strings } from "../Common/String";
import Button from "../Component/Button";
import { LeadService } from "../Services/LeadService";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "../footer";
import Header2 from "../HeaderStatus";
import TextBox from "../Component/TextField";
import DropDown from "../Component/DropDown";
import RadioButtonGroup from "../Component/RadioButtonGroup";
import RadioGroupOptions from "../Common/RadioGroupOptions";
import { MasterService } from "../Services/MasterService";
import { PATH } from "../Common/Path";
import { getCurrentStep, getPath } from "../Common/utils";
import PincodeBox from "../Component/PincodeBoxV2";
import throttle from "lodash/throttle";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import moment from "moment";
import { format_date_only, format_without_time } from "../Common/Constants";
import validator from "validator";

const PersonalDetailsNominee = (props) => {
  const {
    steps,
    setSteps,
    setLoading,
    currentStep,
    setCurrentStep,
    hasChanges,
    setHasChanges,
    showConfirmDialog,
    closeConfirmDialog,
  } = useContext(AppContext);
  const [errors, setErrors] = useState([]);
  const [fieldValidationError, setfieldValidationError] = useState(false);
  const history = useHistory();
  const [apiMessage, setApiMessage] = useState(" ");
  const [visibleApiMesg, setVisibleApiMesg] = useState(false);
  const [relationshipOptions, setRelationShipOptions] = useState([]);
  const [disabledFields, setDisabledFields] = useState([]);
  const [nomineeList, setNomineeList] = useState([]);
  const [hasNominee, setHasNominee] = useState(false);
  const [maxAllowedNominees, setMaxAllowedNominees] = useState(3);
  const [options, setOptions] = React.useState([]);
  const dobRef = useRef();
  const [count, setCount] = useState(1);
  const years = range(1900, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    setLoading(true);
    setHasChanges(false);
    setLoading(true);
    Promise.all([
      LeadService.fillPersonalDetails("nominee"),
      MasterService.getDropdown("nominee_relationship"),
    ])
      .then((datas) => {
        if (datas[1] && datas[1].nominee_relationship) {
          setRelationShipOptions(datas[1].nominee_relationship);
        }

        if (datas[0]) {
          let data = datas[0];
          setCurrentStep(getCurrentStep(data.current_step));
          if (data.success) {
            // Set the nominees
            let nL = [];
            let eL = [];
            if (
              data.max_allowed_nominees !== null ||
              data.max_allowed_nominees !== undefined
            ) {
              setMaxAllowedNominees(data.max_allowed_nominees);
            }
            setHasNominee(data.nominee);
            if (data.nominee) {
              data.nominees.forEach((nominee) => {
                nL.push({
                  name: nominee.name,
                  mobile: nominee.mobile,
                  email: nominee.email,
                  share: nominee.share,
                  relationship: nominee.relationship,
                  sameAsPermanent: nominee.nominee_addr_same,
                  add1: nominee.addr1,
                  add2: nominee.addr2,
                  add3: nominee.addr3,
                  city: nominee.city,
                  state: nominee.state,
                  pincode: nominee.pincode,
                  country: nominee.country,
                  major: nominee.major,
                  dob: nominee.dob
                    ? moment(nominee.dob, format_without_time)._d
                    : undefined,
                  guardianName: nominee.guardian_name,
                  guardianMobile: nominee.guardian_mobile,
                  guardianEmail: nominee.guardian_email,
                  guardianRelationship: nominee.guardian_relationship,
                  guardianSameAsPermanent: nominee.guardian_addr_same,
                  guardianAdd1: nominee.guardian_addr1,
                  guardianAdd2: nominee.guardian_addr2,
                  guardianAdd3: nominee.guardian_addr3,
                  guardianCity: nominee.guardian_city,
                  guardianState: nominee.guardian_state,
                  guardianPincode: nominee.guardian_pincode,
                  guardianCountry: nominee.guardian_country,
                });
                eL.push({
                  name: false,
                  mobile: false,
                  email: false,
                  share: false,
                  relationship: false,
                  add1: false,
                  add2: false,
                  add3: false,
                  city: false,
                  state: false,
                  pincode: false,
                  dob: false,
                  guardianName: false,
                  guardianMobile: false,
                  guardianEmail: false,
                  guardianRelationship: false,
                  guardianAdd1: false,
                  guardianAdd2: false,
                  guardianAdd3: false,
                  guardianCity: false,
                  guardianState: false,
                  guardianPincode: false,
                });
              });
            }
            setErrors(eL);
            setNomineeList(nL);
            setDisabledFields(data.disabled_fields || []);
          }
        }
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [true]);

  useEffect(() => {
    steps.forEach((step) => {
      if (step.id === currentStep) {
        step.status = 1;
      } else if (step.id < currentStep) {
        step.status = 2;
      }
    });
    setSteps(steps);
  }, [currentStep]);

  const addNominee = () => {
    setNomineeList([
      ...nomineeList,
      {
        name: "",
        mobile: "",
        email: "",
        share: 0,
        relationship:
          relationshipOptions && relationshipOptions.length > 0
            ? defaultRelationship()
            : "Father",
        sameAsPermanent: false,
        add1: "",
        add2: "",
        add3: "",
        city: "",
        state: "",
        pincode: "",
        country: "India",
        major: true,
        dob: undefined,
        guardianName: undefined,
        guardianMobile: undefined,
        guardianEmail: undefined,
        guardianRelationship:
          relationshipOptions && relationshipOptions.length > 0
            ? defaultRelationship()
            : "Father",
        guardianSameAsPermanent: false,
        guardianAdd1: "",
        guardianAdd2: "",
        guardianAdd3: "",
        guardianCity: "",
        guardianState: "",
        guardianPincode: "",
        guardianCountry: "India",
      },
    ]);
    setErrors([
      ...errors,
      {
        name: false,
        mobile: false,
        email: false,
        share: false,
        relationship: false,
        add1: false,
        add2: false,
        add3: false,
        city: false,
        state: false,
        pincode: false,
        dob: false,
        guardianName: false,
        guardianMobile: false,
        guardianEmail: false,
        guardianRelationship: false,
        guardianAdd1: false,
        guardianAdd2: false,
        guardianAdd3: false,
        guardianCity: false,
        guardianState: false,
        guardianPincode: false,
      },
    ]);
  };

  /**
   * validation for final submit
   */
  const isValid = () => {
    let valid = true;

    if (hasNominee && nomineeList.length === 0) {
      valid = false;
      return valid;
    }
    let eL = errors;
    let totalShare = nomineeList.reduce(
      (sum, next) => sum + parseFloat(next.share || 0),
      0
    );
    nomineeList.forEach((nominee, index) => {
      if (!nominee.name || nominee.name.trim().length === 0) {
        valid = false;
        eL[index].name = true;
      } else {
        eL[index].name = false;
      }

      if (!nominee.mobile || nominee.mobile.trim().length !== 10) {
        valid = false;
        eL[index].mobile = true;
      } else {
        eL[index].mobile = false;
      }

      if (
        !nominee.email ||
        nominee.email.trim().length === 0 ||
        !validator.isEmail(nominee.email)
      ) {
        valid = false;
        eL[index].email = true;
      } else {
        eL[index].email = false;
      }

      if (totalShare !== 100 || nominee.share > 100 || nominee.share <= 0) {
        valid = false;
        eL[index].share = true;
        if (totalShare !== 100) {
          eL[index].shareErrorMessage = strings.total_share_mimatch;
        } else if (nominee.share > 100) {
          eL[index].shareErrorMessage = strings.share_greater_than_100;
        } else if (nominee.share <= 0) {
          eL[index].shareErrorMessage = strings.share_less_than_0;
        }
      } else {
        eL[index].shareErrorMessage = "";
        eL[index].share = false;
      }

      if (!nominee.relationship || nominee.relationship.trim().length === 0) {
        valid = false;
        eL[index].relationship = true;
      } else {
        eL[index].relationship = false;
      }

      if (!nominee.sameAsPermanent) {
        if (!nominee.add1 || nominee.add1.trim().length === 0) {
          valid = false;
          eL[index].add1 = true;
        } else {
          eL[index].add1 = false;
        }
        if (!nominee.add2 || nominee.add2.trim().length === 0) {
          valid = false;
          eL[index].add2 = true;
        } else {
          eL[index].add2 = false;
        }
        if (!nominee.add3 || nominee.add3.trim().length === 0) {
          valid = false;
          eL[index].add3 = true;
        } else {
          eL[index].add3 = false;
        }
        if (!nominee.city || nominee.city.trim().length === 0) {
          valid = false;
          eL[index].city = true;
        } else {
          eL[index].city = false;
        }
        if (!nominee.state || nominee.state.trim().length === 0) {
          valid = false;
          eL[index].state = true;
        } else {
          eL[index].state = false;
        }
        if (!nominee.pincode || nominee.pincode.length === 0) {
          valid = false;
          eL[index].pincode = true;
        } else {
          eL[index].pincode = false;
        }
      }

      if (!nominee.major) {
        if (!nominee.dob || moment().diff(moment(nominee.dob), "years") > 18) {
          valid = false;
          eL[index].dob = true;
          eL[index].dobErrorMessage =
            moment().diff(moment(nominee.dob), "years") > 18
              ? strings.major_error_message
              : strings.mandatory;
        } else {
          eL[index].dob = false;
          eL[index].dobErrorMessage = "";
        }
        if (!nominee.guardianName || nominee.guardianName.trim().length === 0) {
          valid = false;
          eL[index].guardianName = true;
        } else {
          eL[index].guardianName = false;
        }
        if (
          !nominee.guardianMobile ||
          nominee.guardianMobile.trim().length !== 10
        ) {
          valid = false;
          eL[index].guardianMobile = true;
        } else {
          eL[index].guardianMobile = false;
        }
        if (
          !nominee.guardianEmail ||
          nominee.guardianEmail.trim().length === 0 ||
          !validator.isEmail(nominee.guardianEmail)
        ) {
          valid = false;
          eL[index].guardianEmail = true;
        } else {
          eL[index].guardianEmail = false;
        }
        if (
          !nominee.guardianRelationship ||
          nominee.guardianRelationship.trim().length === 0
        ) {
          valid = false;
          eL[index].guardianRelationship = true;
        } else {
          eL[index].guardianRelationship = false;
        }

        if (!nominee.guardianSameAsPermanent) {
          if (
            !nominee.guardianAdd1 ||
            nominee.guardianAdd1.trim().length === 0
          ) {
            valid = false;
            eL[index].guardianAdd1 = true;
          } else {
            eL[index].guardianAdd1 = false;
          }
          if (
            !nominee.guardianAdd2 ||
            nominee.guardianAdd2.trim().length === 0
          ) {
            valid = false;
            eL[index].guardianAdd2 = true;
          } else {
            eL[index].guardianAdd2 = false;
          }
          if (
            !nominee.guardianAdd3 ||
            nominee.guardianAdd3.trim().length === 0
          ) {
            valid = false;
            eL[index].guardianAdd3 = true;
          } else {
            eL[index].guardianAdd3 = false;
          }
          if (
            !nominee.guardianCity ||
            nominee.guardianCity.trim().length === 0
          ) {
            valid = false;
            eL[index].guardianCity = true;
          } else {
            eL[index].guardianCity = false;
          }
          if (
            !nominee.guardianState ||
            nominee.guardianState.trim().length === 0
          ) {
            valid = false;
            eL[index].guardianState = true;
          } else {
            eL[index].guardianState = false;
          }
          if (
            !nominee.guardianPincode ||
            nominee.guardianPincode.length === 0
          ) {
            valid = false;
            eL[index].guardianPincode = true;
          } else {
            eL[index].guardianPincode = false;
          }
        }
      }
    });
    setErrors(eL);
    if (hasNominee && totalShare !== 100) {
      showConfirmDialog(
        strings.total_share_mimatch_alert,
        undefined,
        undefined,
        strings.ok
      );
    }
    setCount((count) => count + 1);
    return valid;
  };

  /**
   * button click submitt
   */
  const nextClick = () => {
    if (isValid()) {
      setLoading(true);
      let body = {
        type: "nominee",
        details: {
          nominee: hasNominee,
          nominees: hasNominee
            ? nomineeList.map((nominee) => {
              let n = {
                name: nominee.name,
                mobile: nominee.mobile,
                email: nominee.email,
                share: nominee.share,
                relationship: nominee.relationship,
                nominee_addr_same: nominee.sameAsPermanent,
                addr1: nominee.add1,
                addr2: nominee.add2,
                addr3: nominee.add3,
                city: nominee.city,
                state: nominee.state,
                country: "India",
                pincode: nominee.pincode,
                major: nominee.major,
              };

              if (!nominee.major) {
                n.dob = moment(nominee.dob).format(format_without_time);
                n.guardian_name = nominee.guardianName;
                n.guardian_mobile = nominee.guardianMobile;
                n.guardian_email = nominee.guardianEmail;
                n.guardian_relationship = nominee.guardianRelationship;
                n.guardian_addr1 = nominee.guardianAdd1;
                n.guardian_addr2 = nominee.guardianAdd2;
                n.guardian_addr3 = nominee.guardianAdd3;
                n.guardian_city = nominee.guardianCity;
                n.guardian_state = nominee.guardianState;
                n.guardian_country = "India";
                n.guardian_pincode = nominee.guardianPincode;
                n.guardian_addr_same = nominee.guardianSameAsPermanent;
              }
              return n;
            })
            : [],
        },
      };
      LeadService.submitBasicDetails(body)
        .then((data) => {
          if (data.success) {
            history.push(getPath(data.next_step));
          } else {
            setVisibleApiMesg(true);
            setApiMessage(data.error);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    } else {
      expandAllSections();
      setfieldValidationError(true);
    }
  };

  /**
   * onchange function for has nominee field
   * @param {} event
   */
  const handleChangeNominee = (event) => {
    if (event.target.name === "has_nominee" && event.target.value === RadioGroupOptions.ADD_NOMINEE_RECOMMENDED[0]) {
      setHasNominee(true);
      if (nomineeList.length === 0) {
        addNominee();
      }
    } else {
      setHasNominee(false);
      setNomineeList([]);
    }
  };

  /**
   * onchange function of all fields
   * @param {} event
   */
  const handleChange = (index) => (event) => {
    event.persist();
    let nL = [...nomineeList];
    let eL = [...errors];
    let n = nL[index];
    let e = eL[index];
    if (event.target.name.includes("major")) {
      n.major = event.target.value === "Yes";
      if (!n.guardianRelationship) {
        n.guardianRelationship =
          relationshipOptions && relationshipOptions.length > 0
            ? defaultRelationship()
            : "Father";
      }
    } else {
      n[event.target.name] = event.target.value;
      e[event.target.name] = false;
    }
    setErrors(eL);
    setNomineeList(nL);
    setHasChanges(true);
  };

  const defaultRelationship = () => {
    let option = relationshipOptions.find((r) => r.is_default);
    return option ? option.value : relationshipOptions[0].value;
  };

  const calculateShare = (index = 0) => {
    let nL = [...nomineeList];

    if (nL.length === 0) {
      return;
    }
    if (nL.length === 1) {
      nL[0].share = 100;
    }
    if (nL.length === 2 && nL[index].share <= 100) {
      if (index === 0) {
        nL[1].share = 100 - nL[0].share;
      } else {
        nL[0].share = 100 - nL[1].share;
      }
    }

    if (nL.length > 2) {
      let totalShare = nL.reduce(
        (sum, next) => sum + parseFloat(next.share || 0),
        0
      );
      if (totalShare !== 100) {
        let eL = [...errors];
        eL.forEach((e) => (e.share = true));
        setErrors(eL);
      } else {
        let eL = [...errors];
        eL.forEach((e) => (e.share = false));
        setErrors(eL);
      }
    }

    setNomineeList(nL);
    setHasChanges(true);
  };

  useEffect(() => {
    if (nomineeList.length > 0) {
      calculateShare();
    }
  }, [nomineeList.length]);

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        MasterService.getPincodeList(request.input).then((data) =>
          callback(data)
        );
      }, 200),
    []
  );
  const pincodeSelect = (index, field, location) => {
    let nL = [...nomineeList];
    let n = nL[index];
    if (field === "pincode") {
      n.pincode = location.pincode;
      n.city = location.city;
      n.state = location.state;
    } else {
      n.guardianPincode = location.pincode;
      n.guardianCity = location.city;
      n.guardianState = location.state;
    }
    setNomineeList(nL);
    setOptions([]);
  };

  const pinHandleChange = (index) => (event) => {
    let active = true;
    fetch({ input: event.target.value }, (results) => {
      if (active) {
        let newOptions = [];
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });
    let nL = [...nomineeList];
    let eL = [...errors];
    let n = nL[index];
    let e = eL[index];
    n[event.target.name] = event.target.value;
    if (event.target.name === "guardianPincode") {
      e[event.target.name] = false;
      e.guardianCity = false;
      e.guardianState = false;
    } else {
      e[event.target.name] = false;
      e.city = false;
      e.state = false;
    }
    setNomineeList(nL);
    setHasChanges(true);
  };

  const handleCheckBoxChange = (index) => (event) => {
    event.persist();
    let nL = [...nomineeList];
    let n = nL[index];
    n[event.target.name] = event.target.checked;
    setNomineeList(nL);
    setHasChanges(true);
  };

  /**
   * datepicker onclick
   * @param {*} value
   */
  const handleChangeDate = (index, value) => {
    let nL = [...nomineeList];
    let eL = [...errors];
    let n = nL[index];
    let e = eL[index];
    n.dob = value;
    e.dob = false;
    setNomineeList(nL);
    setErrors(eL);
    setHasChanges(true);
  };

  const expandAllSections = () => {
    let parents = document.getElementsByClassName("accordion");
    for (var i = 0; i < parents.length; i++) {
      const parent = parents[i];
      parent.classList.toggle("active");
      var panel = parent.nextElementSibling;
      let arrowL = parent.getElementsByTagName("i");
      let name = parent.getElementsByClassName("nom-name")[0];
      let share = parent.getElementsByClassName("nom-share")[0];
      let arrow = arrowL[arrowL.length - 1];
      if (panel.style.display !== "block" || panel.style.display !== "") {
        panel.style.display = "block";
        arrow.classList.add("fa-chevron-up");
        arrow.classList.remove("fa-chevron-down");
        name.classList.add("d-none");
        share.classList.add("d-none");
      }
    }
  };

  const toggleNominee = (e) => {
    let parent = e.target.closest(".accordion");
    parent.classList.toggle("active");
    var panel = parent.nextElementSibling;
    let arrowL = parent.getElementsByTagName("i");
    let name = parent.getElementsByClassName("nom-name")[0];
    let share = parent.getElementsByClassName("nom-share")[0];
    let arrow = arrowL[arrowL.length - 1];
    if (panel.style.display === "block" || panel.style.display === "") {
      panel.style.display = "none";
      arrow.classList.add("fa-chevron-down");
      arrow.classList.remove("fa-chevron-up");
      name.classList.remove("d-none");
      share.classList.remove("d-none");
    } else {
      panel.style.display = "block";
      arrow.classList.add("fa-chevron-up");
      arrow.classList.remove("fa-chevron-down");
      name.classList.add("d-none");
      share.classList.add("d-none");
    }
  };

  const removeNominee = (index) => (e) => {
    e.stopPropagation();
    if (nomineeList.length > 1) {
      showConfirmDialog(
        strings.confirm_remove_nominee_msg,
        undefined,
        () => {
          closeConfirmDialog();
          let nL = [...nomineeList];
          let eL = [...errors];
          nL.splice(index, 1);
          eL.splice(index, 1);
          setNomineeList(nL);
          setErrors(eL);
        },
        strings.yes,
        strings.no
      );
    }
  };

  const prevClick = () => {
    if (hasChanges) {
      showConfirmDialog(strings.confirm_msg, undefined, () => {
        closeConfirmDialog();
        history.push(PATH.PERSONALADDRESS_PAGE);
      });
    } else {
      history.push(PATH.PERSONALADDRESS_PAGE);
    }
  };

  return (
    <div className="inner-section">
      <div className="details">
        <div className="container-fluid">
          <div className="row">
            <Header2 />
            <div className="col-md-9 pl-5 pt-55 pb-5 pr-5">
              <div
                className="row"
                style={{ alignContent: "end" }}
              >
                <div className="col-md-12">
                  <h5 className="pb-2">{strings.nomiee_subhead}</h5>
                  {/* <p className="sub-head pb-2">{strings.nomiee_subhead}</p> */}
                </div>
                <div className="col-md-12 pl-0">
                  <RadioButtonGroup
                    tabIndex="0"
                    mainclassName="col-md-5 pb-3 pl-3"
                    label={strings.add_nominee_label}
                    options={RadioGroupOptions.ADD_NOMINEE_RECOMMENDED}
                    name="has_nominee"
                    option="has_nominee"
                    onOptionChange={handleChangeNominee}
                    errorMessage={strings.mandatory}
                    errorclass="text-danger errorfont mh-18"
                    selected={
                      hasNominee
                        ? RadioGroupOptions.ADD_NOMINEE_RECOMMENDED[0]
                        : RadioGroupOptions.ADD_NOMINEE_RECOMMENDED[1]
                    }
                    disabled={false}
                  />
                </div>
                {count &&
                  nomineeList &&
                  nomineeList.map((nominee, index) => {
                    let tabIndex = index * 30;
                    return (
                      <div key={index} className="col-md-12 pb-3">
                        <button className="accordion" onClick={toggleNominee}>
                          <span className="f-1">Nominee {index + 1}</span>
                          <span className="nom-name f-1 d-none">
                            {nominee.name}
                          </span>
                          <span className="nom-share pr-3 d-none">
                            Share: {nominee.share || 0}%
                          </span>
                          <i
                            className={`fa fa-trash-o pr-4 mt-1 ${index !== 0 ? "" : " vh"
                              }`}
                            aria-hidden="true"
                            onClick={removeNominee(index)}
                          ></i>
                          <i
                            className="fa fa-chevron-up mt-1"
                            aria-hidden="true"
                          ></i>
                        </button>
                        <div className="panel">
                          <div className="row">
                            <div className="col-md-4">
                              <TextBox
                                tabIndex={tabIndex + 1}
                                mainclassName="pb-3"
                                label={strings.nominee_name}
                                placehold={strings.nominee_name}
                                classes="inuput-box"
                                inputclassName="mb-0"
                                type="text"
                                textId="name"
                                disabled={
                                  disabledFields.includes("name") || false
                                }
                                fieldValue={nominee.name || ""}
                                onTextChange={handleChange(index)}
                                error={errors[index].name}
                                errorclass="text-danger errorfont mh-18"
                                errorMessage={strings.mandatory}
                              />
                            </div>
                            <div className="col-md-4">
                              <TextBox
                                tabIndex={tabIndex + 2}
                                mainclassName="pb-3"
                                label={strings.nominee_mobile_no}
                                placehold={strings.nominee_mobile_no}
                                classes="inuput-box"
                                inputclassName="mb-0"
                                type="number"
                                textId="mobile"
                                textMaxLength={10}
                                disabled={
                                  disabledFields.includes("mobile") || false
                                }
                                fieldValue={nominee.mobile || ""}
                                onTextChange={handleChange(index)}
                                error={errors[index].mobile}
                                errorclass="text-danger errorfont mh-18"
                                errorMessage={strings.mandatory}
                              />
                            </div>
                            <div className="col-md-4">
                              <TextBox
                                tabIndex={tabIndex + 3}
                                mainclassName="pb-3"
                                label={strings.nominee_email}
                                placehold={strings.nominee_email}
                                classes="inuput-box"
                                inputclassName="mb-0"
                                type="text"
                                textId="email"
                                disabled={
                                  disabledFields.includes("email") || false
                                }
                                fieldValue={nominee.email || ""}
                                onTextChange={handleChange(index)}
                                error={errors[index].email}
                                errorclass="text-danger errorfont mh-18"
                                errorMessage={strings.mandatory}
                              />
                            </div>
                            <div className="col-md-4">
                              <TextBox
                                tabIndex={tabIndex + 4}
                                mainclassName="pb-3"
                                label={strings.nominee_percentage_share}
                                placehold={strings.nominee_percentage_share}
                                classes="inuput-box"
                                inputclassName="mb-0"
                                type="text"
                                textId="share"
                                disabled={
                                  disabledFields.includes("share") || false
                                }
                                onBlur={() => calculateShare(index)}
                                fieldValue={nominee.share}
                                onTextChange={handleChange(index)}
                                error={errors[index].share}
                                errorclass="text-danger errorfont mh-18"
                                errorMessage={errors[index].shareErrorMessage}
                              />
                            </div>
                            <div className="col-md-4">
                              <DropDown
                                tabIndex={tabIndex + 5}
                                mainclassName="pb-3"
                                label={strings.nominee_relationship}
                                classes="inuput-box"
                                type="form-control br-0"
                                drId="relationship"
                                selectid="relationship"
                                disabled={
                                  disabledFields.includes("relationship") ||
                                  false
                                }
                                fieldValue={nominee.relationship}
                                options={relationshipOptions}
                                error={errors[index].relationship}
                                errorMessage={strings.mandatory}
                                errorclass="text-danger errorfont mh-18"
                                onselectchange={handleChange(index)}
                                initialValue={nominee.relationship}
                              />
                            </div>
                            <div className="col-md-12 pt-1">
                              <label>Nominee Address</label>
                            </div>
                            <div className="col-md-12">
                              <span>
                                <div className="show-inp">
                                  <label className="sub-add">
                                    {strings.sameaspermaddress}
                                    <input
                                      type="checkbox"
                                      placeholder={
                                        strings.permanent_address_holder
                                      }
                                      onChange={handleCheckBoxChange(index)}
                                      checked={nominee.sameAsPermanent}
                                      name="sameAsPermanent"
                                      tabIndex={tabIndex + 6}
                                      disabled={
                                        disabledFields.includes(
                                          "nominee_addr_same"
                                        ) || false
                                      }
                                    />
                                    <span className="geekmark"></span>
                                  </label>
                                </div>
                              </span>
                            </div>
                            <div className="col-md-12">
                              {!nominee.sameAsPermanent && (
                                <div className="row">
                                  <div className="col-md-4">
                                    <TextBox
                                      tabIndex={tabIndex + 7}
                                      mainclass="pb-2"
                                      //label={strings.address_flat}
                                      placehold={strings.address_flat}
                                      classes="inuput-box"
                                      inputclass="mb-0"
                                      type="text"
                                      textId="add1"
                                      disabled={
                                        disabledFields.includes("add1") || false
                                      }
                                      fieldValue={nominee.add1 || ""}
                                      onTextChange={handleChange(index)}
                                      error={errors[index].add1}
                                      errorclass="text-danger errorfont mh-18"
                                      errorMessage={strings.mandatory}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <TextBox
                                      tabIndex={tabIndex + 8}
                                      mainclass="pb-2"
                                      //label={strings.address_street}
                                      placehold={strings.address_street}
                                      classes="inuput-box"
                                      inputclass="mb-0"
                                      type="text"
                                      textId="add2"
                                      disabled={
                                        disabledFields.includes("add2") || false
                                      }
                                      fieldValue={nominee.add2 || ""}
                                      onTextChange={handleChange(index)}
                                      error={errors[index].add2}
                                      errorclass="text-danger errorfont mh-18"
                                      errorMessage={strings.mandatory}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <TextBox
                                      tabIndex={tabIndex + 9}
                                      mainclass="pb-2"
                                      //label={strings.address_area}
                                      placehold={strings.address_area}
                                      classes="inuput-box"
                                      inputclass="mb-0"
                                      type="text"
                                      textId="add3"
                                      disabled={false}
                                      fieldValue={
                                        disabledFields.includes("add3") ||
                                        nominee.add3 ||
                                        ""
                                      }
                                      onTextChange={handleChange(index)}
                                      error={errors[index].add3}
                                      errorclass="text-danger errorfont mh-18"
                                      errorMessage={strings.mandatory}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <PincodeBox
                                      tabIndex={tabIndex + 10}
                                      mainclass="pb-2"
                                      placehold={strings.pincode}
                                      //label={strings.pincode}
                                      classes="inuput-box"
                                      inputclass="mb-0"
                                      textMaxLength="6"
                                      textId="pincode"
                                      disabled={
                                        disabledFields.includes("pincode") ||
                                        false
                                      }
                                      options={options || []}
                                      fieldValue={nominee.pincode || ""}
                                      onTextChange={pinHandleChange(index)}
                                      optionClick={(location) =>
                                        pincodeSelect(
                                          index,
                                          "pincode",
                                          location
                                        )
                                      }
                                      error={errors[index].pincode}
                                      errorclass="text-danger errorfont mh-18"
                                      errorMessage={strings.mandatory}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <TextBox
                                      tabIndex={tabIndex + 11}
                                      mainclass="pb-2"
                                      placehold={strings.city}
                                      //label={strings.city}
                                      classes="inuput-box"
                                      inputclass="mb-0"
                                      type="text"
                                      textId="city"
                                      disabled={
                                        disabledFields.includes("city") || false
                                      }
                                      fieldValue={nominee.city || ""}
                                      onTextChange={handleChange(index)}
                                      error={errors[index].city}
                                      errorclass="text-danger errorfont mh-18"
                                      errorMessage={strings.mandatory}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <TextBox
                                      tabIndex={tabIndex + 12}
                                      mainclass="pb-2"
                                      placehold={strings.state}
                                      //label={strings.state}
                                      classes="inuput-box"
                                      inputclass="mb-0"
                                      type="text"
                                      textId="state"
                                      disabled={
                                        disabledFields.includes("state") ||
                                        false
                                      }
                                      fieldValue={nominee.state || ""}
                                      onTextChange={handleChange(index)}
                                      error={errors[index].state}
                                      errorclass="text-danger errorfont mh-18"
                                      errorMessage={strings.mandatory}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="col-md-12 pl-0">
                              <RadioButtonGroup
                                tabIndex={tabIndex + 13}
                                mainclassName="col-md-5 pl-3"
                                label={strings.minor_nominee_label}
                                options={RadioGroupOptions.ADD_NOMINEE}
                                name={`major[${index}]`}
                                htmlForText={`major-${index}`}
                                onOptionChange={handleChange(index)}
                                errorMessage={strings.mandatory}
                                errorclass="text-danger errorfont mh-18"
                                selected={
                                  nominee.major
                                    ? RadioGroupOptions.ADD_NOMINEE[0]
                                    : RadioGroupOptions.ADD_NOMINEE[1]
                                }
                                disabled={
                                  disabledFields.includes("major") || false
                                }
                              />
                            </div>
                            <div className="col-md-12">
                              {!nominee.major && (
                              <>
                               <div className="pb-3 inp api-errormessage required">{strings.nominee_guardian_note}</div>
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="inuput-box">
                                      <label>{strings.dateofbirth}</label>
                                      <DatePicker
                                        renderCustomHeader={({
                                          date,
                                          changeYear,
                                          changeMonth,
                                          decreaseMonth,
                                          increaseMonth,
                                          prevMonthButtonDisabled,
                                          nextMonthButtonDisabled,
                                        }) => (
                                          <div
                                            style={{
                                              margin: 10,
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <button
                                              onClick={decreaseMonth}
                                              disabled={prevMonthButtonDisabled}
                                            >
                                              {"<"}
                                            </button>
                                            <select
                                              value={getYear(date)}
                                              onChange={({
                                                target: { value },
                                              }) => changeYear(value)}
                                              style={{ width: 75 }}
                                            >
                                              {years.map((option) => (
                                                <option
                                                  key={option}
                                                  value={option}
                                                >
                                                  {option}
                                                </option>
                                              ))}
                                            </select>

                                            <select
                                              value={months[getMonth(date)]}
                                              onChange={({
                                                target: { value },
                                              }) =>
                                                changeMonth(
                                                  months.indexOf(value)
                                                )
                                              }
                                              style={{ width: 100 }}
                                            >
                                              {months.map((option) => (
                                                <option
                                                  key={option}
                                                  value={option}
                                                >
                                                  {option}
                                                </option>
                                              ))}
                                            </select>

                                            <button
                                              onClick={increaseMonth}
                                              disabled={nextMonthButtonDisabled}
                                            >
                                              {">"}
                                            </button>
                                          </div>
                                        )}
                                        tabIndex={tabIndex + 14}
                                        ref={dobRef}
                                        selected={
                                          nominee.dob ? nominee.dob : undefined
                                        }
                                        onChange={(value) =>
                                          handleChangeDate(index, value)
                                        }
                                        onKeyDown={(e) => {
                                          if (e.keyCode === 9)
                                            dobRef.current.setOpen(false, true);
                                        }}
                                        maxDate={moment()._d}
                                        dateFormat={format_date_only}
                                        placeholderText="DD-MM-YYYY"
                                        disabled={
                                          disabledFields.includes("dob") ||
                                          false
                                        }
                                        className="datePicker options-margin-top paninput"
                                        closeOnScroll={true}
                                        shouldCloseOnSelect={true}
                                        onChangeRaw={(date) => {
                                          if (
                                            date &&
                                            date.currentTarget &&
                                            date.currentTarget.value
                                          ) {
                                            dobRef.current.input.value =
                                              date.currentTarget.value
                                                .replace(/[^0-9\\-]/g, "")
                                                .substring(0, 10);
                                          }
                                        }}
                                      />
                                      <div className="mh-18">
                                        {errors[index].dob && (
                                          <div className="text-danger errorfont">
                                            {errors[index].dobErrorMessage ||
                                              strings.mandatory}
                                          </div>
                                        )}{" "}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <TextBox
                                      tabIndex={tabIndex + 15}
                                      mainclassName="pb-3"
                                      label={strings.nominee_guardian_name}
                                      placehold={strings.nominee_guardian_name}
                                      classes="inuput-box"
                                      inputclassName="mb-0"
                                      type="text"
                                      textId="guardianName"
                                      disabled={
                                        disabledFields.includes(
                                          "guardian_name"
                                        ) || false
                                      }
                                      fieldValue={nominee.guardianName || ""}
                                      onTextChange={handleChange(index)}
                                      error={errors[index].guardianName}
                                      errorclass="text-danger errorfont mh-18"
                                      errorMessage={strings.mandatory}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <TextBox
                                      tabIndex={tabIndex + 16}
                                      mainclassName="pb-3"
                                      label={strings.nominee_guardian_mobile_no}
                                      placehold={
                                        strings.nominee_guardian_mobile_no
                                      }
                                      classes="inuput-box"
                                      inputclassName="mb-0"
                                      type="number"
                                      textId="guardianMobile"
                                      textMaxLength={10}
                                      disabled={
                                        disabledFields.includes(
                                          "guardian_mobile"
                                        ) || false
                                      }
                                      fieldValue={nominee.guardianMobile || ""}
                                      onTextChange={handleChange(index)}
                                      error={errors[index].guardianMobile}
                                      errorclass="text-danger errorfont mh-18"
                                      errorMessage={strings.mandatory}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <TextBox
                                      tabIndex={tabIndex + 17}
                                      mainclassName="pb-3"
                                      label={strings.nominee_guardian_email}
                                      placehold={strings.nominee_guardian_email}
                                      classes="inuput-box"
                                      inputclassName="mb-0"
                                      type="text"
                                      textId="guardianEmail"
                                      disabled={
                                        disabledFields.includes(
                                          "guardian_email"
                                        ) || false
                                      }
                                      fieldValue={nominee.guardianEmail || ""}
                                      onTextChange={handleChange(index)}
                                      error={errors[index].guardianEmail}
                                      errorclass="text-danger errorfont mh-18"
                                      errorMessage={strings.mandatory}
                                    />
                                  </div>
                                  <div className="col-md-4">
                                    <DropDown
                                      tabIndex={tabIndex + 18}
                                      mainclassName="pb-3"
                                      label={
                                        strings.nominee_guardian_relationship
                                      }
                                      classes="inuput-box"
                                      type="form-control br-0"
                                      drId="guardianRelationship"
                                      selectid="guardianRelationship"
                                      disabled={
                                        disabledFields.includes(
                                          "guardian_relationship"
                                        ) || false
                                      }
                                      fieldValue={nominee.guardianRelationship}
                                      options={relationshipOptions}
                                      error={errors[index].guardianRelationship}
                                      errorMessage={strings.mandatory}
                                      errorclass="text-danger errorfont mh-18"
                                      onselectchange={handleChange(index)}
                                      initialValue={
                                        nominee.guardianRelationship
                                      }
                                    />
                                  </div>
                                  <div className="col-md-12 pt-3">
                                    <label>Guardian Address</label>
                                  </div>
                                  <div className="col-md-12">
                                    <span>
                                      <div className="show-inp">
                                        <label className="sub-add">
                                          {strings.sameaspermaddress}
                                          <input
                                            type="checkbox"
                                            placeholder={
                                              strings.permanent_address_holder
                                            }
                                            onChange={handleCheckBoxChange(
                                              index
                                            )}
                                            checked={
                                              nominee.guardianSameAsPermanent
                                            }
                                            name="guardianSameAsPermanent"
                                            tabIndex={tabIndex + 19}
                                            disabled={
                                              disabledFields.includes(
                                                "guardian_addr_same"
                                              ) || false
                                            }
                                          />
                                          <span className="geekmark"></span>
                                        </label>
                                      </div>
                                    </span>
                                  </div>
                                  <div className="col-md-12">
                                    {!nominee.guardianSameAsPermanent && (
                                      <div className="row">
                                        <div className="col-md-4">
                                          <TextBox
                                            tabIndex={tabIndex + 20}
                                            mainclass="pb-2"
                                            //label={strings.address_flat}
                                            placehold={strings.address_flat}
                                            classes="inuput-box"
                                            inputclass="mb-0"
                                            type="text"
                                            textId="guardianAdd1"
                                            disabled={
                                              disabledFields.includes(
                                                "guardian_addr1"
                                              ) || false
                                            }
                                            fieldValue={
                                              nominee.guardianAdd1 || ""
                                            }
                                            onTextChange={handleChange(index)}
                                            error={errors[index].guardianAdd1}
                                            errorclass="text-danger errorfont mh-18"
                                            errorMessage={strings.mandatory}
                                          />
                                        </div>
                                        <div className="col-md-4">
                                          <TextBox
                                            tabIndex={tabIndex + 21}
                                            mainclass="pb-2"
                                            //label={strings.address_street}
                                            placehold={strings.address_street}
                                            classes="inuput-box"
                                            inputclass="mb-0"
                                            type="text"
                                            textId="guardianAdd2"
                                            disabled={
                                              disabledFields.includes(
                                                "guardian_addr2"
                                              ) || false
                                            }
                                            fieldValue={
                                              nominee.guardianAdd2 || ""
                                            }
                                            onTextChange={handleChange(index)}
                                            error={errors[index].guardianAdd2}
                                            errorclass="text-danger errorfont mh-18"
                                            errorMessage={strings.mandatory}
                                          />
                                        </div>
                                        <div className="col-md-4">
                                          <TextBox
                                            tabIndex={tabIndex + 22}
                                            mainclass="pb-2"
                                            //label={strings.address_area}
                                            placehold={strings.address_area}
                                            classes="inuput-box"
                                            inputclass="mb-0"
                                            type="text"
                                            textId="guardianAdd3"
                                            disabled={
                                              disabledFields.includes(
                                                "guardian_addr3"
                                              ) || false
                                            }
                                            fieldValue={
                                              nominee.guardianAdd3 || ""
                                            }
                                            onTextChange={handleChange(index)}
                                            error={errors[index].guardianAdd3}
                                            errorclass="text-danger errorfont mh-18"
                                            errorMessage={strings.mandatory}
                                          />
                                        </div>
                                        <div className="col-md-4">
                                          <PincodeBox
                                            tabIndex={tabIndex + 23}
                                            mainclass="pb-2"
                                            //label={strings.pincode}
                                            placehold={strings.pincode}
                                            classes="inuput-box"
                                            inputclass="mb-0"
                                            textMaxLength="6"
                                            textId="guardianPincode"
                                            disabled={
                                              disabledFields.includes(
                                                "guardian_pincode"
                                              ) || false
                                            }
                                            options={options || []}
                                            fieldValue={
                                              nominee.guardianPincode || ""
                                            }
                                            onTextChange={pinHandleChange(
                                              index
                                            )}
                                            optionClick={(location) =>
                                              pincodeSelect(
                                                index,
                                                "guardianPincode",
                                                location
                                              )
                                            }
                                            error={
                                              errors[index].guardianPincode
                                            }
                                            errorclass="text-danger errorfont mh-18"
                                            errorMessage={strings.mandatory}
                                          />
                                        </div>
                                        <div className="col-md-4">
                                          <TextBox
                                            tabIndex={tabIndex + 24}
                                            mainclass="pb-2"
                                            //label={strings.city}
                                            placehold={strings.city}
                                            classes="inuput-box"
                                            inputclass="mb-0"
                                            type="text"
                                            textId="guardianCity"
                                            disabled={
                                              disabledFields.includes(
                                                "guardian_city"
                                              ) || false
                                            }
                                            fieldValue={
                                              nominee.guardianCity || ""
                                            }
                                            onTextChange={handleChange(index)}
                                            error={errors[index].guardianCity}
                                            errorclass="text-danger errorfont mh-18"
                                            errorMessage={strings.mandatory}
                                          />
                                        </div>
                                        <div className="col-md-4">
                                          <TextBox
                                            tabIndex={tabIndex + 25}
                                            mainclass="pb-2"
                                            //label={strings.state}
                                            placehold={strings.state}
                                            classes="inuput-box"
                                            inputclass="mb-0"
                                            type="text"
                                            textId="guardianState"
                                            disabled={
                                              disabledFields.includes(
                                                "guardian_state"
                                              ) || false
                                            }
                                            fieldValue={
                                              nominee.guardianState || ""
                                            }
                                            onTextChange={handleChange(index)}
                                            error={errors[index].guardianState}
                                            errorclass="text-danger errorfont mh-18"
                                            errorMessage={strings.mandatory}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {hasNominee && nomineeList.length < maxAllowedNominees && (
                  <div className="col-md-12 pt-1">
                    <button className="button-link" onClick={addNominee}>
                      <i
                        className="fa fa-plus-circle pr-2"
                        aria-hidden="true"
                      ></i>
                      Add another nominee
                    </button>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-12 pt-5">
                  <div className="alert alert-success" role="alert">
                    {strings.proof_disclaimer}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row mh-42">
                    {fieldValidationError && (
                      <span className="text-danger d-none">
                        {strings.errormsg}
                      </span>
                    )}
                    <div className="col-md-12 pt-2">
                      {visibleApiMesg && (
                        <div className="text-danger text-center">
                          <label id="txterror" name="txterror">
                            {apiMessage}
                          </label>{" "}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 pt-4">
                      <div className="inp-back">
                        <Button
                          classes="inp-back"
                          label={strings.previous}
                          click={prevClick}
                        />
                      </div>
                    </div>
                    <div className="col-6 pt-3 text-right">
                      <Button
                        classes="inp"
                        classes1="buttonmystyle"
                        label={strings.nextbtn}
                        click={nextClick}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};
export default PersonalDetailsNominee;
